<div class="criteria-list">
    <div *ngFor="let criterion of criteriaList$ | async" class="criterion-item"
        [class.selected]="(selectedCriteria$ | async)?.includes(criterion)" (click)="toggleSelection(criterion)">
        {{ criterion }}
    </div>
</div>

<div class="actions">
    <button (click)="saveSelection()" [disabled]="!(hasSelections$ | async)">
        Add Selected
    </button>
    <button (click)="clearSelections()" [disabled]="!(hasSelections$ | async)">
        Clear All
    </button>
</div>