import { createFeatureSelector, createSelector } from '@ngrx/store'
import { NEVER } from 'rxjs'
import {
  selectRouteNestedParam,
  selectRouteParams
} from '../../core/core.state'
import { selectFeatures, FeaturesState } from '../features.state'
import { ART } from './art.model'
import { adapter, ARTState, stateARTFeatureKey } from './art.reducer'
import { Id } from '../../shared/models/entity-base.model'

export const selectARTsState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.arts
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectARTsState)

/*
export const selectARTsEntities = createSelector(
  selectARTs,
  (state) => state.entities
)
*/

export const selectARTArray = createSelector(selectEntities, (entities) =>
  Object.values(entities)
)

export const selectArt = (id: string) =>
  createSelector(
    selectEntities,
    selectRouteNestedParam(id),
    (arts, idend) => arts[idend]
  )
export const selectARTListOrderByName = createSelector(
  selectAll,
  (entities: ART[]) =>
    entities.sort((a, b) => {
      const nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase()
      if (nameA < nameB)
        // sort string ascending
        return -1
      if (nameA > nameB) return 1
      return 0
    })
)
export const getARTById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)
export const selectART = (id) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getARTByPropertyValue = createSelector(
  selectAll,
  (
    entities: ART[],
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) => entities.filter((item) => item[columnId] === columnValue)
)

export const getARTListByPortfolioId = (portfolioId: string) =>
  createSelector(selectAll, (entities: ART[]) =>
    entities
      .filter((item) => item.portfolioId === portfolioId)
      .sort((a, b) => {
        const nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase()
        if (nameA < nameB)
          // sort string ascending
          return -1
        if (nameA > nameB) return 1
        return 0
      })
  )

export const getARTByKanbanId = (kanbanId: string) =>
  createSelector(selectAll, (entities: ART[]) =>
    entities.find((art) => art.kanbanId === kanbanId)
  )
export const getARTByKanbanIdList = () =>
  createSelector(
    selectAll,
    (entities: ART[], { kanbanId }: { kanbanId: string }) =>
      entities.filter((art) => art.kanbanId === kanbanId)
  )
export const getAvailableARTsByPortfolioId = createSelector(
  selectARTArray,
  (entities: ART[], { portfolioId }: { portfolioId: string }) =>
    entities.filter(
      (item) =>
        (item['portfolioId'] === portfolioId &&
          item.valueStreams === undefined) ||
        ((item.valueStreams === null || item.valueStreams?.length === 0) &&
          item['portfolioId'] === portfolioId)
    )
)
export const getNotAvailableARTsByPortfolioId = (portfolioId: string) =>
  createSelector(selectARTArray, (entities: ART[]) =>
    entities
      .filter(
        (item) =>
          item.portfolioId === portfolioId &&
          item.valueStreams !== undefined &&
          item.valueStreams !== null &&
          item.valueStreams.length > 0
      )
      .sort((a, b) => {
        const nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase()
        if (nameA < nameB)
          // sort string ascending
          return -1
        if (nameA > nameB) return 1
        return 0
      })
  )

export const getARTListByValueStream = (valueStreamId: string) =>
  createSelector(selectAll, (arts: ART[]) => {
    const entitiesOut: ART[] = []
    arts.forEach((art) => {
      if (
        art.valueStreams?.find((va) => va.id === valueStreamId) !== undefined
      ) {
        entitiesOut.push(art)
      }
    })
    if (entitiesOut.length !== 0) return entitiesOut
    else return undefined
  })
export const getARTByValueStreams = (valueStreams: Id[]) =>
  createSelector(selectAll, (arts: ART[]) => {
    const entitiesOut: ART[] = []
    if (
      valueStreams !== undefined &&
      valueStreams !== null &&
      valueStreams.length > 0
    )
      arts.forEach((art) => {
        // console.log('uno', art.id)
        // console.log('otro', valueStreams)
        if (
          art.valueStreams.some(
            (item) => valueStreams.filter((id) => id.id === item.id).length > 0
            // return valueStreams.includes(item)
          )
          // eslint-disable-next-line @typescript-eslint/naming-convention
          /* art.valueStreams?.find((va) =>
            valueStreams?.some((ident) => ident?.id === va.id)
          ) !== undefined*/
        ) {
          entitiesOut.push(art)
        }
      })
    // console.log(entitiesOut)
    return entitiesOut
  })
