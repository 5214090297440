/* eslint-disable guard-for-in */
import * as fromOrganizationSelectors from './organization-store/store/organization.selectors'
import * as fromStrategicThemesSelectors from './strategic-themes-store/store/strategic-themes.selectors'
import * as fromTeamSelectors from '../features/teams-store/store/team.selectors'
import * as fromARTSelectors from '../features/arts-store/art.selectors'
import * as fromPortfolioSelectors from '../features/portfolio-store/portfolio/portfolio-safe.selectors'
import * as fromItemsSelectors from './requirements-store/store/item/requirement.selectors'
import * as fromValueStreamSelectors from '../features/value-stream-store/store/valuestream.selectors'
import * as fromKanbanSelectors from '../kanban/store/kanban-selector'
import * as fromSolutionSelectors from '../features/solutions-store/store/solution.selectors'
import * as fromUserSelectors from '../features/user-store/user.selectors'
import * as fromOrganizationMemberSelectors from './organization-store/store/organization-member.selectors'
import * as fromKPIInstanceSelectors from './store/kpiinstance/kpiinstance.selectors'
import * as fromKPISelectors from './store/kpis/kpis.selectors'
import * as fromPISelectors from '../features/program-increment-store/store/pi/program-increment.selectors'
import { Dictionary } from '@ngrx/entity'
import {
  createSelector,
  createSelectorFactory,
  defaultMemoize
} from '@ngrx/store'
import { ART, ARTWithOrganization } from './arts-store/art.model'
import { Organization } from './organization-store/store/organization.model'
import { PortfolioSafe } from './portfolio-store/portfolio/portfolio-safe.model'
import { Team, TeamWithKanban } from './teams-store/store/team.model'
import {
  DevelopmentValueStream,
  DevelopmentValueStreamWithSolutions,
  Valuestream,
  ValueStreamWithEpics,
  ValuestreamWithSolutions
} from './value-stream-store/store/valuestream.model'
import {
  isProgramKanban,
  Kanban,
  KanbanColumn,
  KanbanI,
  ProgramKanban,
  UnifiedKanbanColumn,
  UnifiedKanbanItem
} from '../kanban/store/kanban.model'
import {
  Comment,
  Epic,
  Feature,
  isEpic,
  isEpicI,
  isFeatureI,
  KanbanItemI,
  RequirementForBudget,
  RequirementI
} from './requirements-store/store/requirements.model'

import {
  EconomicalData,
  KeyResult,
  KeyResultWithEpicsStatistics,
  Objective,
  StrategicTheme,
  StrategicThemeRow,
  StrategicThemeRowWithKeyResults,
  StrategicWithEpics,
  StrategicWithKeyResults
} from './strategic-themes-store/store/strategic-themes.model'
import {
  ContextType,
  IdSolution,
  Solution,
  SolutionType
} from './solutions-store/store/solutions.model'
import { States } from './teams/team-dashboard/team-kanban/team-kanban.component'
import { ArrowRelation } from '../shared/value-stream/svgs/arrows/arrow.model'

import { OrganizationMember } from './organization-store/store/organization-member.model'
import { User } from './user-store/user.model'
import { SolutionWithItsStagesAndColor } from '../shared/value-stream/svgs/model/value-stream-schema-model'
import { KPI, KPIInstance, KPIInstanceWithKPI } from './store/kpis/kpi.model'
import { ProgramIncrement } from './program-increment-store/store/pi/program-increment.model'
import { Id } from '../shared/models/entity-base.model'

export const selectTeamsARTS = createSelector(
  fromARTSelectors.selectAll,
  fromTeamSelectors.selectAll,

  (arts: ART[], teams: Team[]) => {
    const teamsarts = []
    teams.map((team) => {
      arts
        .filter((art) => art.id === team.ARTId)
        .map((art) =>
          teamsarts.push({ ...team, artName: art.name, artID: art.id })
        )
    })
    return teamsarts
  }
)

export const selectARTSWithTeamsByKanbanId = (kanbanId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromTeamSelectors.selectAll,

    (arts: ART[], teams: Team[]) => {
      const art = arts.find((art1) => art1.kanbanId === kanbanId)
      if (art === undefined) return
      const teamsarts = teams.filter((team) => team.ARTId === art.id)
      return { ...art, teams: teamsarts }
    }
  )
export const selectARTSWithTeamsId = (artId: string) =>
  createSelector(
    fromARTSelectors.selectEntities,
    fromTeamSelectors.selectAll,

    (arts: Dictionary<ART>, teams: Team[]) => {
      const art = arts[artId]
      const teamsarts = teams.filter((team) => team.ARTId === artId)

      return { ...art, teams: teamsarts }
    }
  )
export const selectTeamsWithKanban = createSelector(
  fromKanbanSelectors.selectEntities,
  fromTeamSelectors.selectAll,

  (kanbans: Dictionary<Kanban>, teams: Team[]) => {
    const teamsWithKanban: TeamWithKanban[] = teams.map((team) => ({
      ...team,
      kanban: kanbans[team.kanbanId]
    }))

    return teamsWithKanban
  }
)

export const selectTeamsIdWithKanban = (ids: Id[]) =>
  createSelector(
    fromKanbanSelectors.selectEntities,
    fromTeamSelectors.selectAll,
    (kanbans: Dictionary<Kanban>, teams: Team[]) => {
      const teamOuts = teams.filter(filterByIds, { ids: ids })
      return teamOuts.map((team) => ({
        ...team,
        kanban: kanbans[team.kanbanId]
      }))
    }
  )

function filterByIds(team: Team) {
  for (const key in this.ids) {
    if (team.id === this.ids[key].id) {
      return true
    }
  }
  return false
}

export const getTeamByKanbanId = (kanbanId: string) =>
  createSelector(
    fromKanbanSelectors.selectEntities,
    fromTeamSelectors.selectAll,
    (kanbans: Dictionary<Kanban>, teams: Team[]) => ({
      ...teams.find((team) => team.kanbanId === kanbanId),
      kanban: kanbans[kanbanId]
    })
  )

export const selectTeamsByArtId = (artId: string) =>
  createSelector(fromTeamSelectors.selectAll, (teams: Team[]) =>
    teams.filter((team) => team.ARTId === artId)
  )

export const selectTeamsAll = createSelector(
  fromARTSelectors.selectAll,
  fromTeamSelectors.selectAll,
  fromPortfolioSelectors.selectAll,
  fromOrganizationSelectors.selectAll,
  (
    arts: ART[],
    teams: Team[],
    portfolios: PortfolioSafe[],
    organizations: Organization[]
  ) => {
    const teamsfull = []
    teams.map((team) => {
      const portfolioSAFE = portfolios.find(
        (por) => por.id === arts.find((a) => a.id === team.ARTId)?.portfolioId
      )
      teamsfull.push({
        ...team,
        portfolioName: portfolioSAFE?.name,
        portfolioId: portfolioSAFE?.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ARTName: arts.find((a) => a.id === team.ARTId)?.name,
        organization: organizations.find(
          (a) => a.id === portfolioSAFE?.organizationId
        )?.name
      })
    })
    return teamsfull
  }
)

export const selectParentKanbanByARTId = createSelectorFactory(defaultMemoize)(
  fromKanbanSelectors.selectEntities,
  fromPortfolioSelectors.selectEntities,
  fromARTSelectors.selectEntities,
  (
    kanbans: Dictionary<Kanban>,
    portfolios: Dictionary<PortfolioSafe>,
    arts: Dictionary<ART>,
    props: { artId: string }
  ) => {
    const art = arts[props.artId]
    if (!art) {
      return null // Return null if the ART is not found
    }

    const portfolioSAFE = portfolios[art.portfolioId]
    if (!portfolioSAFE || !portfolioSAFE.kanbanId) {
      return null // Return null if the portfolio or kanbanId is missing
    }

    const kanban = kanbans[portfolioSAFE.kanbanId]
    return kanban || null // Return the kanban or null if not found
  }
)

export const selectParentKanbanIdByARTId = createSelector(
  fromPortfolioSelectors.selectEntities,
  fromARTSelectors.selectEntities,
  (
    portfolios: Dictionary<PortfolioSafe>,
    arts: Dictionary<ART>,
    { artId }: { artId: string }
  ) => {
    // console.log(arts)
    const art = arts[artId]
    // console.log('ART', art, artId)
    const portfolioSAFE = portfolios[art.portfolioId]

    return portfolioSAFE.kanbanId
  }
)
export const selectItemsWithVAlueStreamNUll = createSelector(
  fromKanbanSelectors.selectAll,
  fromPortfolioSelectors.selectAll,
  fromARTSelectors.selectAll,
  fromValueStreamSelectors.selectAll,
  fromItemsSelectors.selectAll,
  (
    kanbans: Kanban[],
    portfolios: PortfolioSafe[],
    arts: ART[],
    vas: Valuestream[],
    items: RequirementI[],
    { artId, scope }: { artId: string; scope: Id[] }
  ) => {
    // const art = arts.find((a) => a.id === artId && a.valueStreams !== undefined && a.valueStreams !== null)
    const art = arts.find((a) => a.id === artId)
    const portfolioSAFE = portfolios.find((por) => por.id === art.portfolioId)

    const kanban = kanbans.find((k) => k.id === portfolioSAFE.kanbanId)
    // const itemList=items.filter(item=>item.scope=art.valueStreams)
    return kanban
  }
)
export const selectEpicsfromPortfolio = createSelector(
  fromKanbanSelectors.selectEntities,
  fromPortfolioSelectors.selectEntities,
  fromItemsSelectors.selectAll,
  (
    kanbans: Dictionary<Kanban>,
    portfolios: Dictionary<PortfolioSafe>,
    items: RequirementI[],
    { portfolioId }: { portfolioId: string }
  ) => {
    const portfolioSAFE = portfolios[portfolioId]

    const kanban = kanbans[portfolioSAFE.kanbanId]

    const rows = []
    const epics = items.filter(
      (epic) =>
        epic.name === 'epic' &&
        (epic.columnId === kanban.columns[4].id ||
          epic.columnId === kanban.columns[5].id)
    )
    epics.forEach((epic) =>
      rows.push({
        name: epic.itemName,
        spent: '',
        remaining: '',
        totalBudget: epic['forecastedEpicCost']
      })
    )
    return rows
  }
)

export const selectEpicsfromPortfolioForBudget = (portfolioId: string) =>
  createSelector(
    fromOrganizationMemberSelectors.selectAll,
    fromKanbanSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    getValueStreamsWithSolutions(),
    fromSolutionSelectors.selectAll,
    (
      users: OrganizationMember[],
      kanbans: Dictionary<Kanban>,
      portfolios: Dictionary<PortfolioSafe>,
      items: RequirementI[],
      strategicThemes: StrategicTheme[],
      valuesStreamsArray: ValuestreamWithSolutions[]
    ) => {
      const portfolioSAFE = portfolios[portfolioId]

      // Ensure portfolioSAFE exists and has a valid kanbanId
      if (!portfolioSAFE || !portfolioSAFE.kanbanId) {
        return undefined // Return undefined if no valid portfolio or kanbanId is found
      }

      const kanban = kanbans[portfolioSAFE.kanbanId]
      if (!kanban) {
        return undefined // Return undefined if no valid Kanban is found
      }

      // FILTER COLUMNS IMPLEMENTING & DONE

      if (kanban) {
        const epics: RequirementForBudget[] = items
          .filter(
            (epic) =>
              epic.name === 'epic' &&
              (epic.columnId === kanban.columns[4].id ||
                epic.columnId === kanban.columns[5].id)
            // eslint-disable-next-line arrow-body-style
          )
          .map((epic: Epic) => ({
            ...epic,
            strategicTheme: strategicThemes.find(
              (e) => e.id === epic.strategicThemeId
            ),
            valueStreamObjects: valuesStreamsArray.filter((e, i) => {
              for (const key in epic.valueStreams) {
                if (e.id === epic.valueStreams[key].id) {
                  return e
                }
              }
            }) as DevelopmentValueStreamWithSolutions[],
            solutions: valuesStreamsArray
              .map((e, i) => {
                for (const key in epic.valueStreams) {
                  if (e.id === epic.valueStreams[key].id) {
                    return e.solutionsList
                  }
                }
              })
              .filter(Boolean),
            strategicThemeDescription: strategicThemes.find((e, i) => {
              if (e.id === epic.strategicThemeId) {
                return e
              }
            })?.description,
            owner: users.find((user) => user.userId.id === epic.epicOwnerId)
              ?.userId.name,

            portfolioName: portfolioSAFE.name
          }))
        return epics
      } else return undefined
    }
  )

export const selectSolutionsByValuesStreams = (ids: Id[]) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (valueStreams: Valuestream[], solutions: Solution[]) => {
      const solOut = []
      const vs = valueStreams.filter((e) => {
        for (const key in ids) {
          if (e.id === ids[key].id) {
            return e
          }
        }
      })

      const sols = solutions.filter((solution) => {
        for (const key in vs) {
          if (vs[key].solutions) {
            vs[key].solutions.forEach((element) => {
              if (element.id === solution.id) {
                solOut.push(solution)
              }
            })
          }
        }
      })
      return solOut
    }
  )

export const selectEpicsByKeyResultId = (keyResultId: string) =>
  createSelector(
    fromItemsSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    fromValueStreamSelectors.selectAll,
    (
      items: Epic[],
      strategicThemes: StrategicTheme[],
      valuesStreamsArray: Valuestream[]
    ) => {
      // Find the strategic theme associated with the given keyResultId
      const strategicThemeFound = strategicThemes.find((strategicTheme) => {
        if (strategicTheme.keyResults && strategicTheme.keyResults.length > 0) {
          return strategicTheme.keyResults.some(
            (keyresult) => keyresult.id === keyResultId
          )
        }
        return false
      })

      // Filter and map the epics
      const epics = items
        .filter(
          (epic) =>
            epic.keyResultsId?.includes(keyResultId) && // Check if keyResultId is in the keyResultsId array
            (epic.state === 'Implementing' || epic.state === 'Done') // Filter by state
        )
        .map((epic) => ({
          ...epic,
          strategicTheme: strategicThemeFound || null, // Associate the strategic theme if found
          valueStreamObjects: valuesStreamsArray.filter((valueStream) =>
            epic.valueStreams?.some(
              (epicValueStream) => epicValueStream.id === valueStream.id
            )
          )
        }))

      return epics
    }
  )

export const selectEpicByIdForBudget = (id: string) =>
  createSelector(
    fromItemsSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (
      items: RequirementI[],
      strategicThemes: StrategicTheme[],
      valuesStreamsArray: Valuestream[],
      solutionArray: Solution[]
    ) => {
      // Find the epic by ID
      const epicFound: Epic = items.find((epic) => epic.id === id) as Epic

      // Find the strategic theme associated with any of the keyResultsId in the epic
      const strategicThemeFound = strategicThemes.find((strategicTheme) =>
        strategicTheme.keyResults?.some((keyresult) =>
          epicFound.keyResultsId?.includes(keyresult.id)
        )
      )

      // Build the epicFoundOut object
      const epicFoundOut: RequirementForBudget = {
        portfolioName: '',
        strategicThemeDescription: '',
        ...epicFound,
        strategicTheme: strategicThemeFound || null,
        valueStreamObjects: valuesStreamsArray
          .filter((valueStream) =>
            epicFound.valueStreams?.some(
              (epicValueStream) => valueStream.id === epicValueStream.id
            )
          )
          .map(
            (valueStream) =>
              ({
                ...valueStream,
                solutionsList: solutionArray.filter((solution) =>
                  valueStream.solutions?.some(
                    (valueStreamSolution) =>
                      valueStreamSolution.id === solution.id
                  )
                )
              } as DevelopmentValueStreamWithSolutions)
          ),
        solutions: null
      }

      return epicFoundOut
    }
  )

export const selectEpicsAssignedToART = (artId: string) =>
  createSelector(
    fromARTSelectors.selectEntities,
    fromKanbanSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    fromItemsSelectors.getEpics(),
    (
      arts: Dictionary<ART>,
      kanbans: Dictionary<Kanban>,
      portfolios: Dictionary<PortfolioSafe>,
      items: Epic[]
    ) => {
      const art = arts[artId]
      // console.log('ART', art, artId)
      if (art) {
        const portfolioSAFE = portfolios[art?.portfolioId]

        if (portfolioSAFE) {
          const kanban = kanbans[portfolioSAFE?.kanbanId]
          if (kanban !== null && kanban !== undefined) {
            // RETURN ALL EPICS OF AN ART

            const portfolioepics: RequirementI[] = items.filter(
              (item) =>
                item.portfolioId === portfolioSAFE.id && item.name === 'epic'
            )

            let epicsInColumns2 = []

            kanban.columns.forEach((column) => {
              const epics = portfolioepics.filter(
                (epic) => epic.columnId === column.id
              )
              // console.log(epics)
              epicsInColumns2 = epicsInColumns2.concat(epics)
            })

            return epicsInColumns2
            // NOT NEED BECAUSE VOID RETURNS UNDEFINED
          } else return undefined
        }
      }
    }
  )
// NO BACKLOG COLUMN
export const selectFeaturesAssignedToTeam = (teamId: string) =>
  createSelector(
    fromTeamSelectors.selectEntities,
    fromARTSelectors.selectEntities,

    fromItemsSelectors.selectAll,
    fromKanbanSelectors.selectEntities,
    (
      teams: Dictionary<Team>,
      arts: Dictionary<ART>,

      items: RequirementI[],
      kanbans: Dictionary<Kanban>
    ) => {
      const team = teams[teamId]
      if (team) {
        const art = arts[team.ARTId]
        if (art !== undefined) {
          const programKanban = kanbans[art.kanbanId]
          if (!programKanban) return
          //  const scope: Id[] = art.valueStreams
          const columnIds: string[] = [
            programKanban.columns[0].id,
            programKanban.columns[1].id,
            programKanban.columns[2].id,
            programKanban.columns[3].id,
            programKanban.columns[4].id,
            programKanban.columns[5].id,
            programKanban.columns[6].id
          ]
          // const portfolioId = portfolioSAFE.id
          return items.filter(
            (item) =>
              // scope.indexOf({ id: item.valueStreamId }) > -1 ||
              item.name === 'feature' &&
              //  item['portfolioId'] === portfolioId &&
              columnIds.indexOf(item.columnId) > -1 &&
              item['teams'] !== undefined &&
              item['teams'].find((val) => val.id === team.id) !== undefined
          )
        }
        return undefined
      } else return undefined
    }
  )

export const selectPortfolioWithOrganization = (id: string) =>
  createSelector(
    fromPortfolioSelectors.selectEntities,
    fromOrganizationSelectors.selectAll,
    (portfolios: Dictionary<PortfolioSafe>, organizations: Organization[]) => {
      const portfolio = portfolios[id]
      if (portfolio === undefined) return
      const portout = {
        ...portfolio,
        organization: organizations.find(
          (org) => org.id === portfolio.organizationId
        )?.name
      }
      return portout
    }
  )

export const selectPortfoliosWithOrganization = () =>
  createSelector(
    fromPortfolioSelectors.selectAll,
    fromOrganizationSelectors.selectAll,
    (portfolios: PortfolioSafe[], organizations: Organization[]) => {
      const portsout = [
        ...portfolios.map((portfolio) => ({
          ...portfolio,
          organization: organizations.find(
            (a) => a.id === portfolio.organizationId
          )?.name
        }))
      ]

      return portsout
    }
  )

export const selectPortfoliosByOrganizationIdWithEpics = (
  organizationId: string
) =>
  createSelector(
    fromPortfolioSelectors.selectAll,
    fromItemsSelectors.selectAll,

    (portfolios: PortfolioSafe[], items: RequirementI[]) => {
      const portfoliosSAFE = portfolios
        .filter((portfolio) => portfolio.organizationId === organizationId)
        .map((portfolio1) => ({
          ...portfolio1,
          epics: items.filter(
            (epic) => epic.name === 'epic' && epic.portfolioId === portfolio1.id
          ) as Epic[]
        }))

      return portfoliosSAFE
    }
  )

export const selectPortfolioWithEpics = (portfolioId) =>
  createSelector(
    fromPortfolioSelectors.selectEntities,
    fromItemsSelectors.selectAll,

    (portfolios: Dictionary<PortfolioSafe>, items: RequirementI[]) => {
      const portfolioSAFE = portfolios[portfolioId]

      // If the portfolio exists, add the filtered epics to it
      if (portfolioSAFE) {
        return {
          ...portfolioSAFE,
          epics: items.filter(
            (epic) => epic.name === 'epic' && epic.portfolioId === portfolioId
          ) as Epic[]
        }
      }

      // Return null if no portfolio is found with the given ID
      return null
    }
  )

export const selectDevelopmentValueStreamsWithPortfoliosByOrganizationId = (
  organizationId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (
      valueStreams: Valuestream[],
      portfolios: PortfolioSafe[],
      solutions: Solution[]
    ) =>
      valueStreams
        .filter(
          (va) =>
            va.organizationId === organizationId && va.type === 'development'
        )
        .map((val) => ({
          ...val,
          portfolio: portfolios.find(
            (portfolio) => portfolio.id === val.portfolioId
          )?.name,
          solutionsList: val.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean)
        }))
  )

export const selectDevValueStreamsByOrganizationIdWithEpics = (
  organizationId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,

    fromItemsSelectors.selectAll,
    (valueStreams: Valuestream[], epics: Epic[]) => {
      const streams: ValueStreamWithEpics[] = valueStreams
        .filter(
          (va) =>
            va.organizationId === organizationId && va.type === 'development'
        )
        .map((valueStream) => ({
          ...valueStream,
          epics: epics.filter((e) => {
            if (e.valueStreams) {
              for (const key in e.valueStreams) {
                if (
                  e.valueStreams[key].id === valueStream.id &&
                  (e.state === 'Implementing' ||
                    e.state === 'Portfolio Backlog' ||
                    e.state === 'Validating on Stage' ||
                    e.state === 'Deploying to Production' ||
                    e.state === 'Releasing')
                ) {
                  return true
                }
              }
            }
          })
        }))

      return streams
    }
  )

export const selectARTWithOrganization = (artId) =>
  createSelector(
    fromARTSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    fromOrganizationSelectors.selectAll,
    fromKanbanSelectors.selectEntities,
    (
      arts: Dictionary<ART>,
      portfolios: Dictionary<PortfolioSafe>,
      organizations: Organization[],
      kanbans: Dictionary<Kanban>
    ) => {
      const art = arts[artId]
      if (art) {
        const portfolio = portfolios[art?.portfolioId]

        const kanban = kanbans[art.kanbanId]
        if (kanban) {
          const artOout = {
            ...art,
            organization: organizations.find(
              (org) => org.id === portfolio?.organizationId
            )?.name,
            kanbanName: kanban.name,
            portfolio: portfolio
          }
          return artOout
        }
      }
    }
  )

export const selectARTsByPortfolioId = createSelector(
  fromARTSelectors.selectAll,

  (
    arts: ART[],

    { id }: { id: string }
  ) => arts.filter((art) => art.portfolioId === id)
)

export const selectPortfolioByEpicId = (itemId) =>
  createSelector(
    fromPortfolioSelectors.selectEntities,
    fromItemsSelectors.selectEntities,

    (portfolios: Dictionary<PortfolioSafe>, items: Dictionary<Epic>) => {
      const item = items[itemId]
      return portfolios[item.portfolioId]
    }
  )
export const getValueStreamByIdWithSolutions = (id: string) =>
  createSelector(
    fromValueStreamSelectors.selectEntities,
    fromSolutionSelectors.selectAll,
    (entitiesVa: Dictionary<Valuestream>, solutions: Solution[]) => {
      const va = entitiesVa[id]
      if (va) {
        const convaOut: ValuestreamWithSolutions = {
          ...va,
          solutionsList: va.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean),
          stages: va.stages?.map((stage) => ({
            ...stage,
            solutionsList: stage.solutions
              ?.map((solutionId) =>
                solutions.find((solution) => solution.id === solutionId.id)
              )
              .filter(Boolean)
          }))
        }
        return convaOut
      }
    }
  )

export const getValueStreamsWithSolutions = () =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (valueStreams: Valuestream[], solutions: Solution[]) => {
      const convaOut: ValuestreamWithSolutions[] = valueStreams.map(
        // eslint-disable-next-line arrow-body-style
        (values) => ({
          ...values,
          solutionsList: values.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean)
        })
      )

      return convaOut
    }
  )

export const getEpicsByValueStreamId = (id: string) =>
  createSelector(
    fromValueStreamSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (entitiesVa: Dictionary<Valuestream>, epics: Epic[]) => {
      const va = entitiesVa[id]

      const epicsOut: Epic[] = epics
        .filter((e) => e.valueStreams?.find((v) => v.id === va.id))
        // eslint-disable-next-line arrow-body-style
        .filter((e) => {
          return e.state === 'Implementing' && e.economicalData !== null
        })
        // eslint-disable-next-line arrow-body-style
        .map((e) => {
          return { ...e, totalScope: e.valueStreams.length }
        })

      return epicsOut
    }
  )

export const getValueStreamsByOrganizationIdAndType = (
  organizationId: string,
  type: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    (entities: Valuestream[]) =>
      entities.filter(
        (va) => va.organizationId === organizationId && va.type === type
      )
  )

export const getDevelopingValueStreamsByOrganizationIdWithSolutions = (
  organizationId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (entities: Valuestream[], solutions: Solution[]) =>
      entities
        .filter(
          (va) =>
            va.organizationId === organizationId && va.type === 'development'
        )
        .map((va) => ({
          ...va,
          solutionsList: va.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean),
          stages: va.stages?.map((stage) => ({
            ...stage,
            solutionsList: stage.solutions
              ?.map((id) => solutions.find((solution) => solution.id === id.id))
              .filter(Boolean)
          }))
        }))
  )

export const getOperationalValueStreamsByOrganizationIdWithSolutions = (
  organizationId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (entities: Valuestream[], solutions: Solution[]) =>
      entities
        .filter(
          (item) =>
            item.organizationId === organizationId &&
            item.type === 'operational'
        )
        .map((va) => ({
          ...va,
          solutionsList: va.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean),
          stages: va.stages?.map((stage) => ({
            ...stage,
            solutionsList: stage.solutions
              ?.map((id) => solutions.find((solution) => solution.id === id.id))
              .filter(Boolean)
          }))
        }))
  )

/*
export const getDevelopingValueStreamsByPortfolioIdWithSolutions = (
  portfolioId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    (entities: Valuestream[], solutions: Solution[], strategicThemes:StrategicTheme[]) =>
      entities
        .filter(
          (item) =>
            item.portfolioId === portfolioId && item.type === 'development'
        )
        .map((va) => ({
          ...va,
          solutionsList: va.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean),
          stages: va.stages?.map((stage) => ({
            ...stage,
            solutionsList: stage.solutions
              ?.map((id) => solutions.find((solution) => solution.id === id.id))
              .filter(Boolean)
          }))
        }))
  )
*/
export const getDevelopingValueStreamsByPortfolioId = (portfolioId: string) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    (entities: Valuestream[]) =>
      entities.filter(
        (item) =>
          item.portfolioId === portfolioId && item.type === 'development'
      )
  )
export const getDevelopingValueStreamsByPortfolioIdWithSolutions = (
  portfolioId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    (
      entities: Valuestream[],
      solutions: Solution[],
      strategicThemes: StrategicTheme[]
    ) =>
      entities
        .filter(
          (item) =>
            item.portfolioId === portfolioId && item.type === 'development'
        )
        .map((va) => {
          const allocatedBudgets = strategicThemes
            .filter((theme) => theme.status === 'ACTIVE')
            .reduce((sum, strategicTheme) => {
              if (strategicTheme.budgetDistribution) {
                const budgetData = strategicTheme.budgetDistribution.find(
                  (data) => data.valueStreamId === va.id
                )
                if (budgetData && budgetData.allocatedBudget) {
                  return sum + budgetData.allocatedBudget
                }
              }
              return sum
            }, 0)

          return {
            ...va,
            economicalData: {
              ...va.economicalData,
              totalAllocatedBudget: allocatedBudgets
            },
            solutionsList: va.solutions
              ?.map((ident) =>
                solutions.find((solution) => solution.id === ident.id)
              )
              .filter(Boolean),
            stages: va.stages?.map((stage) => ({
              ...stage,
              solutionsList: stage.solutions
                ?.map((id) =>
                  solutions.find((solution) => solution.id === id.id)
                )
                .filter(Boolean)
            }))
          }
        })
  )
export const getValueStreamsByIdsWithSolutions = (valueStreamIds: string[]) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromSolutionSelectors.selectAll,
    (entities: Valuestream[], solutions: Solution[]) => {
      const valueStreams: ValuestreamWithSolutions[] = entities
        .filter((item) => valueStreamIds.some((v) => v === item.id))
        .map((va) => ({
          ...va,
          solutionsList: va.solutions
            ?.map((ident) =>
              solutions.find((solution) => solution.id === ident.id)
            )
            .filter(Boolean)
        }))
      return valueStreams
    }
  )

/*
scope: art.valueStreams,
type: 'epic',
portfolioId: art.portfolioId,
columnIds: [art.columns[4].id]

  this.epics1$ = combineLatest([this.art$, this.kanban$]).pipe(
      map(([art, kanban]) => ({
        ...art,
        columns: kanban.columns
      })),
      filter(
        (art) => art.valueStreams !== undefined && art.valueStreams !== null
      ),
      switchMap((art) =>
        this.store.select(
          fromKanbanItemsSelectors.getKanbanItemByScope,

          {
            scope: art.valueStreams,
            type: 'epic',
            portfolioId: art.portfolioId,
            columnIds: [art.columns[4].id]
          }
        )
      )
    )
*/

export const getARTsByPortfolioIdWithTeams = createSelector(
  fromARTSelectors.selectAll,
  fromTeamSelectors.selectAll,
  (arts: ART[], teams: Team[], { portfolioId }: { portfolioId: string }) => {
    const artsList = arts.filter((a) => a.portfolioId === portfolioId)
    artsList.map((art) => {
      const artTeams = teams.filter((team) => team.ARTId === art.id)
      const teamsId: Id[] = []
      artTeams.forEach((artTeam) => teamsId.push({ id: artTeam.id }))
      return { ...art, teams: teamsId }
    })
    return artsList
  }
)
export const getARTsByOrganizationId = (organizationId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    (arts: ART[], portfolios: PortfolioSafe[]) => {
      const portfolioList = portfolios.filter(
        (a) => a.organizationId === organizationId
      )

      const artsOut = []

      portfolioList.forEach((p) => {
        const out = arts.filter((art) => art.portfolioId === p.id)
        if (out) artsOut.push(...out)
      })
      return artsOut
    }
  )

export const getTeamsByOrganizationId = (organizationId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    fromTeamSelectors.selectAll,
    (arts: ART[], portfolios: PortfolioSafe[], teams: Team[]) => {
      const portfolioList = portfolios.filter(
        (a) => a.organizationId === organizationId
      )

      const artsOut: ART[] = []

      const teamsOut = []
      const seenTeams = new Set<string>() // Assuming Team has a unique identifier of type string

      portfolioList.forEach((p) => {
        const out = arts.filter((art) => art.portfolioId === p.id)
        if (out) artsOut.push(...out)
      })
      artsOut.forEach((art) => {
        const out: Team[] = teams.filter((team) => team.ARTId === art.id)
        out.forEach((team) => {
          if (!seenTeams.has(team.id)) {
            // Check if the team.id is already in the Set
            seenTeams.add(team.id) // Add team.id to the Set
            teamsOut.push(team) // Push the team to teamsOut
          }
        })
      })

      const out2: Team[] = teams.filter(
        (team) => team.organizationId === organizationId
      )
      out2.forEach((team) => {
        if (!seenTeams.has(team.id)) {
          // Check if the team.id is already in the Set
          seenTeams.add(team.id) // Add team.id to the Set
          teamsOut.push(team) // Push the team to teamsOut
        }
      })

      return teamsOut
    }
  )

export const getARTByValueStreamWithTeams = (valueStreamId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromTeamSelectors.selectAll,
    (entities: ART[], teamsList: Team[]) => {
      const artsOut: ART[] = []
      entities.forEach((entity) => {
        if (
          entity.valueStreams?.find((va) => va.id === valueStreamId) !==
          undefined
        ) {
          const artTeams = teamsList.filter((team) => team.ARTId === entity.id)
          const teamsId: Id[] = []
          artTeams.forEach((artTeam) => teamsId.push({ id: artTeam.id }))
          artsOut.push({ ...entity, teams: teamsId })
        }
      })

      return artsOut

      // console.log(entitiesOut)
      // return artsOut
    }
  )

export const getAvailableARTsByPortfolioIdWithTeams = (portfolioId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromTeamSelectors.selectAll,
    (arts: ART[], teamsList: Team[]) =>
      arts
        .filter(
          (art) =>
            (art.portfolioId === portfolioId &&
              art.valueStreams === undefined) ||
            (art.valueStreams.length === 0 && art.portfolioId === portfolioId)
        )
        .map((entity) => {
          const artTeams = teamsList.filter((team) => team.ARTId === entity.id)
          const teamsId: Id[] = []
          artTeams.forEach((artTeam) => teamsId.push({ id: artTeam.id }))
          return { ...entity, teams: teamsId }
        })
  )

export const getARTsWithPortfoliosAndOrganizations = createSelector(
  fromARTSelectors.selectAll,
  fromPortfolioSelectors.selectAll,
  fromOrganizationSelectors.selectAll,
  (arts: ART[], portfolios: PortfolioSafe[], organizations: Organization[]) => {
    const artsOut = []
    arts.forEach((art) => {
      const portfolioSAFE = portfolios.find((por) => por.id === art.portfolioId)
      artsOut.push({
        ...art,
        portfolioName: portfolioSAFE?.name,
        portfolioId: portfolioSAFE?.id,
        organization: organizations.find(
          (a) => a.id === portfolioSAFE?.organizationId
        )?.name,
        organizationId: portfolioSAFE?.organizationId
      })
    })
    return artsOut
  }
)
export const getARTsWithPortfoliosAndOrganizationsByOrganizationId = (
  organizationId: string
) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    fromOrganizationSelectors.selectAll,
    (
      arts: ART[],
      portfolios: PortfolioSafe[],
      organizations: Organization[]
    ) => {
      const artsOut = []
      arts.forEach((art) => {
        const portfolioSAFE = portfolios.find(
          (por) => por.id === art.portfolioId
        )
        artsOut.push({
          ...art,
          portfolioName: portfolioSAFE?.name,
          portfolioId: portfolioSAFE?.id,
          organization: organizations.find(
            (a) => a.id === portfolioSAFE?.organizationId
          )?.name,
          organizationId: portfolioSAFE?.organizationId
        })
      })
      return artsOut.filter((art) => art.organizationId === organizationId)
    }
  )

// export interface EpicsPortfolioByFeatureKanbanIndexesProps{
//   kanbanId: string,
//   featureKanbanIndexs: number[]
// }
export const getPortfolioEpicsFromFeaturesByColumns = (
  programKanbanId: string,
  portfolioKanbanColumns: number[]
) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromPortfolioSelectors.selectEntities,
    fromKanbanSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      arts: ART[],
      portfolios: Dictionary<PortfolioSafe>,
      kanbans: Dictionary<Kanban>,
      items: RequirementI[]
    ) => {
      // Verificar si el programKanban existe
      const programKanban = kanbans[programKanbanId]
      if (!programKanban) return []

      // Verificar si el ART existe
      const art = arts.find((a) => a.kanbanId === programKanban.id)
      if (!art) return []

      // Verificar si el portfolio existe
      const portfolioSAFE = portfolios[art.portfolioId]
      if (!portfolioSAFE) return []

      // Verificar si el portfolioKanban existe
      const portfolioKanban = kanbans[portfolioSAFE.kanbanId]
      if (!portfolioKanban || !portfolioKanban.columns) return []

      const portfolioKanbanColumnsFiltered: KanbanColumn<KanbanItemI>[] = []
      for (const index of portfolioKanbanColumns) {
        const column: KanbanColumn<KanbanItemI> = portfolioKanban.columns[index]
        if (column) {
          portfolioKanbanColumnsFiltered.push(column)
        }
      }

      const portfolioepics: RequirementI[] = items.filter(
        (item) => item.name === 'epic' && item.portfolioId === portfolioSAFE.id
      )

      let epicsInColumns2: RequirementI[] = []
      portfolioKanbanColumnsFiltered.forEach((column) => {
        const epics = portfolioepics.filter(
          (epic) => epic.columnId === column.id
        )
        epicsInColumns2 = epicsInColumns2.concat(epics)
      })

      return epicsInColumns2
    }
  )

export const getProgramFeaturesFromStoriesByColumns = (
  teamKanbanId: string,
  indexProgramKanbanColumns: string[]
) =>
  createSelector(
    fromTeamSelectors.selectAll,
    fromARTSelectors.selectAll,
    fromKanbanSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      teams: Team[],
      arts: ART[],
      kanbans: Dictionary<Kanban>,
      items: RequirementI[]
    ) => {
      // 1 - Seleccionem el kanban
      const teamKanban = kanbans[teamKanbanId]

      const team = teams.find((a) => a.kanbanId === teamKanban.id)

      const art = arts.find((a) => a.id === team.ARTId)

      // const portfolioSAFE = portfolios[art.portfolioId]

      // const portfolioKanban = kanbans[portfolioSAFE.kanbanId]
      if (art) {
        const programKanban = kanbans[art.kanbanId]

        const programKanbanColumnsFiltered: KanbanColumn<KanbanItemI>[] = []
        indexProgramKanbanColumns.forEach((stateId) => {
          if (isProgramKanban(programKanban)) {
            const columnOut: KanbanColumn<KanbanItemI> =
              programKanban.columns.find((col) => col.id === stateId)
            programKanbanColumnsFiltered.push(columnOut)
          }
        })

        if (programKanbanColumnsFiltered.length > 0) {
          const featuresInColumns: RequirementI[] = items.filter((it) =>
            programKanbanColumnsFiltered.some((cf) => cf.id === it.columnId)
          )

          return featuresInColumns
        } else return undefined
      } else return undefined
    }
  )
export const selectStrategicThemesByOrganization = (id: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    fromOrganizationSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      strategicThemes: StrategicTheme[],
      organizations: Dictionary<Organization>,
      epicsArray: Epic[]
    ) => {
      // Get the organization by ID
      const organization = organizations[id]
      if (!organization) {
        return []
      }

      // Filter and map strategic themes for the given organization
      const strategicThemeFound: StrategicWithKeyResults[] = strategicThemes
        .filter((st) => st.organizationId === organization.id) // Filter by organization ID
        .map((strategic) => {
          // Map each strategic theme to include keyResults with associated epics
          const keyResultsWithEpics = strategic.keyResults
          return {
            ...strategic,
            keyResults: keyResultsWithEpics?.map((kr) => ({
              ...kr,
              // Filter epics where the keyResultsId array includes the key result ID and the state is 'Done' or 'Implementing'
              epicsImplementingAndDone: epicsArray.filter(
                (e) =>
                  e.keyResultsId?.includes(kr.id) &&
                  (e.state === 'Done' || e.state === 'Implementing')
              ),
              // Filter all epics where the keyResultsId array includes the key result ID
              allEpics: epicsArray.filter((e) =>
                e.keyResultsId?.includes(kr.id)
              )
            }))
          }
        })

      return strategicThemeFound
    }
  )

export const selectActiveStrategicThemesByOrganization = (id: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    fromOrganizationSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      streategicThemes: StrategicTheme[],
      organizations: Dictionary<Organization>,
      epicsArray: Epic[]
    ) => {
      const organization = organizations[id]
      if (!organization) {
        return []
      }
      const strategicThemeFound: StrategicWithEpics[] = streategicThemes
        .filter(
          (st) =>
            st.organizationId === organization.id && st.status === 'ACTIVE'
          // eslint-disable-next-line arrow-body-style
        )
        // eslint-disable-next-line arrow-body-style
        .map((strategic) => {
          return {
            ...strategic,
            epicsFound: epicsArray
              .filter(
                (ep) => ep.state === 'Done' || ep.state === 'Implementing'
              )
              .filter((e, i) => {
                for (const key in strategic.epics) {
                  if (e.id === strategic.epics[key].id) {
                    return e
                  }
                }
              }),

            allEpics: epicsArray.filter((e, i) => {
              for (const key in strategic.epics) {
                if (e.id === strategic.epics[key].id) {
                  return e
                }
              }
            })
          }
        })

      return strategicThemeFound
    }
  )

export const selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics =
  (id: string) =>
    createSelector(
      fromStrategicThemesSelectors.selectAll,
      fromOrganizationSelectors.selectEntities,
      fromItemsSelectors.selectAll,
      (
        strategicThemes: StrategicTheme[],
        organizations: Dictionary<Organization>,
        epicsArray: Epic[]
      ) => {
        const organization = organizations[id]
        if (!organization) {
          return []
        }

        const strategicThemeFound: StrategicThemeRowWithKeyResults[] =
          strategicThemes
            .filter(
              (st) =>
                st.organizationId === organization.id && st.status === 'ACTIVE'
            )
            .map((strategic) => {
              let numOfEpics = 0
              let totalForecastedEpicCost = 0
              let totalExpectedBenefit = 0

              return {
                ...strategic,
                keyResults: strategic.keyResults?.map((keyResult) => {
                  epicsArray
                    .filter(
                      (epic) =>
                        epic.keyResultsId?.includes(keyResult.id) &&
                        (epic.state === 'Done' ||
                          epic.state === 'Implementing' ||
                          epic.state === 'Portfolio Backlog')
                    )
                    .forEach((item) => {
                      numOfEpics++
                      totalForecastedEpicCost += item.forecastedEpicCost || 0
                      totalExpectedBenefit += item.expectedBenefit || 0
                    })

                  return {
                    ...keyResult,
                    epicsPortfolioBacklogImplementingAndDone: epicsArray.filter(
                      (epic) =>
                        epic.keyResultsId?.includes(keyResult.id) &&
                        (epic.state === 'Done' ||
                          epic.state === 'Implementing' ||
                          epic.state === 'Portfolio Backlog')
                    ),
                    allEpics: epicsArray.filter((epic) =>
                      epic.keyResultsId?.includes(keyResult.id)
                    )
                  }
                }),
                numOfEpics,
                totalForecastedEpicCost,
                totalExpectedBenefit
              }
            })

        return strategicThemeFound
      }
    )

function filterKeyResults(
  epicsArray: Epic[],
  strategic: StrategicTheme,
  kr: KeyResult
): Epic[] {
  const keyResultsWithEpics = strategic.keyResults
  let numOfEpics = 0
  let totalForecastedEpicCost = 0
  let totalExpectedBenefit = 0

  return epicsArray.filter((epic) => {
    if (
      epic.keyResultsId?.includes(kr.id) &&
      (epic.state === 'Done' ||
        epic.state === 'Implementing' ||
        epic.state === 'Portfolio Backlog')
    ) {
      numOfEpics++
      totalForecastedEpicCost += epic.forecastedEpicCost || 0
      totalExpectedBenefit += epic.expectedBenefit || 0
      return true // Match found
    }
    return false // No match
  })
}

export const selectStrategicThemesByOrganizationWithKeyResultsAndItsItems = (
  organizationId: string
) =>
  createSelector(
    fromUserSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    fromOrganizationSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      users: User[],
      streategicThemes: StrategicTheme[],
      organizations: Dictionary<Organization>,
      items: RequirementI[]
    ) => {
      const organization = organizations[organizationId]
      if (!organization) {
        return []
      }
      const strategicThemeFound: StrategicThemeRow[] = streategicThemes
        .filter(
          (st) => st.organizationId === organization.id
          // eslint-disable-next-line arrow-body-style
        )
        // eslint-disable-next-line arrow-body-style
        .map((strategic) => {
          let numberOfItems = 0
          let totalForecastedEpicCost = 0
          let totalExpectedBenefit = 0
          let totalAllocated = 0
          let totalValueStreamAllocated = 0
          let totalActual = 0
          if (strategic.budgetDistribution) {
            totalValueStreamAllocated = strategic.budgetDistribution.reduce(
              (acc: number, cur: EconomicalData) => {
                if (cur.allocatedBudget) {
                  return acc + cur.allocatedBudget
                }
                return acc
              },
              0
            )
            totalActual = strategic.budgetDistribution.reduce(
              (acc: number, cur: EconomicalData) => {
                if (cur.actualCost) {
                  return acc + cur.actualCost
                }
                return acc
              },
              0
            )
          } else {
            totalValueStreamAllocated = 0
            totalActual = 0
          }
          strategic.keyResults?.forEach((keyResult) => {
            items
              .filter(filterByStateAndKeyResult, {
                keyResultId: keyResult.id
              })
              .forEach((item) => {
                numberOfItems = numberOfItems + 1
                if (isEpicI(item)) {
                  totalForecastedEpicCost =
                    totalForecastedEpicCost + item.forecastedEpicCost
                  totalExpectedBenefit =
                    totalExpectedBenefit + item.expectedBenefit
                  if (item.economicalData?.allocatedBudget)
                    totalAllocated =
                      totalAllocated + item.economicalData?.allocatedBudget
                }
              })
          })
          return {
            ...strategic,
            owner: users.find((user) => user.id === strategic.strategicOwnerId)
              ?.name,
            numOfItems: numberOfItems,
            totalAllocated: totalAllocated,
            totalActual: totalActual,
            totalForecastedEpicCost: totalForecastedEpicCost,
            totalExpectedBenefit: totalExpectedBenefit,
            valueStreamAllocated: totalValueStreamAllocated
          }
        })
        .sort((a, b) => {
          const textA = a.description.toUpperCase()
          const textB = b.description.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
      return strategicThemeFound
    }
  )

export const selectStrategicThemesByPortfolioIdWithKeyResultsAndItsItems = (
  portfolioId: string
) =>
  createSelector(
    fromUserSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    fromPortfolioSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      users: User[],
      streategicThemes: StrategicTheme[],
      portfolios: Dictionary<PortfolioSafe>,
      items: RequirementI[]
    ) => {
      const portfolio = portfolios[portfolioId]
      if (!portfolio) {
        return []
      }
      const strategicThemeFound: StrategicThemeRow[] = streategicThemes
        .filter(
          (st) => st.portfolioId === portfolioId
          // eslint-disable-next-line arrow-body-style
        )
        // eslint-disable-next-line arrow-body-style
        .map((strategic) => {
          let numberOfItems = 0
          let totalForecastedEpicCost = 0
          let totalExpectedBenefit = 0
          let totalAllocated = 0
          let totalValueStreamAllocated = 0
          let totalActual = 0
          if (strategic.budgetDistribution) {
            totalValueStreamAllocated = strategic.budgetDistribution.reduce(
              (acc: number, cur: EconomicalData) => {
                if (cur.allocatedBudget) {
                  return acc + cur.allocatedBudget
                }
                return acc
              },
              0
            )
            totalActual = strategic.budgetDistribution.reduce(
              (acc: number, cur: EconomicalData) => {
                if (cur.actualCost) {
                  return acc + cur.actualCost
                }
                return acc
              },
              0
            )
          } else {
            totalValueStreamAllocated = 0
            totalActual = 0
          }
          strategic.keyResults?.forEach((keyResult) => {
            items
              .filter(filterByStateAndKeyResult, {
                keyResultId: keyResult.id
              })
              .forEach((item) => {
                numberOfItems = numberOfItems + 1
                if (isEpicI(item)) {
                  totalForecastedEpicCost =
                    totalForecastedEpicCost + item.forecastedEpicCost
                  totalExpectedBenefit =
                    totalExpectedBenefit + item.expectedBenefit
                  if (item.economicalData?.allocatedBudget)
                    totalAllocated =
                      totalAllocated + item.economicalData?.allocatedBudget
                }
              })
          })
          return {
            ...strategic,
            owner: users.find((user) => user.id === strategic.strategicOwnerId)
              ?.name,
            numOfItems: numberOfItems,
            totalAllocated: totalAllocated,
            totalActual: totalActual,
            totalForecastedEpicCost: totalForecastedEpicCost,
            totalExpectedBenefit: totalExpectedBenefit,
            valueStreamAllocated: totalValueStreamAllocated
          }
        })
        .sort((a, b) => {
          const textA = a.description.toUpperCase()
          const textB = b.description.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
      return strategicThemeFound
    }
  )

export const selectStrategicThemesByIdWithKeyResultsAndItsItems = (
  strategicThemeId: string
) =>
  createSelector(
    fromUserSelectors.selectAll,
    fromStrategicThemesSelectors.selectEntities,
    fromItemsSelectors.selectAll,
    (
      users: User[],
      strategicThemes: Dictionary<StrategicTheme>,
      items: RequirementI[]
    ) => {
      const strategicTheme = strategicThemes[strategicThemeId]
      if (!strategicTheme) return null // Return null if strategic theme is not found

      // Initialize aggregates
      let numberOfItems = 0
      let totalForecastedEpicCost = 0
      let totalExpectedBenefit = 0
      let totalAllocated = 0
      const totalValueStreamAllocated =
        strategicTheme.budgetDistribution?.reduce(
          (acc, cur) => acc + (cur.allocatedBudget || 0),
          0
        ) || 0
      const totalActual =
        strategicTheme.budgetDistribution?.reduce(
          (acc, cur) => acc + (cur.actualCost || 0),
          0
        ) || 0

      // Process key results
      strategicTheme.keyResults?.forEach((keyResult) => {
        items
          .filter((item) =>
            item.keyResultsId?.some((id) => keyResult.id === id)
          ) // Check if the keyResultId is in the array
          .forEach((item) => {
            numberOfItems++
            if (isEpicI(item)) {
              totalForecastedEpicCost += item.forecastedEpicCost || 0
              totalExpectedBenefit += item.expectedBenefit || 0
              totalAllocated += item.economicalData?.allocatedBudget || 0
            }
          })
      })

      return {
        ...strategicTheme,
        owner: users.find((user) => user.id === strategicTheme.strategicOwnerId)
          ?.name,
        numOfItems: numberOfItems,
        totalAllocated,
        totalActual,
        totalForecastedEpicCost,
        totalExpectedBenefit,
        valueStreamAllocated: totalValueStreamAllocated
      }
    }
  )

function filterByStateAndKeyResult(item: RequirementI) {
  return (
    item.keyResultsId &&
    item.keyResultsId?.includes(this.keyResultId) && // Check if keyResultId includes the current keyResultId
    ['Done', 'Implementing', 'Portfolio Backlog'].includes(item.state)
  )
}

export const selectStrategicThemesByOrganizationByPortfolioId = (
  portfolioId: string
) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    fromOrganizationSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    (
      streategicThemes: StrategicTheme[],
      organizations: Dictionary<Organization>,
      portfolios: Dictionary<PortfolioSafe>
    ) => {
      const portfolio = portfolios[portfolioId]
      const organization = organizations[portfolio.organizationId]
      const strategicThemesFound: StrategicTheme[] = streategicThemes.filter(
        (st) => st.organizationId === organization.id
      )

      return strategicThemesFound
    }
  )

export const selectStrategicThemesByPortfolioId = (portfolioId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,

    (streategicThemes: StrategicTheme[]) => {
      const strategicThemesFound: StrategicTheme[] = streategicThemes.filter(
        (st) => st.portfolioId === portfolioId
      )

      return strategicThemesFound
    }
  )
export const selectActiveStrategicThemesByOrganizationUsingPortfolioId = (
  portfolioId: string
) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    fromOrganizationSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    (
      streategicThemes: StrategicTheme[],
      organizations: Dictionary<Organization>,
      portfolios: Dictionary<PortfolioSafe>
    ) => {
      const portfolio = portfolios[portfolioId]
      if (!portfolio) {
        return []
      }
      const organization = organizations[portfolio.organizationId]
      if (!organization) {
        return []
      }
      const strategicThemesFound: StrategicTheme[] = streategicThemes.filter(
        (st) => st.organizationId === organization.id && st.status === 'ACTIVE'
      )

      return strategicThemesFound
    }
  )

export const selectActiveStrategicThemesByItemId = (itemId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    fromItemsSelectors.selectEntities,
    (strategicThemes: StrategicTheme[], items: Dictionary<RequirementI>) =>
      strategicThemes.filter((strategicTheme) => {
        strategicTheme.keyResults?.forEach((keyResult) => {
          const req = items[itemId]

          if (req.keyResultsId?.includes(keyResult.id)) {
            return true
          } else {
            return false
          }
        })
      })
  )

export const selectActiveStrategicThemesByKeyResultId = (keyResultId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    (strategicThemes: StrategicTheme[]) =>
      strategicThemes.filter((strategicTheme) => {
        let result = false

        strategicTheme.keyResults?.forEach((keyResult) => {
          if (keyResultId === keyResult.id) {
            result = true
          } else result = false
        })

        return result
      })
  )

export const selectSolutionsByStage = (
  valueStreamId: string,
  stageId: string
) =>
  createSelector(
    fromSolutionSelectors.selectAll,
    fromValueStreamSelectors.getValueStreamById(valueStreamId),
    (solutions: Solution[], valueStream: Valuestream) => {
      const stage = valueStream.stages.find((s) => s.id === stageId)
      if (!stage || !stage.solutions) {
        return []
      }

      // Extract IDs of solutions in the stage for faster lookup
      const stageSolutionIds = stage.solutions.map((sol) => sol.id)

      // Filter solutions that have IDs in stageSolutionIds
      const solutionsOut: Solution[] = solutions.filter((s) =>
        stageSolutionIds.includes(s.id)
      )

      return solutionsOut
    }
  )

export const selectAllSolutionsWithStreamsAndPortfolio = () =>
  createSelector(
    fromOrganizationSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    getValueStreamsWithSolutions(),
    fromSolutionSelectors.selectAll,
    (
      organizations: Organization[],
      portfolios: PortfolioSafe[],
      valuesStream: ValuestreamWithSolutions[],
      solutions: Solution[]
    ) => {
      const valueStreamWithPortfoliosNames: any[] = valuesStream.map((vs) => ({
        ...vs,
        portfolioname: portfolios.find((p) => p.id === vs.portfolioId)
      }))
      const solutionWithVs = solutions.map((solution) => ({
        ...solution,
        vs: valueStreamWithPortfoliosNames.filter((v) => {
          if (v.solutions) {
            for (const key in v.solutions) {
              if (v.solutions[key].id === solution.id) {
                return v
              }
            }
          }
        }),
        organization: organizations.find(
          (a) => a.id === solution?.organizationId
        )?.name
      }))

      return solutionWithVs
    }
  )

export const getDevelopmentValueStreamsBySolutions = (ids: IdSolution[]) =>
  createSelector(
    getValueStreamsWithSolutions(),
    (valueStreams: ValuestreamWithSolutions[]) => {
      if (!ids) return

      return valueStreams
        .filter((v) => v.type === 'development')
        .filter((valueStream) => valueStream.solutions !== undefined)
        .filter((valueStream) => {
          // const deliveredSolutions = valueStream.solutions

          const found = ids.some(
            (r) => valueStream.solutions.map((s) => s.id).indexOf(r.id) > -1
          )
          if (found) return true
          else return false
        })
    }
  )

export const getOperationalValueStreamsBySolutions = (ids: any[]) =>
  createSelector(
    getValueStreamsWithSolutions(),
    fromSolutionSelectors.selectAll,
    (valueStreams: ValuestreamWithSolutions[], solutions: Solution[]) => {
      if (!ids) return
      const valueStreamOut = []

      const filteredSolutions = solutions.filter((solution) => {
        for (const key in ids) {
          if (solution.id === ids[key].id) {
            return solution
          }
        }
      })
      const devVa = valueStreams
        .filter((v) => v.type === 'operational')
        .filter((valueStream) => {
          const s = valueStream.solutions
          const solutionOfStages = valueStream.stages?.map((stage) => {
            if (!stage.solutions) return null
            return stage.solutions
          })

          for (const key in s) {
            for (const i in ids) {
              if (ids[i].id === s[key].id) {
                if (!valueStreamOut.includes(valueStream)) {
                  valueStreamOut.push(valueStream)
                }
              }
            }
          }

          for (const index in solutionOfStages) {
            for (const key in solutionOfStages[index]) {
              filteredSolutions.forEach((solution) => {
                if (solution.id === solutionOfStages[index][key].id) {
                  if (!valueStreamOut.includes(valueStream)) {
                    valueStreamOut.push(valueStream)
                  }
                }
              })
            }
          }
        })

      return valueStreamOut.map((valueStream) => {
        const solutionsOfTheVs =
          valueStream.solutionsList && valueStream.solutionsList.length > 0
            ? valueStream.solutionsList
            : []

        return {
          ...valueStream,
          stages: valueStream.stages?.map((stage) => ({
            ...stage,
            solutionsList: stage.solutions
              ?.map((id) => solutions.find((solution) => solution.id === id.id))
              .filter(Boolean)
          }))
        }
      })
    }
  )

export const selectParentKanbanStatesByTeamId = (teamId: string) =>
  createSelector(
    fromKanbanSelectors.selectEntities,
    fromARTSelectors.selectEntities,
    fromTeamSelectors.selectEntities,

    (
      kanbans: Dictionary<Kanban>,
      arts: Dictionary<ART>,
      teams: Dictionary<Team>
    ) => {
      const arrayOut: States[] = []
      const team = teams[teamId]
      // console.log('ART', art, artId)
      const art = arts[team.ARTId]
      if (art === undefined) return

      const kanban = kanbans[art.kanbanId]
      if (kanban === undefined) return
      kanban.columns.forEach((column, index) =>
        arrayOut.push({ id: column.id, name: column.name, index: index })
      )
      return arrayOut
    }
  )
export const selectStatesByKanbanId = (kanbanId: string) =>
  createSelector(
    fromKanbanSelectors.selectEntities,

    (kanbans: Dictionary<Kanban>) => {
      const arrayOut: States[] = []

      const kanban = kanbans[kanbanId]
      if (kanban === undefined) return
      kanban.columns.forEach((column, index) =>
        arrayOut.push({ id: column.id, name: column.name, index: index })
      )
      return arrayOut
    }
  )

export const getAvailableTeamsForStoriesByParent = (parentId: string) =>
  createSelector(
    fromItemsSelectors.selectAll,
    fromTeamSelectors.selectEntities,
    (features: RequirementI[], teams: Dictionary<Team>) => {
      const teamsOut: Team[] = []
      const feature: Feature = features.find(
        (f) => f.id === parentId
      ) as Feature
      if (!feature) return

      feature?.teams?.forEach((t) => {
        if (teams[t.id] !== undefined) teamsOut.push(teams[t.id])
      })

      return teamsOut
    }
  )

export const getArtWithOrganization = (artId) =>
  createSelector(
    fromARTSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    fromOrganizationSelectors.selectEntities,
    (
      arts: Dictionary<ART>,
      portfolioSAFEList: Dictionary<PortfolioSafe>,
      organizations: Dictionary<Organization>
    ) => {
      const art = arts[artId]
      if (art === undefined) return undefined
      const portfolio = portfolioSAFEList[art.portfolioId]
      if (portfolio === undefined) return undefined
      const organization = organizations[portfolio.organizationId]
      if (organization === undefined) return undefined

      const artOut: ARTWithOrganization = {
        ...art,
        organizationId: organization.id
      }

      return artOut
    }
  )

export const selectSolutionsFromArt = (id: string) =>
  createSelector(
    fromARTSelectors.selectEntities,
    fromSolutionSelectors.selectAll,
    (arts, solutions) => {
      const artOut = arts[id]
      if (!artOut) return undefined

      const solutionsOut = solutions.filter((solution) => {
        for (const key in artOut.solutions) {
          if (solution.id === artOut.solutions[key].id) {
            return solution
          }
        }
      })

      return solutionsOut
    }
  )

export const selectKeyResultsWithEpics = (strategicThemeId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectEntities,
    fromItemsSelectors.selectAll,

    (entities: Dictionary<StrategicTheme>, epics: Epic[]) =>
      entities[strategicThemeId]?.keyResults?.map((keyResult) => ({
        ...keyResult,
        epicsImplementingAndDone: epics.filter(
          (e) =>
            e.keyResultsId?.includes(keyResult?.id) &&
            (e.state === 'Done' || e.state === 'Implementing')
        ),
        allEpics: epics.filter(
          (e) => e.keyResultsId?.includes(keyResult?.id) // Match by keyResultId now as an array
        )
      }))
  )

export const selectKeyResultsWithItems = (strategicThemeId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectEntities,
    fromItemsSelectors.selectAll,

    (entities: Dictionary<StrategicTheme>, items: RequirementI[]) =>
      entities[strategicThemeId]?.keyResults?.map((keyResult) => ({
        ...keyResult,
        itemsImplementingAndDone: items.filter(
          (item) =>
            item.keyResultsId?.includes(keyResult.id) && // Check if keyResultsId includes the current keyResult's id
            (item.state === 'Done' ||
              item.state === 'Analyzing' ||
              item.state === 'Program Backlog' ||
              item.state === 'Implementing' ||
              item.state === 'Validating on Stage' ||
              item.state === 'Deploying to Production' ||
              item.state === 'Portfolio Backlog')
        ),
        allItems: items.filter(
          (item) => item.keyResultsId?.includes(keyResult.id) // Check if keyResultsId includes the current keyResult's id
        )
      }))
  )

export const selectAllKeyResultsWithEpicsWithStrategicThemeActiveByOrganizationId =
  (organizationId: String) =>
    createSelector(
      fromStrategicThemesSelectors.selectAll,
      fromItemsSelectors.selectAll,

      (strategicThemes: StrategicTheme[], epics: Epic[]) => {
        const strategicThemesOfTheOrg = strategicThemes
          .filter((st) => st.organizationId === organizationId)
          .filter((st) => st.status === 'ACTIVE')
        const keyResults: any[] = []

        for (const key in strategicThemesOfTheOrg) {
          if (!strategicThemesOfTheOrg[key].keyResults) return

          strategicThemesOfTheOrg[key].keyResults?.map((keyResult) => {
            const kr = {
              ...keyResult,
              epicsImplementingAndDone: epics.filter(
                (e) =>
                  e.keyResultsId?.includes(keyResult?.id) &&
                  (e.state === 'Done' || e.state === 'Implementing')
              ),
              allEpics: epics.filter(
                (e) => e.keyResultsId?.includes(keyResult?.id) // Match by keyResultId now as an array
              )
            }
            keyResults.push(kr)
          })
          return keyResults
        }
      }
    )

export const getStageAndSolutionLinkArrow = (
  stageIndex: number,
  solution: SolutionWithItsStagesAndColor,
  arrowNumber: number
) =>
  createSelector(
    fromValueStreamSelectors.selectEntities,

    (entities: Dictionary<Valuestream>) => {
      if (stageIndex === null || solution === null) return

      const stage = solution.stages[arrowNumber]

      // const arowsOfTheStage = []

      const arrow: ArrowRelation = {
        stageIndex: stageIndex,
        solutionId: solution?.id,
        stage: stage,
        stageId: stage?.id
      }

      return arrow
    }
  )

export const selectTotalBudgetOfOrganization = (organizationId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,

    (strategicThemes: StrategicTheme[]) => {
      const totalBudget: number = strategicThemes
        .filter(
          (st) =>
            st.status === 'ACTIVE' &&
            st.organizationId === organizationId &&
            st.amount !== undefined &&
            st.amount !== null
        )
        .map((st) => st.amount)
        .reduce((e, n) => e + n, 0)

      return totalBudget
    }
  )
export interface BudgetDetails {
  allocatedBudget: number
  totalBudget: number
  availableBudget: number
  allocatedBudgetPercent: number
  availableBudgetPercent: number
}

export const selectBugetDetailsOfOrganization = (organizationId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,

    (strategicThemes: StrategicTheme[]) => {
      const filteredThemes = strategicThemes.filter(
        (st) => st.status === 'ACTIVE' && st.organizationId === organizationId
      )

      let totalBudget = 0
      let allocatedBudget = 0

      if (filteredThemes.length > 0) {
        totalBudget = filteredThemes.reduce(
          (total, st) => total + (st.amount ?? 0),
          0
        )

        allocatedBudget = filteredThemes.reduce((total, st) => {
          const budgetDistribution = st.budgetDistribution
          if (budgetDistribution) {
            const themeAllocatedBudget = budgetDistribution.reduce(
              (themeTotal, budgetData) =>
                themeTotal + (budgetData.allocatedBudget || 0),
              0
            )
            return total + themeAllocatedBudget
          }
          return total
        }, 0)
      }

      const availableBudget = totalBudget - allocatedBudget
      const allocatedBudgetPercent =
        totalBudget !== 0 ? (allocatedBudget * 100) / totalBudget : 0
      const availableBudgetPercent =
        totalBudget !== 0 ? (availableBudget * 100) / totalBudget : 0

      const details: BudgetDetails = {
        totalBudget,
        allocatedBudget,
        availableBudget,
        allocatedBudgetPercent,
        availableBudgetPercent
      }

      return details
    }
  )

export const selectBugetDetailsOfPortfolio = (portfolioId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,

    (strategicThemes: StrategicTheme[]) => {
      const filteredThemes = strategicThemes.filter(
        (st) => st.status === 'ACTIVE' && st.portfolioId === portfolioId
      )

      let totalBudget = 0
      let allocatedBudget = 0

      if (filteredThemes.length > 0) {
        totalBudget = filteredThemes.reduce(
          (total, st) => total + (st.amount ?? 0),
          0
        )

        allocatedBudget = filteredThemes.reduce((total, st) => {
          const budgetDistribution = st.budgetDistribution
          if (budgetDistribution) {
            const themeAllocatedBudget = budgetDistribution.reduce(
              (themeTotal, budgetData) =>
                themeTotal + (budgetData.allocatedBudget || 0),
              0
            )
            return total + themeAllocatedBudget
          }
          return total
        }, 0)
      }

      const availableBudget = totalBudget - allocatedBudget
      const allocatedBudgetPercent =
        totalBudget !== 0 ? (allocatedBudget * 100) / totalBudget : 0
      const availableBudgetPercent =
        totalBudget !== 0 ? (availableBudget * 100) / totalBudget : 0

      const details: BudgetDetails = {
        totalBudget,
        allocatedBudget,
        availableBudget,
        allocatedBudgetPercent,
        availableBudgetPercent
      }

      return details
    }
  )
/* export const selectAllocatedBudgetOfOrganization = (organizationId: string) =>
  createSelector(
    selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics(
      organizationId
    ),

    (strategicThemes: StrategicThemeRowWithKeyResults[]) => {
      let allocatedBudget = 0
      let total = 0
      // eslint-disable-next-line guard-for-in
      strategicThemes.forEach((strategicTheme) => {
        if (strategicTheme.objectives) {
          strategicTheme.objectives.forEach((objective) => {
            if (objective.keyResults) {
              total = objective.keyResults
                .map((ep: KeyResultWithEpicsStatistics) =>
                  ep?.epicsPortfolioBacklogImplementingAndDone
                    .map((epic) => epic.economicalData?.allocatedBudget)
                    .filter((val) => !!val)
                    .reduce((n, e) => n + e, 0)
                )
                .reduce((n, e) => n + e, 0)
            }
            allocatedBudget += total
          })
        }
      })

      return allocatedBudget
    }
  )
*/
export const selectAllocatedBudgetOfOrganization = (organizationId: string) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    (strategicThemes: StrategicTheme[]) => {
      const allocatedBudget = strategicThemes
        .filter(
          (st) => st.status === 'ACTIVE' && st.organizationId === organizationId
        )
        .reduce((totalBudget1, strategicTheme) => {
          const budgetDistribution = strategicTheme.budgetDistribution
          if (budgetDistribution) {
            const themeAllocatedBudget = budgetDistribution.reduce(
              (themeTotalBudget, budgetData) =>
                themeTotalBudget + (budgetData.allocatedBudget || 0),
              0
            )
            return totalBudget1 + themeAllocatedBudget
          }
          return totalBudget1
        }, 0)

      return allocatedBudget
    }
  )
export const selectAllocatedValueSteamBudgetOfOrganization = (
  organizationId: string
) =>
  createSelector(
    selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics(
      organizationId
    ),

    (strategicThemes: any[]) => {
      let allocatedBudget = 0
      let total = 0
      // eslint-disable-next-line guard-for-in
      for (const key in strategicThemes) {
        if (strategicThemes[key].objectives) {
          strategicThemes[key].objectives.forEach((objective) => {
            if (objective.keyResults) {
              total = objective.keyResults
                .map((ep: any) =>
                  ep?.epicsPortfolioBacklogImplementingAndDone
                    .map((epic) => epic.forecastedEpicCost)
                    .reduce((n, e) => n + e, 0)
                )
                .reduce((n, e) => n + e, 0)
            }
            allocatedBudget += total
          })
        }
      }
      return allocatedBudget
    }
  )
export const selectActualCostOfOrganization = (organizationId: string) =>
  createSelector(
    selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics(
      organizationId
    ),

    (strategicThemes: any[]) => {
      let actualCost = 0
      let total = 0
      // eslint-disable-next-line guard-for-in
      for (const key in strategicThemes) {
        if (strategicThemes[key].objectives) {
          strategicThemes[key].objectives.forEach((objective) => {
            if (objective.keyResults) {
              total = objective.keyResults
                .map((ep: any) =>
                  ep?.epicsPortfolioBacklogImplementingAndDone
                    .filter(
                      (epic: Epic) =>
                        epic.state === 'Implementing' ||
                        epic.state === 'Portfolio Backlog'
                    )
                    .map((epic: Epic) => {
                      if (
                        epic.economicalData &&
                        epic.economicalData.actualCost
                      ) {
                        return epic.economicalData.actualCost
                      } else {
                        return 0
                      }
                    })
                    .reduce((n, e) => n + e, 0)
                )
                .reduce((n, e) => n + e, 0)
            }
            actualCost += total
          })
        }
      }

      return actualCost
    }
  )
export const selectExpectedBenefitOfOrganization = (organizationId: string) =>
  createSelector(
    selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics(
      organizationId
    ),

    (strategicThemes: any[]) => {
      let expectedBenefit = 0
      let total = 0
      // eslint-disable-next-line guard-for-in
      for (const key in strategicThemes) {
        if (strategicThemes[key].objectives) {
          strategicThemes[key].objectives.forEach((objective) => {
            if (objective.keyResults) {
              total = objective.keyResults
                .map((ep: any) =>
                  ep?.epicsPortfolioBacklogImplementingAndDone
                    .filter(
                      (epic: Epic) =>
                        epic.state === 'Implementing' ||
                        epic.state === 'Portfolio Backlog'
                    )
                    .map((epic: Epic) => epic.expectedBenefit)
                    .reduce((n, e) => n + e, 0)
                )
                .reduce((n, e) => n + e, 0)
            }
            expectedBenefit += total
          })
        }
      }
      return expectedBenefit
    }
  )
export const selectForecastedCostOfOrganization = (organizationId: string) =>
  createSelector(
    selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics(
      organizationId
    ),

    (strategicThemes: any[]) => {
      let forecastedEpicCost = 0
      let total = 0
      // eslint-disable-next-line guard-for-in
      for (const key in strategicThemes) {
        if (strategicThemes[key].keyResults) {
          total = strategicThemes[key].keyResults
            .map((ep: any) =>
              ep?.epicsPortfolioBacklogImplementingAndDone
                .filter(
                  (epic: Epic) =>
                    epic.state === 'Implementing' ||
                    epic.state === 'Portfolio Backlog'
                )
                .map((epic: Epic) => epic.forecastedEpicCost)
                .reduce((n, e) => n + e, 0)
            )
            .reduce((n, e) => n + e, 0)
        }
        forecastedEpicCost += total
      }
      return forecastedEpicCost
    }
  )
export const selectReturnOnInvestmentOfOrganization = (
  organizationId: string
) =>
  createSelector(
    selectActiveStrategicThemesByOrganizationWithKeyResultsAndItsEpics(
      organizationId
    ),

    (strategicThemes: any[]) => {
      let forecastedEpicCost = 0
      let totalforecastedEpicCost = 0
      let expectedBenefit = 0
      let totalexpectedBenefit = 0
      // eslint-disable-next-line guard-for-in
      for (const key in strategicThemes) {
        if (strategicThemes[key].keyResults) {
          totalforecastedEpicCost = strategicThemes[key].keyResults
            .map((ep: any) =>
              ep?.epicsPortfolioBacklogImplementingAndDone
                .filter(
                  (epic: Epic) =>
                    epic.state === 'Implementing' ||
                    epic.state === 'Portfolio Backlog'
                )
                .map((epic: Epic) => epic.forecastedEpicCost)
                .reduce((n, e) => n + e, 0)
            )
            .reduce((n, e) => n + e, 0)
          totalexpectedBenefit = strategicThemes[key].keyResults
            .map((ep: any) =>
              ep?.epicsPortfolioBacklogImplementingAndDone
                .filter(
                  (epic: Epic) =>
                    epic.state === 'Implementing' ||
                    epic.state === 'Portfolio Backlog'
                )
                .map((epic: Epic) => epic.expectedBenefit)
                .reduce((n, e) => n + e, 0)
            )
            .reduce((n, e) => n + e, 0)
        }
        forecastedEpicCost += totalforecastedEpicCost
        expectedBenefit += totalexpectedBenefit
      }
      return expectedBenefit / forecastedEpicCost
    }
  )

/*
  export const getOrganizationMembersWithLastLogin = () =>
  createSelector(
    fromOrganizationMemberSelectors.selectAll,
    fromUserSelectors.selectAll,
    fromOrganizationSelectors.selectAll

    (
      arts: OrganizationMember[],
      // portfolios: Dictionary<PortfolioSafe>,

      items: RequirementI[],
      {
        organizationId,

      }: { organizationId: string; }
    ) => {}
  )
  */
export const getOrganizationMembersWithRoles = (organizationId: string) =>
  createSelector(
    fromUserSelectors.selectEntities,
    fromOrganizationMemberSelectors.selectAll,

    (users: Dictionary<User>, members: OrganizationMember[]) =>
      members
        .filter((member) => member.organizationId === organizationId)
        .map((member) => ({
          ...member,
          roles: users[member.userId]?.roles,
          lastLogin: users[member.userId]?.lastLogin,
          name: users[member.userId]?.name,
          email: users[member.userId]?.email,
          emailVerified: users[member.userId]?.email_verified
        }))
  )

export const getUsersNotInOrganization = (organizationId: string) =>
  createSelector(
    fromUserSelectors.selectEntities,
    fromOrganizationMemberSelectors.selectAll,
    (users: Dictionary<User>, members: OrganizationMember[]) =>
      Object.values(users).filter(
        (user) =>
          !members.some(
            (member) =>
              member.organizationId === organizationId &&
              member.userId === user.id
          )
      )
  )

export const getTeamsByIds = (teamsIds: Id[]) =>
  createSelector(
    fromTeamSelectors.selectAll,

    (entities: Team[]) => {
      if (!teamsIds || teamsIds.length === 0) return
      const teams = entities.filter((item) =>
        teamsIds.some((team) => team.id === item.id)
      )

      return teams
    }
  )
export const selectOrganizationByKanbanId = (kanbanId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromOrganizationSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    (
      arts: ART[],
      organizations: Dictionary<Organization>,
      portfolios: Dictionary<PortfolioSafe>
    ) => {
      const art = arts.find((a) => a.kanbanId === kanbanId)
      if (art) {
        const portfolio = portfolios[art.portfolioId]
        if (portfolio) {
          const organization = organizations[portfolio.organizationId]

          return organization
        } else return undefined
      } else return undefined
    }
  )

export const selectPortfolioByKanbanId = (kanbanId: string) =>
  createSelector(
    fromARTSelectors.selectAll,
    fromPortfolioSelectors.selectEntities,
    (arts: ART[], portfolios: Dictionary<PortfolioSafe>) => {
      const art = arts.find((a) => a.kanbanId === kanbanId)
      if (art) {
        const portfolio = portfolios[art.portfolioId]
        return portfolio
      } else return undefined
    }
  )
export const selectOrganizationByPortfolioKanbanId = (kanbanId: string) =>
  createSelector(
    fromOrganizationSelectors.selectEntities,
    fromPortfolioSelectors.selectAll,
    (organizations: Dictionary<Organization>, portfolios: PortfolioSafe[]) => {
      const portfolio1 = portfolios.find(
        (portfolio) => portfolio.kanbanId === kanbanId
      )
      if (portfolio1) {
        const organization = organizations[portfolio1.organizationId]

        return organization
      } else return undefined
    }
  )
export const selectSolutionsByValueStreamId = (id: string) =>
  createSelector(
    fromValueStreamSelectors.selectEntities,
    (valueStreams: Dictionary<Valuestream>) => {
      const valueStream = valueStreams[id]
      if (valueStream?.solutions) return valueStream.solutions
    }
  )

export const selectPortfoliosByOrganizationIdWithBudget = (
  organizationId: string
) =>
  createSelector(
    fromPortfolioSelectors.selectAll,
    fromValueStreamSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    fromUserSelectors.selectEntities,
    (
      entities: PortfolioSafe[],
      valueStreams: DevelopmentValueStream[],
      strategicThemes: StrategicTheme[],
      users: Dictionary<User>
    ) => {
      const portfolios = entities.filter(
        (item) => item.organizationId === organizationId
      )
      const devValueStreams = valueStreams
        .filter((vs) => vs.type === 'development')
        .map((vStream) => {
          const allocatedBudgets = strategicThemes
            .filter((theme) => theme.status === 'ACTIVE')
            .reduce((sum, strategicTheme) => {
              if (strategicTheme.budgetDistribution) {
                const budgetData = strategicTheme.budgetDistribution.find(
                  (data) => data.valueStreamId === vStream.id
                )
                if (budgetData && budgetData.allocatedBudget) {
                  return sum + budgetData.allocatedBudget
                }
              }
              return sum
            }, 0)
          return {
            ...vStream,
            budget: allocatedBudgets
          }
        })

      return portfolios.map((portfolio) => {
        const budget = devValueStreams
          .filter((valueStream) => valueStream.portfolioId === portfolio.id)
          .map((valueStream) => valueStream.budget)
          .reduce((previousValue, currentValue) => {
            const previousValueNum = !isNaN(previousValue) ? previousValue : 0
            const currentValueNum = !isNaN(currentValue) ? currentValue : 0
            return previousValueNum + currentValueNum
          }, 0)

        return {
          ...portfolio,
          manager: users[portfolio.portfolioOwnerId]?.name,
          budget
        }
      })
    }
  )

export const selectPortfoliosWithOrganizationAndBudget = () =>
  createSelector(
    fromPortfolioSelectors.selectAll,
    fromOrganizationSelectors.selectAll,
    fromValueStreamSelectors.selectAll,
    fromStrategicThemesSelectors.selectAll,
    (
      portfolios: PortfolioSafe[],
      organizations: Organization[],
      valueStreams: DevelopmentValueStream[],
      strategicThemes: StrategicTheme[]
    ) => {
      const devValuestream: DevelopmentValueStream[] = valueStreams
        .filter((vs) => vs.type === 'development')
        .map((va) => {
          const allocatedBudgets = strategicThemes
            .filter((st) => st.status === 'ACTIVE')
            .reduce((sum, strategicTheme) => {
              if (strategicTheme.budgetDistribution) {
                const budgetData = strategicTheme.budgetDistribution.find(
                  (data) => data.valueStreamId === va.id
                )
                if (budgetData && budgetData.allocatedBudget) {
                  return sum + budgetData.allocatedBudget
                }
              }
              return sum
            }, 0)
          return {
            ...va,
            budget: allocatedBudgets
          }
        })

      return portfolios.map((portfolio) => ({
        ...portfolio,
        organization: organizations.find(
          (a) => a.id === portfolio.organizationId
        )?.name,
        budget: devValuestream
          .filter((valueStream) => valueStream.portfolioId === portfolio.id)
          .map((valueStream) => valueStream.budget)
          .reduce((e, n) => {
            const previousValue = !isNaN(e) ? e : 0
            const currentValue = !isNaN(n) ? n : 0
            return previousValue + currentValue
          }, 0)
      }))

      /* return portsout.map((portfolio) => ({
        ...portfolio,
        budget: devValuestream
          .filter((valueStream) => valueStream.portfolioId === portfolio.id)
          .map((valueStream) => valueStream.budget)
          .reduce((e, n) => {
            const previousValue = !isNaN(e) ? e : 0
            const currentValue = !isNaN(n) ? n : 0
            return previousValue + currentValue
          }, 0)
      })) */
    }
  )

export const getSolutionsByOrganizationId = (organizationId: string) =>
  createSelector(
    fromSolutionSelectors.selectAll,
    fromUserSelectors.selectAll,
    (entities: Solution[], users: User[]) =>
      entities
        .filter((item) => item.organizationId === organizationId)
        .map((item) => ({
          ...item,
          leader: users.find((user) => user.id === item.solutionManagerId)?.name
        }))
  )

export const getSolutionsByOrganizationIdFilterByType = (
  organizationId: string,
  type: ContextType
) =>
  createSelector(
    fromSolutionSelectors.selectAll,
    fromUserSelectors.selectAll,
    (entities: Solution[], users: User[]) =>
      entities
        .filter(
          (item) =>
            item.organizationId === organizationId && item.contextType === type
        )
        .map((item) => ({
          ...item,
          leader: users.find((user) => user.id === item.solutionManagerId)?.name
        }))
  )

export const getDevelopingValueStreamsByOrganizationIdAndPortfolioId = (
  organizationId: string,
  portfolioId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    (entities: Valuestream[], portfolio: PortfolioSafe[]) =>
      entities
        .filter(
          (item) =>
            item.organizationId === organizationId &&
            item.type === 'development' &&
            item.portfolioId === portfolioId
        )
        .map((valuestream) => ({
          ...valuestream,
          portfolio: portfolio.find((p) => p.id === valuestream.portfolioId)
        }))
  )

export const getValueStreamsByOrganizationIdWithPortfolio = (
  organizationId: string
) =>
  createSelector(
    fromValueStreamSelectors.selectAll,
    fromPortfolioSelectors.selectAll,
    (entities: Valuestream[], portfolio: PortfolioSafe[]) =>
      entities
        .filter((item) => item.organizationId === organizationId)
        .map((valuestream) => {
          const portfolioItem = portfolio.find(
            (p) => p.id === valuestream.portfolioId
          )
          // Only include the portfolio property if portfolioItem exists
          return portfolioItem
            ? { ...valuestream, portfolio: portfolioItem }
            : { ...valuestream }
        })
  )

export const getKPIInstanceListIdWithKPI = (valueStreamId) =>
  createSelector(
    fromKPISelectors.selectEntities,
    fromKPIInstanceSelectors.selectAll,
    (kpis: Dictionary<KPI>, kpisinstances) =>
      kpisinstances
        .filter((kpiInstance) => kpiInstance.valueStreamId === valueStreamId)
        .map((kpisinstance) => ({
          ...kpisinstance,
          kpi: kpis[kpisinstance.kpiId]
        }))
  )

export const getKPIInstanceByIdWithKPI = (id: string) =>
  createSelector(
    fromKPISelectors.selectAll,
    fromKPIInstanceSelectors.selectEntities,
    (kpis, kpisinstances) => {
      const kpiInstance = kpisinstances[id]
      const kpiInstance2: KPIInstanceWithKPI = {
        ...kpisinstances[id],
        kpi: kpis.find((kpi) => kpi.id === kpiInstance.kpiId)
      }
      return kpiInstance2
    }
  )

export const getMembersList = (organizationId) =>
  createSelector(
    fromUserSelectors.selectEntities,
    fromOrganizationMemberSelectors.selectAll,
    (users: Dictionary<User>, organizationMember) =>
      organizationMember
        .filter((member) => member.organizationId === organizationId)
        .map((member) => ({
          ...member,
          user: users[member.userId],
          owner: users[member.ownerId]
        }))
  )

export const getAllMembersList = () =>
  createSelector(
    fromUserSelectors.selectEntities,
    fromOrganizationMemberSelectors.selectAll,
    (users, organizationMember) =>
      organizationMember.map((member) => ({
        ...member,
        user: users[member.userId],
        owner: users[member.ownerId]
      }))
  )

export const selectFeaturesFromPI = (piId) =>
  createSelector(
    fromItemsSelectors.selectAll,
    (items: RequirementI[]) =>
      items.filter((item) => {
        if (isFeatureI(item) && item.programIncrementId === piId) {
          return true
        } else {
          return false
        }
      }) as Feature[]
  )

// Base selector to filter and sort program increments by ART ID
export const selectProgramIncrementsByArtId = (artId: string) =>
  createSelector(fromPISelectors.selectAll, (entities: ProgramIncrement[]) =>
    entities
      .filter((pi) => pi.ARTId === artId)
      .sort(
        (a, b) =>
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      )
  )
// Custom memoization function to compare the filtered result of items
const memoize = (fn: (...args: any[]) => any) =>
  defaultMemoize(
    fn,
    (prevResult, newResult) =>
      // Only recompute if the filtered result has changed
      JSON.stringify(prevResult) === JSON.stringify(newResult)
  )

// Features Selector with custom memoization
export const selectFeatures = createSelectorFactory(memoize)(
  fromItemsSelectors.selectAll,
  (items: RequirementI[]) =>
    items.filter((item) => item.name === 'feature') as Feature[]
)

export const selectFeaturesByPI = (piId: string) =>
  createSelector(selectFeatures, (features: Feature[]) =>
    features.filter((feature) => feature.programIncrementId === piId)
  )

export const selectFeaturesBySolutionId = (solutionId) =>
  createSelector(selectFeatures, (features: Feature[]) =>
    features.filter((feature) => feature.solutionId === solutionId)
  )
export const selectProgramIncrementsWithfeaturesByArtId = (artId) =>
  createSelector(
    selectProgramIncrementsByArtId(artId),
    selectFeatures,
    (entities: ProgramIncrement[], features: Feature[]) =>
      entities
        .filter((f) => f.ARTId === artId)
        .map((pi) => ({
          ...pi,
          features: features.filter(
            (feature) => feature.programIncrementId === pi.id
          ) as Feature[]
        }))
        .sort(
          (a, b) =>
            new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        )
  )

export const selectAllProgramIncrementsWithFeatures = () =>
  createSelector(
    fromPISelectors.selectAll,
    fromItemsSelectors.selectAll,
    (entities: ProgramIncrement[], features: RequirementI[]) =>
      entities

        .map((pi) => ({
          ...pi,
          features: features.filter(
            (feature) =>
              feature.name === 'feature' && feature.programIncrementId === pi.id
          ) as Feature[]
        }))
        .sort(
          (a, b) =>
            new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        )
  )

export const getFeatureWithProgramIncrementAndThemes = (featureId) =>
  createSelector(
    fromPISelectors.selectEntities,
    fromItemsSelectors.selectEntities,
    fromStrategicThemesSelectors.selectAll,
    (
      entities: Dictionary<ProgramIncrement>,
      features: Dictionary<RequirementI>,
      strategicThemes: StrategicTheme[]
    ) => {
      const item = features[featureId]
      if (item && isFeatureI(item)) {
        const pi = entities[item.programIncrementId]

        const stheme = strategicThemes.find(
          (t) =>
            t.keyResults &&
            t.keyResults.some((kr) => item.keyResultsId?.includes(kr.id))
        )

        return {
          ...item,
          pi,
          theme: stheme
        }
      } else {
        return undefined
      }
    }
  )

export const getChildrenKanbanItems = (parentId) =>
  createSelector(
    fromItemsSelectors.selectAll,
    fromKanbanSelectors.selectAll,
    (entities: RequirementI[], kanbans: Kanban[]) => {
      if (!entities || !kanbans) {
        return [] // Handle cases where entities or kanbans are null or undefined
      }
      const filteredItems = entities.filter(
        (item) => item.parentId === parentId
      )
      return filteredItems
        .map((item) => {
          const kanban = kanbans.find(
            (k) => k.id === item.kanbanId
          ) as KanbanI<any>

          if (kanban) {
            const column = kanban.columns?.find(
              (col) => col.id === item.columnId
            )

            if (column) {
              const state = column.name
              return { ...item, state }
            }
          }

          return item
        })
        .sort((itemA: RequirementI, itemB: RequirementI) => {
          // DELETE THE "-" AND TO NUMBER TO AVOID ERRORS LIKE : MVP-1, MVP-2, MVP-21, MVP-22, MVP-3, MVP-4..etc
          const numerationA = itemA.numeration?.split('-')[1]
          const numerationB = itemB.numeration?.split('-')[1]

          // Handle cases where numerationA or numerationB are not valid numbers
          const a = Number(numerationA) || 0
          const b = Number(numerationB) || 0

          if (a >= b) {
            return 1
          } else if (a <= b) {
            return -1
          } else {
            return 0
          }
        })
    }
  )

export const selectOrganizationByTeamId = (teamId) =>
  createSelector(
    fromARTSelectors.selectEntities,
    fromPortfolioSelectors.selectEntities,
    fromTeamSelectors.selectEntities,
    fromOrganizationSelectors.selectEntities,
    (arts, portfolios, teams, organzations) => {
      const team = teams[teamId]
      if (!team) return null

      const art = arts[team.ARTId]
      if (art) {
        const portfolio = portfolios[art.portfolioId]
        if (portfolio) {
          const organization = organzations[portfolio.organizationId]

          return organization
        }
      }
      return null
    }
  )

export const totaBudgetOfValuestreamsFromActiveStrategicsAndAvailableBudget = (
  vStreamIds: string[],
  allocatedB: number
) =>
  createSelector(
    fromStrategicThemesSelectors.selectAll,
    (strategicThemes: StrategicTheme[]) => {
      let availableB = 0
      let sumOfSameValueStreamsFromAllStrategicthemes = 0

      if (vStreamIds.length !== 0) {
        sumOfSameValueStreamsFromAllStrategicthemes = strategicThemes
          .filter((theme) => theme.status === 'ACTIVE')
          .reduce((sum, theme) => {
            if (theme.budgetDistribution) {
              theme.budgetDistribution.forEach((budget) => {
                if (
                  vStreamIds.includes(budget.valueStreamId) &&
                  budget.allocatedBudget
                ) {
                  sum += budget.allocatedBudget
                }
              })
            }
            return sum
          }, 0)
      }

      if (allocatedB) {
        availableB = sumOfSameValueStreamsFromAllStrategicthemes - allocatedB
      } else {
        availableB = sumOfSameValueStreamsFromAllStrategicthemes
      }

      return {
        sumOfStreams: sumOfSameValueStreamsFromAllStrategicthemes,
        availableBudget: availableB
      }
    }
  )

export const getARTByItemId = (itemId) =>
  createSelector(
    fromItemsSelectors.selectEntities,
    fromARTSelectors.selectAll,
    (items: Dictionary<RequirementI>, arts: ART[]) => {
      const item = items[itemId]
      if (item && isEpicI(item)) {
        const valueStreams = item.valueStreams
        const entitiesOut: ART[] = []
        if (
          valueStreams !== undefined &&
          valueStreams !== null &&
          valueStreams.length > 0
        )
          arts.forEach((art) => {
            if (
              art.valueStreams.some(
                (item1) =>
                  valueStreams.filter((id) => id.id === item1.id).length > 0
              )
            ) {
              entitiesOut.push(art)
            }
          })
        return entitiesOut
      } else return undefined
    }
  )

export const getStatesAndColumnIdsByItemId = (id: string) =>
  createSelector(
    fromItemsSelectors.selectEntities,
    fromKanbanSelectors.selectEntities,
    (
      items: Dictionary<RequirementI>,
      kanbans: Dictionary<KanbanI<UnifiedKanbanItem>>
    ) =>
      // entities[id].columns.map((item: { name: any }) => item.name)
      {
        const item = items[id]
        if (item) {
          const kanban = item.kanbanId ? kanbans[item.kanbanId] : null
          const arrayOut = []

          if (kanban) {
            kanban.columns.forEach((column) => {
              arrayOut.push({ name: column.name, id: column.id })
            })
          } else if (item.columnId) {
            const kanbanWithColumn = Object.values(kanbans).find((kanbanin) =>
              kanbanin.columns.some((column) => column.id === item.columnId)
            )

            if (kanbanWithColumn) {
              kanbanWithColumn.columns.forEach((column) => {
                arrayOut.push({ name: column.name, id: column.id })
              })
              // Find the specific column in the `kanbanWithColumn` that matches `columnId`
            }
          }

          return arrayOut
        } else {
          return [] // Return an empty array when no item matches the provided id
        }
      }
  )

// Factory function to get comments with user names
export const getKanbanItemCommentsWithUsernames = (id: string) =>
  createSelector(
    fromItemsSelectors.getKanbanItemCommentsById(id), // Correctly calling the selector factory
    fromUserSelectors.selectEntities, // Fetch all users
    (comments: Comment[], users: Dictionary<User>) =>
      comments.map((comment) => ({
        ...comment,
        userName: users[comment.userId]?.name || 'Unknown User'
      }))
  )
