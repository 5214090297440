import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core'
import { LoadingBarModule, LOADING_BAR_CONFIG } from '@ngx-loading-bar/core'
import { LoadingBarRouterModule } from '@ngx-loading-bar/router'
import { CoreModule } from './core/core.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app/app.component'
import {
  FontAwesomeModule,
  FaIconLibrary
} from '@fortawesome/angular-fontawesome'
import {
  faSquare,
  faCheckSquare,
  faInfoCircle,
  faArrowUp
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare as farSquare,
  faCheckSquare as farCheckSquare
} from '@fortawesome/free-regular-svg-icons'
import {
  faStackOverflow,
  faGithub,
  faMedium
} from '@fortawesome/free-brands-svg-icons'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'

import { ChildMenuComponent } from './app/menu/child-menu/child-menu.component'
import { MenuItemComponent } from './app/menu/child-menu/menu-item/menu-item.component'
import { TopMenuComponent } from './app/menu/top-menu/top-menu.component'

import '@angular/common/locales/global/es'
import '@angular/common/locales/global/fr'
import '@angular/common/locales/global/de'
import '@angular/common/locales/global/en'
import '@angular/common/locales/global/pt'
import '@angular/common/locales/zh-Hans'
import '@angular/common/locales/sk'

import { LocaleProvider } from './core/locale/services/locale.provider'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular-13'
import {
  AuthClientConfig,
  AuthHttpInterceptor,
  AuthModule,
  AuthService
} from '@auth0/auth0-angular'
import { environment } from '../environments/environment'
import { from, Observable } from 'rxjs'
import { MatTooltipModule } from '@angular/material/tooltip'
// import { BottomMenuComponent } from './app/menu/bottom-menu/bottom-menu.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { AccountMenuComponent } from './app/menu/account-menu/account-menu.component'
import { OrganizationContextMenuComponent } from './app/menu/organization-context-menu/organization-context-menu.component'
import 'chartjs-adapter-luxon'
import { NeoButtonComponent } from './app/neo-button/neo-button.component'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { CallbackModule } from './features/callback/callback.module'
import { AuthServiceGeneric } from './core/auth/auth.service'
import { HttpClient } from '@angular/common/http'
import { authFactory } from './core/auth/authFactory'
// import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'import { AuthServiceGeneric } from './core/auth/auth.service'
// import { authFactory } from './core/auth/authFactory'
/*
const sessionStorageCache = {
  get: function (key) {
    return JSON.parse(sessionStorage.getItem(key))
  },

  set: function (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },

  remove: function (key) {
    sessionStorage.removeItem(key)
  },

  // Optional
  allKeys: function () {
    return Object.keys(sessionStorage)
  }
}
const cookieCache = {
  // Helper function to get a cookie by name
  getCookie: function (name) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        return JSON.parse(c.substring(nameEQ.length, c.length)) // Parse JSON value
      }
    }
    return null
  },

  // Helper function to set a cookie
  setCookie: function (name, value, days) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + JSON.stringify(value) + expires + '; path=/' // Store as JSON string
  },

  // Helper function to remove a cookie
  removeCookie: function (name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/' // Set expiration to past
  },

  // Get a cookie
  get: function (key) {
    return this.getCookie(key)
  },

  // Set a cookie
  set: function (key, value, days = 7) {
    this.setCookie(key, value, days)
  },

  // Remove a cookie
  remove: function (key) {
    this.removeCookie(key)
  },

  // Optional: Get all cookie names (keys)
  allKeys: function () {
    const cookies = document.cookie.split(';')
    return cookies.map((cookie) => cookie.split('=')[0].trim())
  }
}*/
// Simplified and refactored initialization function
function initializeApp(config: AuthClientConfig): () => Promise<void> {
  return () =>
    new Promise<void>((resolve, reject) => {
      try {
        // Set the configuration in one go, no need to call config.set twice
        config.set({
          clientId: environment.auth0.clientId,
          domain: environment.auth0.domain,
          authorizationParams: {
            audience: environment.auth0.audience,
            scope: 'openid profile email',
            redirect_uri: environment.auth0.redirectUri
          },
          useRefreshTokens: environment.auth0.useRefreshTokens,
          cacheLocation: 'localstorage'
        })

        resolve() // Resolve after setting the configuration
      } catch (error) {
        reject(error) // Reject the promise if there is an error
      }
    })
}

@NgModule({
  imports: [
    // angular

    BrowserAnimationsModule,
    BrowserModule,
    // core
    CoreModule,
    ScrollingModule,

    // OVERLAY FORMS
    // OverlayFormsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    // app

    AppRoutingModule,

    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatTooltipModule,
    CallbackModule,
    AuthModule.forRoot()
  ],
  declarations: [
    AppComponent,

    TopMenuComponent,
    ChildMenuComponent,
    MenuItemComponent,
    // BottomMenuComponent,
    OrganizationContextMenuComponent,
    AccountMenuComponent,
    NeoButtonComponent
  ],
  bootstrap: [AppComponent],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthClientConfig],
      multi: true
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    //  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: AuthServiceGeneric,
      useFactory: authFactory,
      deps: [HttpClient, AuthService, Injector]
    },

    { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } },
    LocaleProvider
  ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faSquare,
      faCheckSquare,
      farSquare,
      farCheckSquare,
      faStackOverflow,
      faGithub,
      faMedium,
      faInfoCircle,
      faArrowUp
    )
  }
}
