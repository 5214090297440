import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { Feature, Story } from '../requirements-store/store/requirements.model'

// Request interfaces
export interface ImproveRequest {
  readonly epicRequest: string
  readonly context?: string
  readonly n: number
}

export interface GenerateFeatureAcceptanceCriteriaRequest {
  readonly description: string
  readonly benefitHypothesis?: string
  readonly context?: string
  readonly n: number
}
export interface GenerateAcceptanceCriteriaRequest {
  readonly description?: string
  readonly itemName: string
  readonly n: number
}

export interface FindLeadingIndicatorsRequest {
  readonly epicDescription: string
  readonly nonFunctionalRequirements: string
  readonly n: number
}

export interface BreakDownRequest {
  readonly description: string
  readonly context?: string
  readonly role: string
  readonly n?: number
}

// Response interfaces
export interface ImproveResponse {
  readonly choices?: Choice[]
}
export interface BreakDownEpicOld {
  readonly features
}

export interface BreakDownEpic {
  readonly features: Feature[] // Added type for clarity
}

export interface BreakDownEpicStructured {
  readonly features: Feature[]
}

export interface BreakDownFeature {
  readonly stories: any[] // Define the type of stories based on your data structure
}

export interface Choice {
  readonly index: number
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly finish_reason: string
  readonly message: Message
}

export interface Message {
  role: string
  content: string
}

// Suggestion types
export type SuggestionType =
  | 'feature'
  | 'epic'
  | 'story'
  | 'leading'
  | 'acceptance'

export interface AISuggestion<T> {
  suggestion?: string
  items?: T[]
  type: SuggestionType
}

// Generic AISuggestion interface
export interface AISuggestion<T = string> {
  // Default to string
  suggestion?: string
  items?: T[] // Keep items as an array of type T
  type: SuggestionType
}
// State interface
export interface OpenAIState<T = any> {
  items: AISuggestion<T>[] // Generic state to hold any item type (Feature, Story, etc.)
}

@Injectable({
  providedIn: 'root'
})
export class OpenAIStore extends ComponentStore<OpenAIState> {
  readonly items$: Observable<AISuggestion<any>[]> = this.select(
    (state) => state.items,
    { debounce: true }
  )

  // Add multiple items (with generic type support)
  readonly addItems = this.updater(
    (state: OpenAIState, newItems: AISuggestion<any>[]) => ({
      items: [...newItems, ...state.items] // Corrected to accept an array of AISuggestion
    })
  )

  // Add a single item (with generic type support)
  readonly addItem = this.updater(
    (state: OpenAIState, newItem: AISuggestion<any>) => ({
      items: [newItem, ...state.items]
    })
  )

  constructor() {
    super({ items: [] }) // Initialize the state with an empty items array
  }

  filterItemsByType<T>(type: SuggestionType): Observable<T[]> {
    return this.items$.pipe(
      map(
        (items: AISuggestion<T>[]) =>
          // Use AISuggestion<T> for type safety
          // console.log('Items before filtering:', items) // Log items before filtering
          items
            .filter((item) => item.type === type) // Filter by the specified type
            .reduce((acc: T[], item) => {
              // Ensure acc is of type T[]
              // If item.items is defined, concatenate it
              if (item.items) {
                // console.log('Item items:', item.items) // Log item.items
                return acc.concat(item.items as T[]) // Explicit cast if necessary
              }
              // If items is undefined, use the suggestion instead
              if (item.suggestion) {
                console.log('Using suggestion:', item.suggestion) // Log suggestion
                return acc.concat([item.suggestion as unknown as T]) // Cast suggestion to T
              }
              /* console.log(
              'Item items and suggestion are both undefined for:',
              item
            )*/
              return acc // Return the accumulator unchanged
            }, [] as T[]) // Initialize the accumulator as an array of type T
      )
    )
  }
}
