import {
  createFeatureSelector,
  ActionReducerMap,
  combineReducers
} from '@ngrx/store'
import { ARTState } from './arts-store/art.reducer'
import { PortfolioState } from './portfolio-store/portfolio/portfolio-safe.reducer'
import { KanbanItemState } from './requirements-store/store/item/requirement.reducer'
import { TeamState } from './teams-store/store/team.reducer'
import * as fromOrganizations from './organization-store/store/organization.reducer'
import * as fromOrganizationsMembers from './organization-store/store/organization-member.reducer'
import * as fromItems from './requirements-store/store/item/requirement.reducer'
import * as fromArts from './arts-store/art.reducer'
import * as fromTeams from '../features/teams-store/store/team.reducer'
import * as fromSolutions from '../features/solutions-store/store/solutions.reducer'
import * as fromPortfolios from '../features/portfolio-store/portfolio/portfolio-safe.reducer'
import * as fromValueStreams from '../features/value-stream-store/store/valuestream.reducer'
import * as fromKanban from '../kanban/store/kanban.reducer'
import * as fromStrategicTheme from './strategic-themes-store/store/strategic-theme.reducer'
import * as fromUsers from './user-store/user.reducer'
import * as fromHistoryAudit from '../features/history-audit-store/history-audit.reducer'
import * as fromKPI from '../features/store/kpis/kpis.reducer'
import * as fromKPIInstance from '../features/store/kpiinstance/kpiinstance.reducer'
import { AppState } from '../core/core.state'
import { SolutionState } from './solutions-store/store/solution.selectors'
import { ValueStreamState } from './value-stream-store/store/valuestream.reducer'
import { KanbanState } from '../kanban/store/kanban.reducer'
import { StrategicThemeState } from './strategic-themes-store/store/strategic-theme.reducer'
import { UserState } from './user-store/user.reducer'
import { HistoryAuditState } from './history-audit-store/history-audit.reducer'
import { KPIState } from './store/kpis/kpis.reducer'
import { KPIInstanceState } from './store/kpiinstance/kpiinstance.reducer'
import { PIState } from './program-increment-store/store/reducers/index'
import * as fromProgramIncrement from './program-increment-store/store/pi/program-increment.reducer'
import * as fromObjective from './program-increment-store/store/objectives/objective.reducer'
import * as fromEvent from './program-increment-store/store/events/event.reducer'

export const FEATURE_NAME = 'features'
export const selectFeatures = createFeatureSelector<FeaturesState>(FEATURE_NAME)
export const reducers: ActionReducerMap<FeaturesState> = {
  items: fromItems.kanbanItemReducer,
  arts: fromArts.artReducer,
  teams: fromTeams.teamReducer,
  portfolios: fromPortfolios.portfolioSAFEReducer,
  organizations: fromOrganizations.organizationReducer,
  organizationmembers: fromOrganizationsMembers.reducer,
  solutions: fromSolutions.solutionReducer,
  valuestreams: fromValueStreams.vaReducer,
  kanbans: fromKanban.kanbanReducer,
  strategicthemes: fromStrategicTheme.strategicThemeReducer,
  users: fromUsers.reducer,
  history: fromHistoryAudit.reducer,
  kpis: fromKPI.reducer,
  kpisinstances: fromKPIInstance.reducer,
  planification: combineReducers({
    [fromProgramIncrement.programIncrementsFeatureKey]:
      fromProgramIncrement.reducer,
    [fromObjective.objectivesFeatureKey]: fromObjective.reducer,
    [fromEvent.eventsFeatureKey]: fromEvent.reducer
  })
}

export interface FeaturesState {
  items: KanbanItemState
  arts: ARTState
  teams: TeamState
  portfolios: PortfolioState
  organizations: fromOrganizations.State
  solutions: SolutionState
  valuestreams: ValueStreamState
  kanbans: KanbanState
  organizationmembers: fromOrganizationsMembers.State
  strategicthemes: StrategicThemeState
  users: UserState
  kpis: KPIState
  kpisinstances: KPIInstanceState
  history: HistoryAuditState
  planification: PIState
}

export interface State extends AppState {
  features: FeaturesState
}
