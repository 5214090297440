import { Injectable } from '@angular/core'
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router'
import { Observable, of } from 'rxjs'
import { take, timeout, catchError, map, tap } from 'rxjs/operators'
import { ProfilingService } from './profiling.service'

@Injectable({
  providedIn: 'root'
})
export class ProfileCompleteGuard implements CanActivate, CanLoad {
  constructor(
    private profileService: ProfilingService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.checkProfile()
  }

  canLoad(): Observable<boolean | UrlTree> {
    return this.checkProfile()
  }

  private checkProfile(): Observable<boolean | UrlTree> {
    return this.profileService.isProfileCompleted$.pipe(
      take(1), // Ensure completion after first emission
      map((isComplete) => isComplete || this.router.parseUrl('/user-form')),
      catchError(() => of(this.router.parseUrl('/user-form')))
    )
  }
}
