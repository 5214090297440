import {
  NgModule,
  Optional,
  SkipSelf,
  ErrorHandler,
  Injector,
  InjectionToken
} from '@angular/core'
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http'
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
  routerReducer
} from '@ngrx/router-store'
import { ActionReducerMap, Store, StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import {
  TranslateModule,
  TranslateLoader,
  TranslateService
} from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import {
  FaIconLibrary,
  FontAwesomeModule
} from '@fortawesome/angular-fontawesome'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule } from '@angular/material/select'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatCardModule } from '@angular/material/card'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { CommonModule } from '@angular/common'
import { environment } from '../../environments/environment'
import {
  AppState,
  reducers,
  metaReducers,
  selectRouterState
} from './core.state'
import { AuthEffects } from './auth/auth.effects'
import { selectIsAuthenticated, selectAuth } from './auth/auth.selectors'
import {
  authLogin,
  authLogout,
  authSuccessLogout,
  successLogin
} from './auth/auth.actions'

import { AuthGuardService } from './auth/auth-guard.service'
import { TitleService } from './title/title.service'
import {
  ROUTE_ANIMATIONS_ELEMENTS,
  routeAnimations
} from './animations/route.animations'
import { AnimationsService } from './animations/animations.service'
import { AppErrorHandler } from './error-handler/app-error-handler.service'
import { LocalStorageService } from './local-storage/local-storage.service'
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor'
import { GoogleAnalyticsEffects } from './google-analytics/google-analytics.effects'
import { NotificationService } from './notifications/notification.service'
import { SettingsEffects } from './settings/settings.effects'
import {
  selectSettingsLanguage,
  selectEffectiveTheme,
  selectSettingsStickyHeader
} from './settings/settings.selectors'

import { MatButtonModule } from '@angular/material/button'
import {
  faCog,
  faBars,
  faRocket,
  faPowerOff,
  faUserCircle,
  faPlayCircle,
  faSitemap,
  faChess,
  faHome,
  faPlus,
  faAngleDoubleLeft,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons'
import {
  faGithub,
  faMediumM,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { MdePopoverModule } from '@material-extended/mde'
import { MatExpansionModule } from '@angular/material/expansion'
import { AuthInterceptor } from './auth/auth-interceptor'
import { MatMenuModule } from '@angular/material/menu'
import { AuthServiceGeneric } from './auth/auth.service'
import { authFactory } from './auth/authFactory'
import { AuthClientConfig, AuthModule, AuthService } from '@auth0/auth0-angular'
import { OverlayFormsModule } from '../overlay-forms/overlay-forms.module'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { ColorPickerModule } from 'ngx-color-picker-angular-13'
import { QuillModule } from 'ngx-quill'
export {
  TitleService,
  selectAuth,
  authLogin,
  successLogin,
  authLogout,
  authSuccessLogout,
  routeAnimations,
  AppState,
  LocalStorageService,
  selectIsAuthenticated,
  ROUTE_ANIMATIONS_ELEMENTS,
  AnimationsService,
  AuthGuardService,
  selectRouterState,
  NotificationService,
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectSettingsStickyHeader
}
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  )
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatCardModule,
    MatSnackBarModule,
    MatButtonModule,
    MdePopoverModule,
    MatExpansionModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    ColorPickerModule,

    // Overlay Forms
    OverlayFormsModule.forRoot(),

    // Auth0 Module
    //AuthModule.forRoot(),

    // Quill Editor
    QuillModule.forRoot(),

    // NgRx State Management
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      AuthEffects,
      SettingsEffects,
      GoogleAnalyticsEffects
    ]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Angular NgRx Material Starter'
        }),

    // Third-party
    FontAwesomeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  exports: [
    FormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatCardModule,
    MatSnackBarModule,
    MatButtonModule,
    MatExpansionModule,
    FontAwesomeModule,
    MdePopoverModule,
    TranslateModule
  ]
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    faIconLibrary: FaIconLibrary
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule')
    }
    faIconLibrary.addIcons(
      faCog,
      faBars,
      faRocket,
      faPowerOff,
      faUserCircle,
      faPlayCircle,
      faGithub,
      faMediumM,
      faTwitter,
      faInstagram,
      faYoutube,
      faSitemap,
      faChess,
      faHome,
      faPlus,
      faAngleDoubleLeft,
      faAngleDoubleRight
    )
  }
}
