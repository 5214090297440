import { Injectable, Injector } from '@angular/core'
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router'
import { Observable, of } from 'rxjs'
import { map, take, catchError, switchMap, filter, tap } from 'rxjs/operators'
import { AuthServiceGeneric } from './auth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {
  authService: AuthServiceGeneric
  constructor(
    // private authService: AuthServiceGeneric,
    private router: Router,
    private injector: Injector
  ) {}

  private init(): void {
    if (!this.authService) {
      this.authService = this.injector.get(AuthServiceGeneric, null)
      if (!this.authService) {
        console.error('AuthServiceGeneric is not provided')
      }
    }
  }

  canActivate(): Observable<boolean | UrlTree> {
    this.init()
    return this.checkAuthentication()
  }

  canLoad(): Observable<boolean | UrlTree> {
    this.init()
    return this.checkAuthentication()
  }

  private checkAuthentication(): Observable<boolean | UrlTree> {
    return this.authService.isLoggedIn().pipe(
      take(1),
      map((loggedIn) => (loggedIn ? true : this.router.parseUrl('/login'))),
      catchError(() => of(this.router.parseUrl('/login')))
    )
  }
}
