import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Inject
} from '@angular/core'
import { Update } from '@ngrx/entity'
import {
  Epic,
  Feature,
  RequirementI,
  Story
} from '../../../../features/requirements-store/store/requirements.model'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { OverlayFormRef } from '../../../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../../../overlay-forms/overlay/overlay.service'

@Component({
  selector: 'anms-select-parent-form',
  templateUrl: './select-parent-form.component.html',
  styleUrls: ['./select-parent-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectParentFormComponent implements OnInit {
  //feature: Feature
  story: Story
  //features: Feature[]
  //epics: Epic[]
  //currentEpic: Epic
  //currentFeature: Feature
  form: FormGroup
  currentParentId: string
  description
  name

  currentItem
  item
  itemsList
  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: { feature; epics; story; features }
  ) {
    this.form = this.formBuilder.group({
      parentId: ['']
    })
  }

  ngOnInit(): void {
    if ('feature' in this.data) {
      this.item = this.data.feature
      this.currentParentId = this.item.parentId
      this.description = this.item.description

      this.itemsList = this.data.epics
      if (this.item.parentId) {
        this.currentItem = this.itemsList.find(
          (epic) => epic.id === this.item.parentId
        )
        this.name = this.item.itemName

        this.form.get('parentId').setValue(this.data.feature.parentId)
      } else {
        this.form.get('parentId').setValue('')
      }
    }
    if ('story' in this.data) {
      this.item = this.data.story
      this.currentParentId = this.item.parentId
      this.description = this.item.description

      this.itemsList = this.data.features
      if (this.item.parentId) {
        this.currentItem = this.itemsList.find(
          (feature) => feature.id === this.item.parentId
        )
        this.name = this.currentItem.description

        this.form.get('parentId').setValue(this.data.story.parentId)
      } else {
        this.form.get('parentId').setValue('')
      }
    }
  }

  updateParent(parentId) {
    const update: Update<RequirementI> = {
      id: this.item.id,

      changes: { parentId }
    }

    this.currentParentId = parentId
    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }
  closeDialog() {
    this.dialogRef.close()
  }
}
