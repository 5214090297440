/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { ActivationEnd, Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { select, Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, interval, merge, of } from 'rxjs'
import {
  tap,
  withLatestFrom,
  map,
  distinctUntilChanged,
  mapTo,
  filter,
  switchMap
} from 'rxjs/operators'

import { selectSettingsState } from '../core.state'
import { LocalStorageService } from '../local-storage/local-storage.service'
import { AnimationsService } from '../animations/animations.service'
import { TitleService } from '../title/title.service'

import {
  actionSettingsChangeAnimationsElements,
  actionSettingsChangeAnimationsPage,
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeAutoNightMode,
  actionSettingsChangeLanguage,
  actionSettingsChangeTheme,
  actionSettingsChangeStickyHeader,
  actionSettingsChangeHour,
  actionSettingsChangePaginationPortfolioSAFE
} from './settings.actions'
import {
  selectEffectiveTheme,
  selectSettingsLanguage,
  selectPageAnimations,
  selectElementsAnimations
} from './settings.selectors'
import { Language, State } from './settings.model'
import { LocaleService } from '../locale/services/locale.service'

export const SETTINGS_KEY = 'SETTINGS'

const INIT = of('anms-init-effect-trigger')

@Injectable()
export class SettingsEffects {
  changeHour = createEffect(() =>
    interval(60_000).pipe(
      mapTo(new Date().getHours()),
      distinctUntilChanged(),
      map((hour) => actionSettingsChangeHour({ hour }))
    )
  )

  persistSettings = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          actionSettingsChangeAnimationsElements,
          actionSettingsChangeAnimationsPage,
          actionSettingsChangeAnimationsPageDisabled,
          actionSettingsChangeAutoNightMode,
          actionSettingsChangeLanguage,
          actionSettingsChangeStickyHeader,
          actionSettingsChangeTheme,
          actionSettingsChangePaginationPortfolioSAFE
        ),
        withLatestFrom(this.store.pipe(select(selectSettingsState))),
        tap(([action, settings]) =>
          this.localStorageService.setItem(SETTINGS_KEY, settings)
        )
      ),
    { dispatch: false }
  )

  updateRouteAnimationType = createEffect(
    () =>
      merge(
        INIT,
        this.actions$.pipe(
          ofType(
            actionSettingsChangeAnimationsElements,
            actionSettingsChangeAnimationsPage
          )
        )
      ).pipe(
        withLatestFrom(
          combineLatest([
            this.store.pipe(select(selectPageAnimations)),
            this.store.pipe(select(selectElementsAnimations))
          ])
        ),
        tap(([action, [pageAnimations, elementsAnimations]]) =>
          this.animationsService.updateRouteAnimationType(
            pageAnimations,
            elementsAnimations
          )
        )
      ),
    { dispatch: false }
  )

  updateTheme = createEffect(
    () =>
      merge(INIT, this.actions$.pipe(ofType(actionSettingsChangeTheme))).pipe(
        withLatestFrom(this.store.pipe(select(selectEffectiveTheme))),
        tap(([action, effectiveTheme]) => {
          const classList =
            this.overlayContainer.getContainerElement().classList
          const toRemove = Array.from(classList).filter((item: string) =>
            item.includes('-theme')
          )
          if (toRemove.length) {
            classList.remove(...toRemove)
          }
          classList.add(effectiveTheme)
        })
      ),
    { dispatch: false }
  )

  setTranslateServiceLanguage = createEffect(
    () =>
      this.store.pipe(
        select(selectSettingsLanguage),
        distinctUntilChanged(),
        tap((language) => this.translateService.reloadLang(language))
      ),
    { dispatch: false }
  )

  setTitle = createEffect(
    () =>
      merge(
        this.actions$.pipe(ofType(actionSettingsChangeLanguage)),
        this.router.events.pipe(
          filter((event) => event instanceof ActivationEnd)
        )
      ).pipe(
        tap((event: any) => {
          if (!event.language) return
          this.localeService.setLocale(event.language)
          this.titleService.setTitle(
            this.router.routerState.snapshot.root,
            this.translateService
          )
        })
      ),
    { dispatch: false }
  )
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private router: Router,
    private overlayContainer: OverlayContainer,
    private localStorageService: LocalStorageService,
    private titleService: TitleService,
    private animationsService: AnimationsService,
    private translateService: TranslateService,

    private localeService: LocaleService
  ) {}
}
