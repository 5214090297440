import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
  Inject,
  Injectable,
  INJECTOR,
  Injector,
  OnDestroy
} from '@angular/core'

import {
  environment as env,
  environment
} from '../../../environments/environment'
import { AuthenticationRequest, AuthState } from './auth.models'

import { select, Store } from '@ngrx/store'
import {
  BehaviorSubject,
  combineLatest,
  defer,
  forkJoin,
  interval,
  Observable,
  of,
  OperatorFunction,
  ReplaySubject,
  Subscription,
  throwError,
  zip
} from 'rxjs'
import { AuthResponse } from '../../core/auth/auth.model'
import { AuthService, User } from '@auth0/auth0-angular'
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  exhaustMap,
  filter,
  flatMap,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators'

import { selectAuthState } from '../core.state'
import { jwtDecode } from 'jwt-decode'

export interface AuthServiceI {
  login(username: string, password: string): Observable<AuthResponse>
  refreshAccessToken(): Observable<string>
  logout(): Observable<User>
  /**
   * METODO QUE DEVUELVE SI EL USER ESTA AUTENTIFICADO O NO, NO REALIZA ACCIONES.
   */
  isLoggedIn(): Observable<boolean>
  isLoggedOut(): Observable<boolean>
  isDateBeforeToday(date)
  getExpirationDate(): Observable<Date>
  // callBackMethod() {}
  setResponse(response: AuthResponse)
  getIsAuthChecker(): boolean
  setisAuthChecker(value: boolean)
}
export class AuthServiceGeneric implements AuthServiceI {
  login(username: string, password: string): Observable<AuthResponse> {
    return
  }
  refreshAccessToken(): Observable<any> {
    return
  }

  logout(): Observable<User> {
    return
  }

  getUser(): Observable<{ id: string; name: string; email; sub }> {
    return
  }
  /**
   * METODO QUE DEVUELVE SI EL USER ESTA AUTENTIFICADO O NO, NO REALIZA ACCIONES.
   */
  isLoggedIn(): Observable<boolean> {
    return
  }
  isLoggedOut(): Observable<boolean> {
    return
  }
  isDateBeforeToday(date) {}
  getExpirationDate(): Observable<Date> {
    return
  }
  // callBackMethod() {}
  setResponse(response: AuthResponse) {}
  getIsAuthChecker(): boolean {
    return
  }
  setisAuthChecker(value: boolean) {}

  getAccessToken(): Observable<string> {
    return
  }
  getIdTokenClaims(): Observable<any> {
    return
  }
  getStoreInit(): Observable<any> {
    return
  }
}

// Keep your original interfaces as they are...

@Injectable()
export class AuthServiceLocaL implements AuthServiceGeneric, OnDestroy {
  httpOptions = {
    headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')
  }

  today: Date
  apiurl = env.loginURL
  refreshTokenURL = env.refreshTokenURL
  logoutURL = env.logoutURL

  private _store: Store
  private storeInitialized$ = new BehaviorSubject<boolean>(false)
  private subscriptions = new Subscription()

  constructor(
    private http: HttpClient,
    @Inject(INJECTOR) private injector: Injector
  ) {
    this.today = new Date()
    // Initialize store immediately but handle the case where it might not be ready
    this.initStore()
  }

  private initStore(): void {
    this.getStoreInit().subscribe((initialized) => {
      console.log('Store is ready:', initialized)
    })

    const MAX_RETRIES = 5
    let retryCount = 0

    const attempt = () => {
      try {
        console.log('Attempting to get Store')
        this._store = this.injector.get(Store)
        console.log('Store obtained')
        this.storeInitialized$.next(true)
        // this.storeInitialized$.complete()
      } catch (error) {
        console.error('Error getting Store:', error)
        if (retryCount++ < MAX_RETRIES) {
          setTimeout(attempt, 100 * retryCount)
        } else {
          console.error('Store initialization failed after retries')
          this.storeInitialized$.next(false)
          // this.storeInitialized$.complete()
        }
      }
    }

    attempt()
  }
  getStoreInit(): Observable<boolean> {
    return this.storeInitialized$.pipe(
      filter((initialized) => initialized === true),
      take(1),
      catchError((err) => {
        console.error('Error in getStoreInit:', err)
        return of(false) // Return false instead of throwing an error
      })
    )
  }

  getIdTokenClaims(): Observable<any> {
    return this.getAccessToken().pipe(
      map((token) => {
        if (token) {
          try {
            const decodedToken = jwtDecode(token)
            return decodedToken
          } catch (error) {
            console.error('Error decoding token', error)
            return null
          }
        }
        return null
      })
    )
  }

  getAccessToken(): Observable<string> {
    return this.storeInitialized$.pipe(
      filter((initialized) => initialized),
      take(1),
      switchMap(() => {
        return this._store.pipe(
          select(selectAuthState),
          map((auth) => auth?.accessToken || null),
          take(1)
        )
      }),
      catchError((err) => {
        console.error('Error getting access token:', err)
        return of(null)
      })
    )
  }

  setResponse(response: AuthResponse): void {
    // Implement if needed
  }

  setisAuthChecker(value: boolean) {
    // Implement if needed
  }

  getIsAuthChecker(): boolean {
    return false // Implement actual logic if needed
  }

  login(username: string, password: string): Observable<AuthResponse> {
    const authReq: AuthenticationRequest = { username, password }
    return this.http
      .post<AuthResponse>(this.apiurl, authReq, this.httpOptions)
      .pipe(
        take(1),
        catchError((error) => {
          console.error('Login error:', error)
          return throwError(() => new Error('Login failed'))
        })
      )
  }

  refreshAccessToken(): Observable<any> {
    return this.http.get<string>(this.refreshTokenURL, {}).pipe(
      take(1),
      catchError((error) => {
        console.error('Token refresh error:', error)
        return throwError(() => new Error('Token refresh failed'))
      })
    )
  }

  logout(): Observable<User> {
    return this.http.post<User>(this.logoutURL, {}).pipe(
      take(1),
      tap(() => {
        // Remove the items from localStorage
        localStorage.removeItem('showHelp')
        localStorage.removeItem('ANMS-AUTH')
        localStorage.removeItem('menuStoreData')
      }),
      catchError((error) => {
        console.error('Logout error:', error)
        return of(null)
      })
    )
  }

  // This should be part of your AuthServiceLocaL class
  public isLoggedIn(): Observable<boolean> {
    // If store isn't initialized yet, return false immediately
    return this.getStoreInit().pipe(
      switchMap(() =>
        this._store.pipe(
          select(selectAuthState),
          map((auth) => {
            const today: Date = new Date()
            const isExpired = auth?.expirationDate
              ? today.getTime() > new Date(auth.expirationDate).getTime()
              : true

            const isValid =
              auth &&
              auth.isAuthenticated === true &&
              auth.expirationDate &&
              !isExpired
            return isValid
          }),
          distinctUntilChanged(), // Only emit when the value changes
          catchError((error) => {
            console.error('Error in isLoggedIn:', error)
            return of(false)
          })
        )
      )
    )
  }

  getUser(): Observable<{ id: string; name: string; email; sub }> {
    return this.storeInitialized$.pipe(
      filter((initialized) => initialized),
      switchMap(() => {
        return this._store.pipe(
          select(selectAuthState),
          map((auth) => {
            if (!auth) return null
            return {
              id: auth.id,
              name: auth.username,
              email: auth.email,
              sub: auth.sub
            }
          })
        )
      }),
      catchError(() => of(null))
    )
  }

  isLoggedOut(): Observable<boolean> {
    return this.isLoggedIn().pipe(map((isLoggedIn) => !isLoggedIn))
  }

  isDateBeforeToday(date) {
    return new Date(date.toDateString()) < new Date(new Date().toDateString())
  }

  getExpirationDate(): Observable<Date> {
    return this.storeInitialized$.pipe(
      filter((initialized) => initialized),
      switchMap(() => {
        return this._store.pipe(
          select(selectAuthState),
          map((auth) =>
            auth?.expirationDate ? new Date(auth.expirationDate) : null
          )
        )
      }),
      catchError(() => of(null))
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}

// Make similar changes to the Auth0 service implementation
@Injectable()
export class AuthServiceAuth0 implements AuthServiceGeneric, OnDestroy {
  private _storeInitialized = new ReplaySubject<boolean>(1)
  private _destroyed$ = new ReplaySubject<void>(1)

  response: AuthResponse
  accessToken$: Observable<string>

  isAuthenticated$: Observable<boolean> = of(false)

  localeAuth$: Observable<boolean>
  private subscription: Subscription

  private _store: Store

  private _isAuthChecker = false

  constructor(
    private auth0: AuthService,
    @Inject(INJECTOR) private injector: Injector // eslint-disable-next-line ngrx/no-multiple-global-stores
  ) {
    this.initializeStore()
    /*
    this._storeInitialized
      .pipe(
        filter((initialized) => initialized),
        take(1)
      )
      .subscribe(() => {
        this.initializeObservables()
      })
        */

    // setTimeout(() => {
    //this._store = this.injector.get(Store)
    //this._storeInitialized.next(true)
    //this._storeInitialized.complete()

    // })
  }

  private initializeStore(): void {
    const MAX_RETRIES = 5
    let retryCount = 0

    const attempt = () => {
      try {
        console.log('Attempting to get Store')
        this._store = this.injector.get(Store)
        console.log('Store obtained')
        this._storeInitialized.next(true)
        // this.storeInitialized$.complete()
      } catch (error) {
        console.error('Error getting Store:', error)
        if (retryCount++ < MAX_RETRIES) {
          setTimeout(attempt, 100 * retryCount)
        } else {
          console.error('Store initialization failed after retries')
          this._storeInitialized.next(false)
          // this.storeInitialized$.complete()
        }
      }
    }

    attempt()
  }

  getStoreInit(): Observable<boolean> {
    return this._storeInitialized.pipe(
      take(1),
      catchError((err) => {
        // Handle/store the error as needed
        console.error('Error in getStoreInit:', err)
        return of(false) // Return false instead of throwing an error
      })
    )
  }
  getIdTokenClaims(): Observable<any> {
    return this.auth0.idTokenClaims$
  }
  public isLoggedIn(): Observable<boolean> {
    //private initializeObservables() {
    /* this.auth0.isAuthenticated$.subscribe((val) =>
      console.log('auth0 authenticated', val)
    )
    this.localeAuth$ = this.getStoreInit().pipe(
      switchMap(() =>
        this._store.pipe(
          select(selectAuthState),
          map((auth: AuthState) => {
            if (!auth) return false
            const today: Date = new Date()
            const expirationDate: Date = new Date(auth.expirationDate) // Convert string to Date object
            const isExpired: boolean =
              today.getTime() > expirationDate.getTime()

            return !!(auth?.isAuthenticated && !isExpired)
          }),
          startWith(false), // Ensure emission even if store is slow
          distinctUntilChanged(),
          catchError(() => of(false)) // Handle store errors
        )
      ),
      takeUntil(this._destroyed$)
    )

    this.localeAuth$.subscribe((val) =>
      console.log('locale authenticated', val)
    )*/
    return combineLatest([
      this.auth0.isAuthenticated$.pipe(startWith(false)),
      this.getStoreInit().pipe(
        switchMap(() =>
          this._store.pipe(
            select(selectAuthState),
            map((auth) => this.isValidAuthState(auth)),

            distinctUntilChanged(),
            catchError(() => of(false)) // Handle store errors
          )
        ),
        takeUntil(this._destroyed$)
      )
    ]).pipe(
      tap(([isAuth0Authenticated, isAuthenticated]) => {
        if (isAuth0Authenticated === true && isAuthenticated === true) {
          console.log({
            logueado: isAuth0Authenticated,
            msg: 'Estas logueado en Auth0 y  hay true en el local storage'
          })
        } else if (isAuth0Authenticated === true && isAuthenticated === false) {
          console.log({
            logueado: isAuth0Authenticated,
            msg: 'Estas logueado en Auth0 pero no hay o ha expirado el token en el local storage'
          })
        }
      }),
      map(([isAuth0Authenticated, isAuthenticated]) => {
        // Handle potential null/undefined from auth0.isAuthenticated$
        const isAuth0Valid = isAuth0Authenticated ?? false
        //  this.setisAuthChecker(resp)

        if (isAuth0Valid === true && isAuthenticated === true) {
          // SE CHEQUEA QUE ESTA LOGGEADO Y TODO OK

          return true
        } else if (isAuth0Valid === true && isAuthenticated === false) {
          return false
        } else {
          // console.log({
          //   logueado: isAuth0Authenticated,
          //   msg: 'NO ESTAS LOGUEADO'
          // })

          return false
          // this.setisAuthChecker(false)
        }
      }),
      distinctUntilChanged(), // Add this line to ensure only distinct values are emitted,
      shareReplay(1)
    )
    // When we refresh
    // this.subscription = this.isAuthenticated$.subscribe()
  }

  private isValidAuthState(auth: AuthState): boolean {
    if (!auth) return false
    const now = Date.now()
    const expiresAt = new Date(auth.expirationDate).getTime()
    return auth.isAuthenticated && now < expiresAt
  }
  getAccessToken(): Observable<string> {
    return this.getStoreInit().pipe(
      switchMap(() =>
        this._store.pipe(
          // eslint-disable-next-line ngrx/select-style
          select(selectAuthState),
          map((auth) => auth.accessToken)
        )
      )
    )
  }
  getExpirationDate(): Observable<Date> {
    return this.getStoreInit().pipe(
      switchMap(() =>
        this._store.pipe(
          // eslint-disable-next-line ngrx/select-style
          select(selectAuthState),
          map((auth) => auth.expirationDate)
        )
      )
    )
  }

  getUser(): Observable<{
    id: string
    name: string
    email: string
    sub: string
  }> {
    return this.getStoreInit().pipe(
      switchMap(() =>
        this._store.pipe(
          select(selectAuthState),
          map((auth: AuthState) => ({
            id: auth?.id || '',
            name: auth?.username || '',
            email: auth?.email || '',
            sub: auth?.sub || ''
          })),
          catchError(() => of(null)) // Fallback on error
        )
      ),
      takeUntil(this._destroyed$)
    )
  }

  isLoggedOut(): Observable<boolean> {
    return this.getStoreInit().pipe(
      switchMap(() =>
        this._store.pipe(
          // eslint-disable-next-line ngrx/select-style
          select(selectAuthState),
          map((auth) => {
            if (!auth || !auth.isAuthenticated || !auth.expirationDate) {
              return true
            } else return false
          })
        )
      )
    )
  }
  login(username: string, password: string): Observable<AuthResponse> {
    return of(this.response)
  }

  isDateBeforeToday(date: any): void {
    throw new Error('Method not implemented.')
  }
  getExpiration(): void {
    throw new Error('Method not implemented.')
  }

  ngOnInit(): void {}

  // METODO QUE DEVUELVE SI EL USER ESTA AUTENTIFICADO O NO, NO REALIZA ACCIONES
  /*
  public isLoggedIn(): Observable<boolean> {
    return combineLatest([
      this.getStoreInit(),
      this.isAuthenticated$.pipe(startWith(false))
    ]).pipe(
      map(([_, isAuthenticated]) => isAuthenticated),
      distinctUntilChanged()
    )
  }
*/
  public logout(): Observable<User> {
    // this.setisAuthChecker(false)
    // if (this.subscription) this.subscription.unsubscribe()
    // NOT NECESSAY BECAUSE AUTH= logouts for default to returnTO
    // this._store.dispatch(fromAuthActions.authLogout())
    // localStorage.removeItem('ANMS-AUTH')
    localStorage.removeItem('showHelp')
    localStorage.removeItem('ANMS-AUTH')
    localStorage.removeItem('menuStoreData')

    this.auth0.logout({
      logoutParams: {
        returnTo: window.location.origin + '/login'
      }
    })
    return of([])
    // this.auth0.logout({ returnTo: environment.logoutURL })
  }

  setisAuthChecker(value: boolean) {
    // this._store.dispatch(fromAuthActions.authCheck())
    this._isAuthChecker = value
  }
  getIsAuthChecker(): boolean {
    return this._isAuthChecker
  }

  setResponse(response: AuthResponse) {
    this.response = response
  }
  refreshAccessToken(): Observable<any> {
    return this.auth0.getAccessTokenSilently()
  }
  ngOnDestroy(): void {
    this._destroyed$.next()
    this._destroyed$.complete()
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
