import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core'

@Component({
  selector: 'anms-neo-button',
  templateUrl: './neo-button.component.html',
  styleUrls: ['./neo-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NeoButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
