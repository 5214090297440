import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { selectCurrentUserName } from '../auth/auth.selectors'
import { distinctUntilChanged, shareReplay, take, filter } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class ProfilingService implements OnDestroy {
  // private isProfileCompletedSubject = new BehaviorSubject<boolean>(false)
  private isProfileCompletedSubject = new ReplaySubject<boolean>(1) // ✅ Holds the latest value
  private subscription: Subscription

  // Simplified public observable
  isProfileCompleted$ = this.isProfileCompletedSubject
    .asObservable()
    .pipe(distinctUntilChanged(), shareReplay(1))

  constructor(private store: Store) {
    this.checkProfileStatus()
  }

  private checkProfileStatus(): void {
    this.subscription = this.store
      .pipe(
        select(selectCurrentUserName),
        // Wait for defined value (skip initial undefined if needed)
        filter((userName) => userName !== undefined),
        take(1)
      )
      .subscribe({
        next: (userName) => {
          const isComplete = !!userName
          this.isProfileCompletedSubject.next(isComplete)
        },
        error: () => this.isProfileCompletedSubject.next(false)
      })
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe()
  }

  // Keep if needed for manual updates after form submission
  markProfileAsCompleted(): void {
    this.isProfileCompletedSubject.next(true)
  }
}
