import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  switchMap,
  take,
  first
} from 'rxjs/operators'

import * as fromAuthActions from './auth.actions'
import { AuthServiceGeneric } from './auth.service'
import { Injectable, NgZone, Injector } from '@angular/core'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization'
  private refreshTokenInProgress = false
  private refreshTokenSubject = new BehaviorSubject<string | null>(null)

  constructor(private store: Store, private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isPublicRequest(req)) {
      return next.handle(req)
    }
    const authService = this.injector.get(AuthServiceGeneric)

    return authService.isLoggedIn().pipe(
      filter((loggedIn) => loggedIn),
      switchMap(() => authService.getAccessToken().pipe(take(1))),
      switchMap((token) => {
        const authReq = this.addTokenToRequest(req, token)
        return next.handle(authReq).pipe(
          catchError((error) => {
            if (this.isUnauthorizedError(error)) {
              return this.handle401Error(authReq, next)
            }
            return throwError(() => error)
          })
        )
      })
    )
  }

  private isPublicRequest(req: HttpRequest<any>): boolean {
    const publicUrls = [
      '/api/v1/login',
      '/api/v1/register',
      '/api/v1/logout',
      '/callback',
      '/login',
      '/assets/',
      '/i18n/',
      '/register'
    ]
    return publicUrls.some((url) => req.url.includes(url))
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true
      this.refreshTokenSubject.next(null)
      const authService = this.injector.get(AuthServiceGeneric)
      authService.refreshAccessToken().pipe(
        switchMap((newToken) => {
          this.refreshTokenSubject.next(newToken)
          return next.handle(this.addTokenToRequest(request, newToken))
        }),
        finalize(() => {
          this.refreshTokenInProgress = false
        }),
        catchError((error) => {
          this.store.dispatch(fromAuthActions.authLogout())
          return throwError(() => error)
        })
      )
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenToRequest(request, token!)))
    )
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string | null
  ): HttpRequest<any> {
    return token
      ? request.clone({
          headers: request.headers.set(this.AUTH_HEADER, `Bearer ${token}`)
        })
      : request
  }

  private isUnauthorizedError(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 401
  }
}
