import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { ART } from './art.model'
import { Team } from '../teams-store/store/team.model'
import { Valuestream } from '../value-stream-store/store/valuestream.model'
import { Solution } from '../solutions-store/store/solutions.model'
import { RequirementI } from '../requirements-store/store/requirements.model'
import { Id } from '../../shared/models/entity-base.model'

export const loadARTs = createAction('[ART/API] Load ARTs')

export const loadARTsList = createAction('[ART/API] Load ARTs List')

export const loadARTsByPortfolioId = createAction(
  '[ART/API] Load ARTs by PortfolioId',
  props<{ portfolioId: string }>()
)

export const loadART = createAction(
  '[ART/API] LOAD ART',
  props<{ id: string }>()
)
export const loadARTDashBoard = createAction(
  '[ART/API] LOAD ART DASHBOARD',
  props<{ id: string }>()
)
export const successLoadART = createAction(
  '[ART/API] Success LOAD ART',
  props<{ aRT: ART }>()
)

export const successLoadARTs = createAction(
  '[ART/API] Success Load ARTs',
  props<{ aRTs: ART[] }>()
)
export const successLoadARTsSet = createAction(
  '[ART/API] Success Load ARTs Set',
  props<{ aRTs: ART[] }>()
)

export const addART = createAction('[ART/API] Add ART', props<{ aRT: ART }>())

export const sucessAddART = createAction(
  '[ART/API] Success Add ART',
  props<{ aRT: ART }>()
)

export const upsertART = createAction(
  '[ART/API] Upsert ART',
  props<{ aRT: ART }>()
)

export const addValueStreamtoART = createAction(
  '[ART/API] Add ValueStream to ART',
  props<{ aRTId: string; va: Valuestream }>()
)
export const successAddValuestreamToART = createAction(
  '[ART/API] Success Add ValueStream to ART',
  props<{ aRT: ART }>()
)

export const removeValuestreamFromART = createAction(
  '[ART/API] Remove ValueStream from ART',
  props<{ aRTId: string; vaId: string }>()
)

export const successRemoveValueStreamFromART = createAction(
  '[ART/API] Success Remove ValueStream from ART',
  props<{ aRT: ART }>()
)

export const loadItemsAssignedToART = createAction(
  '[ART/API] Load Items assigned to ART',
  props<{ id: string }>()
)
export const successLoadItemsAssignedToART = createAction(
  '[ART/API] Success Load Items assigned to ART',
  props<{ items: RequirementI[] }>()
)
export const addTeamtoART = createAction(
  '[ART/API] Add Team to ART',
  props<{ aRTId: string; team: Team }>()
)
export const removeTeamFromART = createAction(
  '[ART/API] Remove Team from ART',
  props<{ aRTId: string; teamId: string }>()
)
export const successAddTeamtoART = createAction(
  '[ART/API] Success Add Team to ART',
  props<{ aRT: ART }>()
)
export const successRemoveTeamFromART = createAction(
  '[ART/API] Success Remove Team from ART',
  props<{ aRT: ART; teamId: string }>()
)

export const successUpsertART = createAction(
  '[ART/API] Success Upsert ART',
  props<{ aRT: ART }>()
)

export const addARTs = createAction(
  '[ART/API] Add ARTs',
  props<{ aRTs: ART[] }>()
)

export const upsertARTs = createAction(
  '[ART/API] Upsert ARTs',
  props<{ aRTs: ART[] }>()
)

export const updateART = createAction(
  '[ART/API] Update ART',
  props<{ aRT: Update<ART> }>()
)

export const patchART = createAction(
  '[ART/API] Patch ART',
  props<{ aRT: Update<ART> }>()
)

export const updateARTs = createAction(
  '[ART/API] Update ARTs',
  props<{ aRTs: Update<ART>[] }>()
)

export const deleteART = createAction(
  '[ART/API] Delete ART',
  props<{ id: string }>()
)
export const successDeleteART = createAction(
  '[ART/API] Success Delete ART',
  props<{ id: string }>()
)

export const deleteARTs = createAction(
  '[ART/API] Delete ARTs',
  props<{ ids: string[] }>()
)

export const addSolutiontoART = createAction(
  '[ART/API] Add Solution to ART',
  props<{ aRTId: string; solution: Solution }>()
)
export const successAddSolutionToART = createAction(
  '[ART/API] Success Add Solution to ART',
  props<{ aRT: ART; solutionId: string }>()
)

export const removeSolutionFromART = createAction(
  '[ART/API] Remove Solution from ART',
  props<{ aRTId: string; solutionId: string }>()
)

export const successRemoveSolutionFromART = createAction(
  '[ART/API] Success Remove Solution from ART',
  props<{ aRT: ART; solutionId: string }>()
)
export const errorCatch = createAction(
  '[ART/API] Error Art',
  props<{ error: string }>()
)

export const addSolutionsToART = createAction(
  '[ART/API] Add Solutions to Art',
  props<{ artId: string; solutions: Id[] }>()
)
export const successAddSolutionsToART = createAction(
  '[ART/API] Success Add Solutions to ART',
  props<{ art: ART }>()
)

export const clearARTs = createAction('[ART/API] Clear ARTs')
