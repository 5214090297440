import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { Store } from '@ngrx/store'
import { Routes, RouterModule, Router } from '@angular/router'

import { environment } from '../../../../environments/environment'
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap
} from 'rxjs/operators'

import { jwtDecode, JwtPayload } from 'jwt-decode'
import * as fromAuthActions from '../../../core/auth/auth.actions'
import { combineLatest, EMPTY, of, Subject } from 'rxjs'
import { AuthResponse } from '../../../core/auth/auth.model'
export interface ErrorMessage {
  message: string
  email?: string
}
export interface CustomJwtPayload extends JwtPayload {
  email?: string
  name?: string
  username?: string
  preferred_username?: string // Opcional si el backend lo usa
}

@Component({
  selector: 'anms-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallbackComponent implements OnInit, AfterViewInit {
  errorMessage$ = new Subject<ErrorMessage>()
  private isRedirectHandled = false // Flag to ensure callback is only triggered once

  constructor(
    private auth0: AuthService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.auth0.error$.subscribe((error) => {
      console.error('Global Auth0 error:', error)
      // this.router.navigate(['/auth-error'], { state: { error }});
    })
  }
  ngAfterViewInit(): void {
    console.log('CallbackComponent initialized')

    // Check if redirect has already been handled
    if (this.isRedirectHandled) {
      console.log('Redirect callback already handled')
      return // Exit early if it's already handled
    }
    // Set the flag to indicate that the callback is being processed
    this.isRedirectHandled = true
    /*this.auth0
      .handleRedirectCallback()
      .pipe(
        catchError((error) => {
          console.error('Auth0 redirect error:', error)
          this.errorMessage$.next({ message: 'Authentication failed.' })
          return of(null) // Continue the stream without re-throwing
        })
      )*/
    this.auth0.isAuthenticated$
      .pipe(
        switchMap((isAuthenticated) =>
          isAuthenticated
            ? combineLatest([
                this.auth0.user$.pipe(
                  filter((user) => !!user),
                  take(1)
                ),
                this.auth0.getAccessTokenSilently().pipe(take(1))
              ])
            : EMPTY
        )
      )
      .subscribe({
        next: ([user, token]) => {
          const decodedToken = jwtDecode<CustomJwtPayload>(token)
          const response: AuthResponse = {
            access_token: token,
            exp: decodedToken.exp!,
            name: user.name!,
            username: user.email!,
            id: user.sub?.slice(6) || '',
            isAuthenticated: true,
            iat: decodedToken.iat!
          }

          console.log('response', response)
          this.store.dispatch(fromAuthActions.successLogin({ response }))
        },
        error: (error) => {
          console.error('Invalid state error:', error)
          this.errorMessage$.next({
            message: 'Invalid authentication state. Please try again.'
          })
        }
      })
  }

  resendVerificationTicket(email: string, origin: HTMLElement): void {
    origin.setAttribute('disabled', 'true')
    setTimeout(() => origin.removeAttribute('disabled'), 5000)

    this.store.dispatch(fromAuthActions.resendVerificationTicket({ email }))
  }
}
