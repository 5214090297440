import { Injectable } from '@angular/core'
import {
  BreakpointObserver,
  Breakpoints,
  MediaMatcher
} from '@angular/cdk/layout'
import { BehaviorSubject, Observable, fromEvent, combineLatest } from 'rxjs'
import { map, startWith, distinctUntilChanged } from 'rxjs/operators'

export type ServiceToggleMode = 'backdrop' | 'click'
export type SidenavState = 'collapsed' | 'expanded'

export interface ServiceToogle {
  state1: SidenavState
  mode?: ServiceToggleMode
}

@Injectable({ providedIn: 'root' })
export class SidenavService {
  private touchScreenQuery: MediaQueryList
  private isTouchable$: Observable<boolean>
  private mobileOpenedSubject = new BehaviorSubject<boolean>(false)
  private serviceStateSubject = new BehaviorSubject<ServiceToogle>({
    state1: 'collapsed'
  })
  isMobile = false
  isTablet = false
  isDesktop = true

  constructor(
    private mediaMatcher: MediaMatcher,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall, // Mobile
        Breakpoints.Small, // Tablet
        '(min-width: 960px)'
      ])
      .subscribe((result) => {
        this.isMobile = result.breakpoints[Breakpoints.XSmall]
        this.isTablet = result.breakpoints[Breakpoints.Small]
        // this.isDesktop = result.breakpoints[Breakpoints.Web]
        this.isDesktop = !!result.breakpoints['(min-width: 960px)']
      })
    this.touchScreenQuery = this.mediaMatcher.matchMedia('(pointer: coarse)')
    this.isTouchable$ = fromEvent<MediaQueryListEvent>(
      this.touchScreenQuery,
      'change'
    ).pipe(
      map((event) => event.matches),
      startWith(this.touchScreenQuery.matches),
      distinctUntilChanged()
    )
  }

  getServiceState$(): Observable<ServiceToogle> {
    return combineLatest([
      this.serviceStateSubject,
      this.isTouchable$,
      this.mobileOpenedSubject
    ]).pipe(
      map(([state, isTouchable, mobileOpened]) => ({
        ...state,
        state1: isTouchable
          ? mobileOpened
            ? 'expanded'
            : 'collapsed'
          : state.state1
      }))
    )
  }

  toggle(): void {
    console.log('toogle')
    if (this.touchScreenQuery.matches) {
      this.mobileOpenedSubject.next(!this.mobileOpenedSubject.value)
    } else {
      const current = this.serviceStateSubject.value
      const newState = current.state1 === 'expanded' ? 'collapsed' : 'expanded'
      this.serviceStateSubject.next({ state1: newState })
    }
  }

  close(): void {
    console.log('close')
    if (this.touchScreenQuery.matches) {
      this.mobileOpenedSubject.next(false)
    } else {
      this.serviceStateSubject.next({ state1: 'collapsed' })
    }
  }

  closeFromBackDrop(): void {
    // console.log('close from backdrop')
    if (this.touchScreenQuery.matches) {
      this.mobileOpenedSubject.next(false)
    } else {
      this.serviceStateSubject.next({
        state1: 'collapsed',
        mode: 'backdrop'
      })
    }
  }
  closeIfTouchable(): void {
    if (this.touchScreenQuery.matches || this.isTablet) {
      // For touch devices, collapse by closing mobile view
      this.mobileOpenedSubject.next(false)
    }
    // For non-touch devices, do nothing - maintain regular state
  }

  open(): void {
    if (this.touchScreenQuery.matches) {
      this.mobileOpenedSubject.next(true)
    } else {
      this.serviceStateSubject.next({ state1: 'expanded' })
    }
  }

  setMobileOpened(isOpened: boolean): void {
    this.mobileOpenedSubject.next(isOpened)
  }
}
