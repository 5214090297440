<div class="main-wrapper p-1">
  <form [formGroup]="form" (ngSubmit)="onSubmit($event)" class="default-form" [ngClass]="{'update-form' : isUpdate}">
    <div class="form-fields">
      <div class="form-fields-inner d-flex flex-column flex-lg-row">
        <div class="form-main px-0 form-container-left section-left px-md-2">
          <div class="d-flex px-0">
            <ng-container *ngIf="isUpdate">
              <anms-breadcrum-requirement [requirement]="epic" (updateDescription)="updateNameEpic($event)"
                [isUpdate]="isUpdate"></anms-breadcrum-requirement>
            </ng-container>
          </div>



          <div *ngIf="isUpdate$ | async" class="d-flex justify-content-between flex-column flex-lg-row">

            <div class="upload-file-field mb-2 d-flex justify-content-start">

              <input class="upload-file-input" id="fileUpload" placeholder="upload a file" type="file" formControlName="fileSource"
                (change)="onHandleFile($event)">
              <span class="material-icons selected-file">
                attach_file
              </span>
              <!-- <span *ngIf="file" class="material-icons selected-file">check</span> -->
              <!-- <span (click)="onSubmitFile()" *ngIf="file" class="material-icons upload-action">file_upload </span> -->
            </div>

            <ng-container *ngIf="states$ | async as states">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label>State</mat-label>
                <mat-select formControlName="columnId" (selectionChange)="changeState($event)">
                  <mat-option *ngFor="let state of states" [value]="state.id">
                    {{state.name}}
                  </mat-option>
                </mat-select>
                <mat-hint>State</mat-hint>
              </mat-form-field>
              <mat-error *ngIf="form.get('columnId').invalid">
                {{ 'anms.epic.form.placeholder.state' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>

            </ng-container>
          </div>
          <div class="contextual-menu">
            <button *ngIf="isUpdate" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openCopyEpicForm(buttonorigin)" #buttonorigin>
                <mat-icon>change_circle</mat-icon>
                <span>Copy Epic</span>
              </button>
              </mat-menu>
          </div>
          <!--row -->
          <div class="row" *ngIf="!isUpdate">
            <mat-form-field appearance="fill" class="col-12 col-md-8  px-0">
              <mat-label>Epic Summary</mat-label>
              <input matInput placeholder="{{ 'anms.epic.form.placeholder.itemName' | translate }}" #name formControlName="itemName"
                class="input-title">
              <mat-hint>{{ 'anms.epic.form.hint.name' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('itemName').invalid">
                {{ 'anms.epic.form.placeholder.name' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-12 col-md-4  px-0">
              <mat-label>Code *</mat-label>
              <input matInput placeholder="{{ 'anms.epic.form.placeholder.code' | translate }}" formControlName="code">
              <mat-hint>
                {{ 'anms.epic.form.code.hint' | translate }}
              </mat-hint>
              <mat-error *ngIf="form.get('code').invalid">
                {{ 'anms.epic.form.placeholder.code' | translate }}
                {{ 'anms.epic.form.required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <mat-tab-group [animationDuration]="'0ms'">
            <mat-tab label="Init">
              <div>
              
                <mat-form-field appearance="fill" class="default-full-width">
                  <mat-label><span class="ml-4">{{ 'anms.epic.form.placeholder.problemStatement' | translate }}</span>
                  </mat-label>



                  <textarea [matInput] style="height: 150px;" [mentionConfig]="mentionsConfig"
                    placeholder="{{ 'anms.epic.form.placeholder.problemStatement' | translate }}"
                    formControlName="problemStatement" cdkTextareaAutosize 
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"></textarea>

                  <mat-hint>{{ 'anms.epic.form.problemStatement.hint' | translate }}</mat-hint>
                  <mat-error *ngIf="form.get('problemStatement').invalid">
                    Problem Statement is required.
                  </mat-error>
                </mat-form-field>
                </div>

              <anms-dialog-parent>
                <!-- Edit Mode Template -->
                <ng-template #editMode>
                  <anms-description-edit [form]="form" [options]="options" [mentionsConfig]="mentionsConfig"
                    (descriptionSaved)="onDescriptionSaved($event)">
                  </anms-description-edit>

                </ng-template>

                <!-- View Mode Template -->
                <ng-template #viewMode>
                  <div class="py-4 px-2" style="background-color: #f0f1f1;cursor: pointer;">
                    <div [innerHTML]="sanitizedDescription"></div>
                
                  </div>
                  <div class="py-4" style="color: rgba(0, 0, 0, 0.6)">{{ 'anms.epic.form.placeholder.description' |
                    translate }}</div>
                
                </ng-template>
              </anms-dialog-parent>



              <div class="attachment-wrapper" *ngIf="filesOfItem!==undefined && filesOfItem.length>0">
                <ng-container *ngFor="let file of filesOfItem">
                  <anms-attachment-card class="mt-1 pr-1 mb-2 " [file]="file" (downloadFileEmmiter)="downLoadFile($event)"
                    (deleteFileEmmiter)="deleteFile($event)">
                  </anms-attachment-card>
                </ng-container>
              </div>

            </mat-tab>
            <mat-tab label="Non-Functional Requirements">
              <div class="epic-accordion-wrapper">
                <textarea [matTextareaAutosize]="true" class="textarea-form mt-1" matInput formControlName="nonFunctionalRequirements"
                  [mentionConfig]="mentionsConfig" cdkTextareaAutosize 
                   cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
                <mat-error *ngIf="form.get('nonFunctionalRequirements').invalid">
                  {{ 'anms.epic.form.nonFunctionalRequirements' | translate }}
                  {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
                
                </div>
                </mat-tab>
                <mat-tab label="Leading Indicators">
                  <div class="epic-accordion-wrapper">
                
                    <textarea [matTextareaAutosize]="true" class="textarea-form mt-1" matInput formControlName="leadingIndicators"
                  [mentionConfig]="mentionsConfig" cdkTextareaAutosize  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
                <mat-error *ngIf="form.get('leadingIndicators').invalid">
                  {{ 'anms.epic.form.leadingIndicators' | translate }}
                  {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
                
                </div>
                <div>
                  <a class="add-features-ai"
                    (click)="findLeadingIndicators(origin1, form.get('description').value,form.get('nonFunctionalRequirements').value)"
                    #origin1>
                    Find Leading Indicators with AI
                  </a>
                </div>
                </mat-tab>
                <mat-tab label="Business Outcome Hypothesis">
                  <div class="epic-accordion-wrapper">
                    <textarea [matTextareaAutosize]="true" class="textarea-form mt-1" matInput formControlName="businessOutComeHypothesis"
                      [mentionConfig]="mentionsConfig" cdkTextareaAutosize cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="10"></textarea>
                    <mat-error *ngIf="form.get('businessOutComeHypothesis').invalid">
                      {{ 'anms.epic.form.placeholder.outcomes' | translate }}
                      {{ 'anms.examples.form.error1' | translate }}
                    </mat-error>
                
                </div>
                </mat-tab>

          </mat-tab-group>
          <div class="px-0 mb-4">
            <ng-container *ngIf="scope$ | async as valueStreamArray">
              <span class="material-icons" #tooltip="matTooltip"
                matTooltip="Scope is referred to the Value Streams assigned to do this EPIC." matTooltipPosition="above"
                aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">info</span>
              <mat-label class="checkbox-label">Scope of Epic</mat-label>
              <div formArrayName="valueStreams" class="vs-checkbox-list">
                <div *ngFor="let va of valueStreams.controls; index as i">
                  <div [formGroupName]="i">
                    <mat-checkbox class="example-margin" formControlName="id"
                      (change)=setValue(i,valueStreamArray[i].id,$event.checked)
                      [checked]="isChecked(i,valueStreamArray)" [attr.disabled]="disabled(i)">
                      {{valueStreamArray[i].name}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="isUpdate">
            <div class="children-wrapper mt-2">
              <div class="d-flex px-0 children-main">
                <h3>Epic Features</h3>
                <a (click)="addItem(epic)" class="btn-new"><span class="material-icons">add</span></a>
              </div>
              <ng-container *ngIf="children$ | async as children">
                <div class="children-list">
                  <ng-container *ngFor="let child of children; let i = index">
                    <anms-feature-row [input]=child (arts)="setCurrentValuesStreams($event)" (artSelectedOut)="onSelectART($event)"
                      [scopeState$]="scopeOut$">
                    </anms-feature-row>
                  </ng-container>
                </div>
              </ng-container>
              <div *ngIf="showAddFeatureMessage" class="mb-4">
                <a (click)="addItem(epic)" class="px-2" style="cursor: pointer;color:#283593 !important;">
                  + Add new Feature
                </a>
              </div>
              <div class="children-new">
                <ng-template #dynamic></ng-template>
              </div>

            </div>
          </ng-container>

          <a class="add-features-ai" [ngClass]="{'disabled': form.get('description').invalid}"
            (click)="openBreakDownEpicForm(originfeature, form.get('description').value+ form.get('nonFunctionalRequirements').value)"
            #originfeature>
            Add Features with AI <mat-icon svgIcon="ai"></mat-icon>
          </a>



          <mat-form-field appearance="fill" class="default-full-width acceptance-criteria-field">
            <mat-label>{{ 'anms.epic.form.notes' | translate }}</mat-label>
            <textarea class="textarea-form" matInput formControlName="notes" [mentionConfig]="mentionsConfig" cdkTextareaAutosize
              cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"></textarea>
            <mat-hint> Notes </mat-hint>
            <mat-error *ngIf="form.get('notes').invalid">
              {{ 'anms.epic.form.notes' | translate }}
              {{ 'anms.examples.form.error1' | translate }}
            </mat-error>
          </mat-form-field>
          <anms-comments *ngIf="isUpdate && (teamMates$ | async) as teamMates" [itemId]="epic.id" [items]="teamMates">
          </anms-comments>

        </div>

        <div class="form-main section-right px-md-2">
          <div class="d-flex flex-column flex-md-row flex-nowrap">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Type *</mat-label>
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of epicTypes" [value]="type.value">
                  {{type.viewValue}}
                </mat-option>
              </mat-select>
              <mat-hint>Type is required</mat-hint>
              <mat-error *ngIf="form.get('type').invalid">
                {{ 'anms.epic.form.placeholder.type' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>


          </div>

          <ng-container *ngIf="objectives$ | async as objectives">
            <div>
              <anms-dialog-parent>
                <!-- Edit Mode Template -->
                <ng-template #editMode>
                  <anms-edit-mode-content [objectives]="objectives" [keyResultIdsArray]="keyResultIdsArray"
                    (checkboxChange)="onCheckboxChange($event)"></anms-edit-mode-content>

                </ng-template>

                <!-- View Mode Template -->
                <ng-template #viewMode>
                  <anms-view-mode-tags [keyResultIdsArray]="keyResultIdsArray" (keyResultRemoved)="onKeyResultRemoved($event)"
                    [objectives]="objectives">
                  </anms-view-mode-tags>

                </ng-template>
              </anms-dialog-parent>
            </div>
          </ng-container>



          <div>
            <ng-container *ngIf="portfolios$ | async as portfolios">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label>Portfolio</mat-label>
                <mat-select formControlName="portfolioId">
                  <mat-option *ngFor="let portfolio of portfolios" [value]="portfolio.id">
                    {{portfolio.name}}
                  </mat-option>
                </mat-select>
                <mat-hint>Portfolio which this Epic belongs to </mat-hint>
              </mat-form-field>
            </ng-container>
          </div>

          <div>
            <ng-container *ngIf="epicOwners$ | async as epicOwners">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label>Epic Owner</mat-label>
                <mat-select formControlName="epicOwnerId" (selectionChange)="updateEpicOwner($event.value)">
                  <mat-option *ngFor="let owner of epicOwners" [value]="owner.userId">
                    {{owner.user.name}}
                  </mat-option>
                </mat-select>
                <!-- <input matInput placeholder="{{ 'anms.epic.form.placeholder.owner' | translate }}"formControlName="epicOwner"> -->
                <mat-hint> Epic Owner</mat-hint>
                <mat-error *ngIf="form.get('epicOwnerId').invalid">
                  {{ 'anms.epic.form.placeholder.owner' | translate }}
                  {{ 'anms.examples.form.error1' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div> <!-- end flex row -->



          <div class="d-flex flex-column flex-md-row flex-nowrap px-0 mb-4">
            <mat-form-field appearance="fill" class="px-0 mb-2">
              <mat-label>Start Date</mat-label>
              <input matInput placeholder="{{ 'anms.epic.form.placeholder.startDate' | translate }}"
                formControlName="startDate" [matDatepicker]="startPicker">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
              <mat-hint>Planned Starting Date</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill" class="px-0 mb-2">
              <mat-label>Due Date</mat-label>
              <input matInput placeholder="{{ 'anms.epic.form.placeholder.dueDate' | translate }}"
                formControlName="dueDate" [matDatepicker]="picker" [min]="minDueDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-hint>Expected Release Date of EPIC</mat-hint>
              <ng-container *ngIf="f.dueDate.errors">
                <mat-error *ngIf="f.dueDate.errors.mustMatch">
                  Due Date cannot be set before start date.
                </mat-error>
              </ng-container>
            </mat-form-field>
          </div>


          <!-- BUTTON TO BUDGET -->
          <ng-container *ngIf="epicState$| async as state">
            <div class="d-flex flex-column flex-lg-row flex-nowrap px-0 mb-4 mt-2 mb-3"
              *ngIf="state === 'Implementing' || state === 'Done' || state === 'Portfolio Backlog'">
              <button mat-raised-button color="primary" (click)="showBudgetForm(origin,epic.id)" #origin type="button"
                class="btn-save"><span class="material-icons">account_balance</span>Go to Budget Information</button>
            </div>
          </ng-container>

          <div class="d-flex flex-column flex-md-row flex-nowrap px-0 mb-4 mt-2 mb-3">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Forecasted Epic Cost</mat-label>
              <input anmsCurrencyFormatter matInput
                placeholder="{{ 'anms.epic.form.placeholder.forecastedEpicCost' | translate }}"
                formControlName="forecastedEpicCost" type="text" min="0" value="0">
              <mat-hint>Expected cost of EPIC</mat-hint>
              <mat-error *ngIf="form.get('forecastedEpicCost').invalid">
                {{ 'anms.epic.form.placeholder.forecastedEpicCost' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
              <mat-error *ngIf="form.get('forecastedEpicCost').errors?.errorNaN">
                {{ 'It must be a number'}}

              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Forecasted Epic Duration</mat-label>
              <input matInput placeholder="{{ 'anms.epic.form.placeholder.forecastedEpicDurationInTime' | translate }}"
                formControlName="forecastedEpicDurationInTime" type="number" min="0" value="0">
              <mat-hint>Number of Program Increments</mat-hint>
              <mat-error *ngIf="form.get('forecastedEpicDurationInTime').invalid">
                {{ 'anms.epic.form.placeholder.forecastedEpicDurationInTime' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div>
            <ng-container *ngIf="editors$ | async as owners">
              <mat-form-field appearance="fill" class="px-0">
                <mat-label>Editor</mat-label>
                <mat-select formControlName="ownerId">
                  <mat-option *ngFor="let owner of owners" [value]="owner.id">
                    {{owner.name}}
                  </mat-option>
                </mat-select>
                <mat-hint>User that has ownership of the record</mat-hint>
                <mat-error *ngIf="form.get('ownerId').invalid">
                  <!--{{ 'anms.organization.form.placeholder.user' | translate }}{{ 'anms.examples.form.error1' | translate }} -->
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>

          <div>
            <mat-form-field appearance="fill" id="container" class="px-0 " (click)="openDialog()"
              [style]="styleColorContainer">
              <mat-label>Color</mat-label>
              <input autocomplete="off" [style]="styleColor" matInput
                placeholder="{{ 'anms.epic.form.placeholder.color' | translate }}" formControlName="color" #color>
              <mat-hint *ngIf="!isUpdate"> {{ 'anms.epic.form.hint.color' | translate }} </mat-hint>
              <mat-error *ngIf="form.get('color').invalid">
                {{ 'anms.epic.form.placeholder.owner' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>


          <div>
            <div *ngIf="isUpdate$ | async" class="item-meta item-meta-bottom">
              <p>Created on <span>{{created | date}}</span></p>
              <p>Last updated on <span>{{ updated | date}}</span></p>
            </div>
          </div>

          <!--ng-container *ngIf="isUpdate"-->

          <!--/ng-container-->
          <div class="item-meta item-meta-bottom" *ngIf="auditHistoryChanges$ | async as auditHistoryChanges"
            style="overflow: auto !important;">
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Show History
                </mat-panel-title>
                <!-- <mat-panel-description></mat-panel-description> -->
              </mat-expansion-panel-header>
              <anms-audit [historyAudit]="history" [currentUser]="epic.ownerId" *ngFor="let history of auditHistoryChanges">
              </anms-audit>
            </mat-expansion-panel>

          </div>
</div>
      </div>

    </div>


    <div class="form-actions">
      <div class="action-col secondary">
        <button *ngIf="isUpdate" mat-flat-button (click)="delete()" type="button" class="btn-delete px-0">
          <fa-icon [icon]="['fas', 'trash']"></fa-icon> Delete
        </button>
        <button *ngIf="!isUpdate" mat-flat-button (click)="closeDialog()" type="button" class="btn-delete px-0">
          <fa-icon [icon]="['fas', 'times']"></fa-icon> Cancel
        </button>
      </div>
      <div class="action-col primary">
        <button *ngIf="!isUpdate" mat-raised-button color="primary" type="submit" class="btn-save" id="saveNew"
          [disabled]='!form.valid'>
          <span class="material-icons">check</span>Save New</button>
        <button *ngIf="isUpdate" mat-raised-button color="primary" type="submit" id="save" class="btn-save"><span
            class="material-icons">check</span>Save</button>
      </div>
    </div>

  </form>
</div>