import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ComponentFactoryResolver,
  ViewContainerRef,
  OnDestroy,
  ViewChildren,
  QueryList,
  Inject,
  ChangeDetectorRef
} from '@angular/core'

import {
  AbstractControl,
  AbstractControlOptions,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms'

import { Store, select } from '@ngrx/store'

import {
  Epic,
  EpicI,
  FeatureI,
  RequirementI,
  StoryI
} from '../../../features/requirements-store/store/requirements.model'

import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import {
  INIT_DATA1,
  OverlayService
} from '../../../overlay-forms/overlay/overlay.service'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Valuestream } from '../../../features/value-stream-store/store/valuestream.model'
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  Subject,
  Subscription
} from 'rxjs'
import * as fromValueStreamSelectors from '../../../features/value-stream-store/store/valuestream.selectors'
import * as fromPortofolioSelectors from '../../../features/portfolio-store/portfolio/portfolio-safe.selectors'
import * as fromkanbanitemsSelectors from '../../../features/requirements-store/store/item/requirement.selectors'
import * as fromActionsKanbanItems from '../../../features/requirements-store/store/item/requirement.actions'
import * as fromOrganizationMembers from '../../../features/organization-store/store/organization-member.selectors'
import { PortfolioSafe } from '../../../features/portfolio-store/portfolio/portfolio-safe.model'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators'
import { KanbanItemService } from '../../../features/requirements/services/kanban-item.service'
import { EpicDynamicRequirementFormComponent } from '../epic/epic-dynamic-requirement-form/epic-dynamic-requirement-form.component'
import { OverlayFormRef } from '../../../overlay-forms/overlay/overlay-form-ref'
import * as kanbanItemActions from '../../../features/requirements-store/store/item/requirement.actions'
import { User } from '../../../features/user-store/user.model'
import * as fromUsersSelectors from '../../../features/user-store/user.selectors'
import * as fromFeatureSelectors from '../../../features/feature-selectors'
import { ART } from '../../../features/arts-store/art.model'
import { ColorPickerControl } from 'ngx-color-picker-angular-13'
import { MatSelect } from '@angular/material/select'
import {
  KeyResult,
  Objective,
  StrategicTheme
} from '../../../features/strategic-themes-store/store/strategic-themes.model'
import { Update } from '@ngrx/entity'
import * as fromStrategicThemes from '../../../features/feature-selectors'
import { MatDialog } from '@angular/material/dialog'
import { ColorpickerComponent } from './colorpicker/colorpicker.component'

import { MatCheckbox } from '@angular/material/checkbox'
import { BudgetInformationFormComponent } from '../../budget-information-form/budget-information-form.component'
import * as fromKanbanSelectors from '../../../kanban/store/kanban-selector'
import * as fromRequirementsActions from '../../../features/requirements-store/store/item/requirement.actions'
import { Kanban } from '../../../kanban/store/kanban.model'
import * as fromARTSelectors from '../../../features/arts-store/art.selectors'
import { Artevent } from './feature-row/feature-row.component'
import { numberValidator } from '../../form-custom-validators/number-validator'
import {
  OrganizationMember,
  OrganizationMemberWithUser
} from '../../../features/organization-store/store/organization-member.model'
import { HistoryAudit } from '../../../features/history-audit-store/history-audit.model'
import * as fromAuditSelectors from '../../../features/history-audit-store/history-audit.selectors'
import { AttachmentService } from '../../attachment/services/attachment.service'
import { AttachMentResponse, File } from '../../attachment/services/model'
import { deepStrictEqual } from 'assert'
import { ImproveEpicFormComponent } from '../../improve-epic-form/improve-epic-form.component'
import { BreakDownEpicFormComponent } from '../../break-down-epic-form/break-down-epic-form.component'
import { CopyEpicFormComponent } from './copy-epic-form/copy-epic-form.component'
import { FindLeadingIndicatorsComponent } from '../../find-leading-indicators/find-leading-indicators.component'
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints
} from '@angular/cdk/layout'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { Solution } from '../../../features/solutions-store/store/solutions.model'
import { Id } from '../../models/entity-base.model'
interface EpicType {
  viewValue: string
  value: string
}

interface EpicState {
  id: string
  name: string
}

interface SelectedArts {
  featureId: string
  valueStreams: any[]
}

@Component({
  selector: 'anms-epic-overlay-form',
  templateUrl: './epic-overlay-form.component.html',
  styleUrls: ['./epic-overlay-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TranslatePipe,
      useFactory(service: TranslateService, ref: ChangeDetectorRef) {
        return new TranslatePipe(service, ref)
      },
      deps: [TranslateService, ChangeDetectorRef]
    }
  ]
})
export class EpicOverlayFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChildren('checkBox') vsselect: QueryList<MatCheckbox>
  @ViewChild('name') name: ElementRef
  @ViewChild('selectAll') skillSel: MatSelect
  @ViewChild('keyResult') keyResult: ElementRef
  @ViewChild('color') colorInput: ElementRef

  @ViewChildren(CdkTextareaAutosize) autosizes!: QueryList<CdkTextareaAutosize>

  @ViewChild('dynamic', {
    read: ViewContainerRef
  })
  viewContainerRef: ViewContainerRef
  faInfoCircle = faInfoCircle
  form: FormGroup
  submited: boolean
  isUpdate = false
  subscription: Subscription
  subscriptionOfScope: Subscription
  showAddFeatureMessage = true

  public githubControl = new ColorPickerControl().setValueFrom('#1273DE')

  scope$: Observable<Valuestream[]>
  portfolios$: Observable<PortfolioSafe[]>
  //  strategicThemes$: Observable<StrategicTheme[]>
  objectives$: Observable<StrategicTheme[]>
  arts$: Observable<ART[]>
  epic: Epic

  styleColor
  styleColorContainer

  epicTypes: EpicType[] = [
    { value: 'business', viewValue: 'Business' },
    { value: 'enabler', viewValue: 'Enabler' }
  ]
  epicOwners$: Observable<OrganizationMemberWithUser[]>
  arrayItems: {
    id: number
    title: string
  }[]
  componentRef: any
  children$: Observable<RequirementI[]>
  features: FormGroup
  formTitle: string
  formNewTitle: string
  created: Date
  updated: Date
  key: string
  auditHistoryChanges$: Observable<HistoryAudit[]>

  organizationId
  editors$: Observable<User[]>

  teamMates$: Observable<OrganizationMemberWithUser[]>
  // valueStreamId$: Subject<string>
  openDialog$: Observable<any>
  isUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  scopeOut$: Subject<Id[]> = new Subject()

  subject: Subject<any> = new Subject()
  scopeUpdated$: Subject<boolean> = new Subject()
  disableColorPicker = false
  subscriptionNew: Subscription
  executed = false
  valuesStreamOfSelectedArts: SelectedArts[] = []
  solutions$: Observable<Solution[]>
  states$: Observable<EpicState[]>

  epicState$: Observable<string>

  addItemexecuted = false

  file = null
  filesOfItem: File[]
  newFile: File

  minDueDate: Date

  showErrorMinDueDate$ = new BehaviorSubject<boolean>(false)

  mentionsConfig: any

  public options: Object = {
    placeholderText:
      ' For <b>customers</b><br> who <b>do something</b><br>the <b>solution</b><br>' +
      'is a <b>something – the ‘how’</b><br>' +
      'that <b>provides this value</b><br>' +
      'unlike <b>competitor, current solution or non-existing solution</b><br>' +
      'our solution <b>does something better — the ‘why’</b>',
    heightMin: 200,
    zIndex: 1000

    // charCounterCount: false,

    // https://github.com/froala/vue-froala-wysiwyg/issues/181
    // toolbarButtons: 'indexOf: key => Object.keys(this).indexOf(key),',
  }

  // private dialogRef: OverlayFormRef
  nonFunctionalOpenState = false
  leadingIndicatorsOpenState = false
  businessOutcome = false

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: OverlayFormRef,
    public translateService: TranslateService,
    public itemService: KanbanItemService,
    private resolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    private previewDialog: OverlayService,
    private attachmentService: AttachmentService,
    private sanitizer: DomSanitizer,

    @Inject(INIT_DATA1)
    public data: { portfolioId; epic; columnId; kanbanId; solutionId }
  ) {
    this.submited = false
    // Initialize form with default values

    // Create a FormArray from epic.keyResultsId
    const keyResultsFormArray =
      this.data?.epic?.keyResultsId &&
      Array.isArray(this.data.epic.keyResultsId)
        ? this.formBuilder.array(
            this.data.epic.keyResultsId.map((id) =>
              this.formBuilder.control(id)
            )
          )
        : this.formBuilder.array([]) // Default to empty array if null, undefined, or not an array

    // Create a FormArray from epic.valueStreams
    const valueStreamsFormArray =
      this.data?.epic?.valueStreams &&
      Array.isArray(this.data.epic.valueStreams)
        ? this.formBuilder.array(
            this.data.epic.valueStreams.map((valueStream) =>
              this.formBuilder.group({
                id: this.formBuilder.control(valueStream.id)
              })
            )
          )
        : this.formBuilder.array([])

    const controlsConfig = {
      id: '',
      code: ['', [Validators.required]],
      name: '',
      itemName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      type: ['', [Validators.required]],
      entryDate: [''],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      WSJF: [''],
      kanbanId: ['', [Validators.required]],
      columnId: '',
      outcome: [''],
      toKanban: [''],
      tasks: [[]],
      features: [[]],
      locked: [''],
      solutionId: '',
      portfolioId: [{ value: this.data.portfolioId }],
      epicType: ['', [Validators.required]],
      forecastedEpicCost: ['', [numberValidator]],
      forecastedEpicDurationInStoryPoints: '',
      forecastedEpicDurationInTime: '',
      problemStatement: [''],
      businessOutComeHypothesis: '',
      leadingIndicators: '',
      nonFunctionalRequirements: '',
      solutionsId: this.formBuilder.array([]),

      keyResultsId: keyResultsFormArray,
      valueStreams: valueStreamsFormArray,
      inRoadmap: '',
      epicOwnerId: '',
      ownerId: [''],
      dueDate: '',
      startDate: '',
      color: '',
      notes: [''],
      keyResultId: [''],
      fileSource: [''],
      files: ['']
    }
    const options: AbstractControlOptions = {
      validators: this.MustMatch('startDate', 'dueDate')
    }

    this.form = this.formBuilder.group(controlsConfig, options)
  }

  initializeEpic(): EpicI<FeatureI<StoryI>> {
    const epic: EpicI<FeatureI<StoryI>> = {
      id: '',
      name: 'epic',
      columnId: this.data.columnId,
      kanbanId: this.data.kanbanId,
      problemStatement: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      // MVPFeatures: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      businessOutComeHypothesis: '',
      leadingIndicators: '',
      nonFunctionalRequirements: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      WSJF: '',
      assignedToArt: false,
      assigned: false,
      code: '',
      description: '',
      itemName: '',
      locked: false,
      ownerId: '',
      notes: '',
      portfolioId: this.data.portfolioId,
      solutionId: this.data.solutionId,
      toKanban: false,
      type: '',
      valueStreams: [],
      epicType: 'portfolioEpic',
      inRoadmap: false,
      epicOwnerId: '',
      color: '#283593',
      dueDate: null,
      startDate: null,
      keyResultsId: []
    }
    return epic
  }

  ngOnInit(): void {
    // this.githubControl.setColorPresets([
    //   '#795548', '#673ab7', '#3f51b5', '#2196f3', '#4caf50', '#ffc107', '#ff5722', '#f44336',
    //   '#94776d', '#8561c5', '#6574c4', '#4dabf5', '#70bf73', '#ffcd39', '#ff794e', '#f6695e',
    //   '#af9991', '#a489d4', '#8c97d3', '#7ac0f8', '#94cf96', '#ffda6a', '#ff9a7a', '#f88e86',
    //   '#c9bbb6', '#c2b0e2', '#b2b9e1', '#a6d5fa', '#b7dfb9', '#ffe69c', '#ffbca7', '#fbb4af',
    // ])

    // https://colorswall.com/collections/17
    // https://colorswall.com/collections/9

    // this.store.dispatch(strategicThemesActions.loadStrategicThemes())

    // this.store.dispatch(fromUserActions.loadUsers())

    // this.valueStreamId$ = new Subject<string>()

    this.mentionsConfig = {
      items: [''], // Initially set data to empty array
      triggerChar: '@',
      mentionSelect: (item) =>
        // Customize the format of the selected mention
        `@'${item.label}'`
    }

    this.form.reset()

    this.portfolios$ = this.store.select(fromPortofolioSelectors.selectAll)

    const owners1$ = this.store.select(fromUsersSelectors.selectAll)

    this.editors$ = combineLatest([owners1$, this.isUpdate$]).pipe(
      map(
        ([owners, isUpdate]) => {
          if (isUpdate) {
            this.form.get('ownerId').disable()
            return owners
          } else return undefined
        },
        filter((val) => !!val)
      )
    )

    const portfolioId = this.data.portfolioId
    if ('portfolioId' in this.data) {
      this.form.get('portfolioId').disable()

      this.solutions$ = this.store
        .select(
          fromFeatureSelectors.getDevelopingValueStreamsByPortfolioId(
            portfolioId
          )
        )
        .pipe(
          switchMap((entities: Valuestream[]) => {
            const ids: Id[] = entities.map((val) => ({ id: val.id }))
            return this.store.select(
              fromFeatureSelectors.selectSolutionsByValuesStreams(ids)
            )
          })
        )

      this.epicOwners$ = this.store
        .select(fromPortofolioSelectors.getPortfolioSafeById(portfolioId))
        .pipe(
          filter((portfolio) => !!portfolio),
          mergeMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getMembersList(portfolio.organizationId)
            )
          )
        )
      // retrieve all team mates of this organization
      this.teamMates$ = this.store
        .select(
          fromPortofolioSelectors.getPortfolioSafeById(this.data.portfolioId)
        )
        .pipe(
          filter((portfolio) => !!portfolio),
          switchMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getMembersList(portfolio.organizationId)
            )
          )
        )

      this.teamMates$.subscribe((teamMembers) => {
        this.mentionsConfig.items = teamMembers.map(
          (teamMember) => teamMember.user.name
        )
      })
    }

    // edition mode
    if ('epic' in this.data) {
      this.isUpdate$.next(true)
      this.isUpdate = true
      //  UPDATE ITEM FORM

      this.epic = { ...this.data.epic }

      this.auditHistoryChanges$ = this.store.select(
        fromAuditSelectors.getHistoryAuditByRequirement(this.epic.id)
      )
      if (this.epic.files) {
        this.filesOfItem = this.epic.files
      } else {
        this.filesOfItem = []
      }

      // .filter(
      //   (history) => history.type === 'UPDATE' || history.type === 'DELETE'
      // )

      if (this.epic.children) {
        this.epic.children.forEach((child) =>
          this.valuesStreamOfSelectedArts.push({
            featureId: child.id,
            valueStreams: []
          })
        )
      }

      this.epicState$ = this.store.select(
        fromKanbanSelectors.getStateNameByColumnId(
          this.epic.kanbanId,
          this.epic.columnId
        )
      )

      this.states$ = this.store.select(
        fromFeatureSelectors.getStatesAndColumnIdsByItemId(this.epic.id)
      )

      /*
      this.epicOwners$ = this.store
        .select(
          fromPortofolioSelectors.getPortfolioSafeById(this.epic.portfolioId)
        )
        .pipe(
          mergeMap((portfolio) =>
            this.store.select(
              fromFeatureSelectors.getMembersList(portfolio.organizationId)
            )
          ),
          distinctUntilChanged()
        )*/

      if (this.epic.color) {
        this.disableColorPicker = true
        this.form.get('color').disable()
      }
      if (
        this.data.epic.portfolioId !== null &&
        this.data.epic.portfolioId !== undefined
      ) {
        this.form.get('portfolioId').disable()
      }

      // DueDateValidation
      if (this.epic.startDate) {
        this.minDueDate = new Date(this.epic.startDate)
      }

      this.objectives$ = this.store.pipe(
        // eslint-disable-next-line ngrx/select-style
        select(
          fromPortofolioSelectors.getPortfolioSafeById(this.epic.portfolioId)
        ),
        filter((val) => !!val),
        switchMap((portfolio: PortfolioSafe) =>
          this.store.select(
            fromFeatureSelectors.selectStrategicThemesByPortfolioId(
              portfolio.id
            )
          )
        )
      )
      if (this.epic.portfolioId) {
        this.epicOwners$ = this.store
          .select(
            fromPortofolioSelectors.getPortfolioSafeById(this.epic.portfolioId)
          )
          .pipe(
            filter((portfolio) => !!portfolio),
            mergeMap((portfolio) =>
              this.store.select(
                fromFeatureSelectors.getMembersList(portfolio.organizationId)
              )
            )
          )

        this.solutions$ = this.store
          .select(
            fromFeatureSelectors.getDevelopingValueStreamsByPortfolioId(
              this.epic.portfolioId
            )
          )
          .pipe(
            switchMap((entities: Valuestream[]) => {
              const ids: Id[] = entities.map((val) => ({ id: val.id }))
              return this.store.select(
                fromFeatureSelectors.selectSolutionsByValuesStreams(ids)
              )
            })
          )

        // retrieve all team mates of this organization
        this.teamMates$ = this.store
          .select(
            fromPortofolioSelectors.getPortfolioSafeById(this.epic.portfolioId)
          )
          .pipe(
            filter((portfolio) => !!portfolio),
            switchMap((portfolio) =>
              this.store.select(
                fromFeatureSelectors.getMembersList(portfolio.organizationId)
              )
            )
          )

        this.teamMates$.subscribe((teamMembers) => {
          this.mentionsConfig.items = teamMembers.map(
            (teamMember) => teamMember.user.name
          )
        })
      }

      const ownerId = this.epic.ownerId
      if (ownerId !== null && ownerId !== undefined) {
        this.form.get('ownerId').disable()
      }

      if (this.epic.epicType === undefined || this.epic.epicType === null) {
        this.form.get('epicType').setValue('portfolioEpic')
      }

      this.scope$ = this.store.select(
        fromValueStreamSelectors.getDevelopingValueStreamsByPortfolioIdFunction(
          this.epic.portfolioId
        )
      )

      this.formTitle = this.epic.itemName
      this.created = this.epic.creationDate
      this.updated = this.epic.lastUpdate
      this.key = this.epic.numeration

      this.store.dispatch(
        kanbanItemActions.loadChildrenKanbanitems({ parentId: this.epic.id })
      )

      const children2 = this.store.pipe(
        // eslint-disable-next-line ngrx/select-style
        select(fromFeatureSelectors.getChildrenKanbanItems(this.epic.id))
      )
      this.children$ = combineLatest([children2, this.isUpdate$]).pipe(
        map(([childrens, isUpdate]) => {
          if (isUpdate) return childrens
          else return undefined
        }),
        filter((val) => !!val)
      )

      if (this.epic.valueStreams)
        this.scopeOut$.next([...this.epic.valueStreams])

      // DELETE BECAUSE PATCH VALUE OVERWRITES
      // TO AVOID ADD SAME ID TWICE INTO DE ARRAYFORM
      // const epicPatch = { ...this.epic }
      // delete epicPatch.valueStreams

      // Destructuring_assignment because patch fails with formArray

      const { valueStreams, ...epicPatch } = this.epic
      this.form.patchValue(epicPatch)

      this.form.get('startDate').setValue(new Date(this.epic.startDate))
      this.form.get('dueDate').setValue(new Date(this.epic.dueDate))
    } else {
      // NEW ITEM FORM
      this.formTitle = 'New Epic'
      this.scope$ = this.store.select(
        fromValueStreamSelectors.getDevelopingValueStreamsByPortfolioIdFunction(
          portfolioId
        )
      )

      this.objectives$ = this.store.select(
        fromStrategicThemes.selectStrategicThemesByPortfolioId(portfolioId)
      )
      // this.form.patchValue(this.initializeEpic())
    }

    this.initializeValueStreamsSubscription()

    this.scopeUpdated$.pipe(debounceTime(1000)).subscribe((data) => {
      if (data === true) {
        const epicPatch = this.form.getRawValue()

        const kanbanItem: Update<RequirementI> = {
          id: epicPatch.id,
          changes: { valueStreams: epicPatch.valueStreams }
        }
        this.store.dispatch(
          fromActionsKanbanItems.patchKanbanitem({ kanbanitem: kanbanItem })
        )

        this.epic = epicPatch
      }
    })
  }

  get valueStreams(): FormArray {
    return this.form.get('valueStreams') as FormArray
  }
  // Getter for easy access to keyResultIds form array
  get keyResultIdsArray(): FormArray {
    return this.form.get('keyResultsId') as FormArray
  }
  // Method to sanitize HTML
  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.form.get('description')?.value
    )
  }
  /**
   * Handle checkbox change and update FormArray
   */
  onCheckboxChange(event: { checked: boolean; keyResultId: string }) {
    if (event.checked) {
      this.keyResultIdsArray.push(this.formBuilder.control(event.keyResultId)) // Add to FormArray

      // Create an update object to include the new keyResultId
      const updatedKeyResultsId = [
        ...(this.epic.keyResultsId || []),
        event.keyResultId
      ]

      const update: Update<RequirementI> = {
        id: this.epic.id,
        changes: { keyResultsId: updatedKeyResultsId }
      }

      // Dispatch the 'add' operation to the store
      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({
          kanbanitem: update,
          op: 'add'
        })
      )
    } else {
      const index = this.keyResultIdsArray.controls.findIndex(
        (control) => control.value === event.keyResultId
      )
      if (index !== -1) {
        this.keyResultIdsArray.removeAt(index) // Remove from FormArray
      }

      // Remove the keyResultId from the updated list
      const updatedKeyResultsId = (this.epic.keyResultsId || []).filter(
        (id) => id !== event.keyResultId
      )

      const update: Update<RequirementI> = {
        id: this.epic.id,
        changes: { keyResultsId: updatedKeyResultsId }
      }

      // Dispatch the 'remove' operation to the store
      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({
          kanbanitem: update,
          op: 'remove'
        })
      )
    }
  }
  // Get description by ID
  getKeyResultDescription(id: number, objectives): string {
    for (const theme of objectives) {
      const keyResult = theme.keyResults.find((kr) => kr.id === id)
      if (keyResult) {
        return keyResult.description
      }
    }
    return ''
  }

  save() {
    if (this.form.valid) {
      const epic = this.form.getRawValue()

      epic.valueStreams = epic.valueStreams.filter((va) => va.id !== '')

      this.store.dispatch(
        fromActionsKanbanItems.upsertKanbanitem({
          kanbanitem: epic
        })
      )

      this.epic = epic
      this.formTitle = this.epic.itemName

      this.isUpdate$.next(true)
    } else {
      this.validateAllFormFields(this.form)
    }
  }

  saveNew() {
    if (this.form.valid) {
      // eslint-disable-next-line prefer-const

      const epic: Epic = this.form.getRawValue()

      this.itemService.addKanbanItem(epic).subscribe((data: Epic) => {
        this.store.dispatch(
          fromRequirementsActions.successAddKanbanitem({
            kanbanitem: data
          })
        )

        this.dialogRef.close()
        // this.isUpdate$.next(true)
        // this.updateEpicInfo(data as Epic)
      })
    }
  }

  delete() {
    const id = this.form.value.id

    this.store.dispatch(
      fromActionsKanbanItems.deleteKanbanitem({
        id
      })
    )

    this.dialogRef.close()
  }
  closeDialog() {
    this.dialogRef.close()
  }

  onSubmit($event): void {
    if ($event.submitter.id === 'save') {
      this.save()
    }
    if ($event.submitter.id === 'saveNew') {
      // console.log('here')
      this.saveNew()
    }
  }

  addItem(currentEpic: any) {
    if (!this.addItemexecuted) {
      this.addItemexecuted = true

      this.componentRef = this.viewContainerRef.createComponent(
        EpicDynamicRequirementFormComponent
      )
      this.componentRef.instance.data = {
        parentId: currentEpic.id,
        parentComponent: this
      }
      this.showAddFeatureMessage = false
    }
    // this.updateFeatures()
  }

  deleteItem() {
    this.viewContainerRef.clear()
    this.showAddFeatureMessage = true
    this.addItemexecuted = false
  }

  /*
  updateEpicInfo(epic?: Epic) {
    this.form.get('id').setValue(epic.id)
    this.form.get('columnId').setValue(epic.columnId)
    this.form.get('ownerId').setValue(epic.ownerId)
    this.form.get('color').disable()

    this.epic = epic
    this.formTitle = epic.itemName
    this.key = epic.numeration
    this.children$ = this.store.pipe(
      // eslint-disable-next-line ngrx/select-style
      select(fromFeatureSelectors.getChildrenKanbanItems(this.epic.id)),
      map((val) => {
        const child = []
        val.forEach((item) =>
          child.push(this.itemService.convert(item as RequirementI))
        )
        return child
      })
    )
    if (this.epic.keyResultId) {
      // this.form.get('keyResult').patchValue(epic.keyResult)
      this.form.get('keyResultId').setValue(epic.keyResultId)
    }
  }
    */

  ngAfterViewInit() {
    if (!this.isUpdate) {
      const searchText$ = fromEvent<any>(this.name.nativeElement, 'keyup')
        .pipe(
          debounceTime(1000),
          map((event) => event.target.value),
          switchMap((source) =>
            this.itemService
              .askForCode(source)
              .pipe(
                tap((data) => this.form.controls['code'].setValue(data['code']))
              )
          )
        )
        .subscribe()
    }

    if ('epic' in this.data) {
      this.styleColor = `background-color: ${this.data.epic.color}; color: ${this.data.epic.color}; width: 80px;`
      this.styleColorContainer = 'width: 80px;'
    } else {
      this.styleColor =
        'background-color: #283593; color: #283593; width: 80px;'
      this.styleColorContainer = 'width: 80px;'
    }
    if (
      this.data.epic?.dueDate === null ||
      this.data.epic?.dueDate === undefined
    ) {
      this.form.get('dueDate').reset()
    }
    if (
      this.data.epic?.startDate === null ||
      this.data.epic?.dueDate === undefined
    ) {
      this.form.get('startDate').reset()
    }

    // TO DISABLE CHECKBOX WHEN ART IS SELECTED
    //  this.valuesStreamOfSelectedArts.sort((a, b) => a.id.localeCompare(b.id))
  }

  ngOnChanges(): void {}

  isChecked(i: number, valueStreams: Valuestream[]) {
    const aa = this.form.get('valueStreams') as FormArray

    if (
      valueStreams.map((s) => s.id).indexOf(aa.controls[i].get('id').value) !==
      -1
    ) {
      return true
    } else {
      return false
    }
  }
  setValue(i: number, value: string, checked: boolean) {
    const sendValue = checked === true ? value : ''
    this.valueStreams.controls[i].get('id').setValue(sendValue)

    const values: Id[] = []
    this.valueStreams.controls.forEach((control) => {
      if (control.get('id').value !== '') {
        values.push({ id: control.get('id').value })
      }
    })

    this.scopeOut$.next([...values])
    if (this.epic) {
      this.scopeUpdated$.next(true)
    }
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  openDialog(): void {
    if (!this.disableColorPicker) {
      const dialogRef = this.dialog.open(ColorpickerComponent, {
        width: '38vh',
        minWidth: '35vh',
        maxWidth: '85vh'
      })
      this.openDialog$ = dialogRef.afterClosed()
      this.openDialog$.subscribe((data) => {
        if (data) {
          this.styleColor = `background-color: ${data.value.toHexString()}; color: ${data.value.toHexString()}; width: 100px;`
          this.form.get('color').setValue(data.value.toHexString())
          this.colorInput.nativeElement.blur()
        }
      })
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    // {1}
    Object.keys(formGroup.controls).forEach((field) => {
      // {2}
      const control = formGroup.get(field) // {3}
      if (control instanceof FormControl) {
        // {4}
        control.markAsTouched({ onlySelf: true })
      } else if (control instanceof FormGroup) {
        // {5}
        this.validateAllFormFields(control) // {6}
      }
    })
  }
  // GET THE VALUE STREAMS RELATED TO ARTS FROM FEATURE ROW COMPONENT
  setCurrentValuesStreams(valueStreams: any[] | any) {
    const ids = []
    const i = this.valuesStreamOfSelectedArts
      .map((id) => id.featureId)
      .indexOf(valueStreams.childId)
    if (
      !this.valuesStreamOfSelectedArts[i] ||
      !this.valuesStreamOfSelectedArts[i].valueStreams
    ) {
      return
    }
    if (!valueStreams.change) {
      valueStreams.valueStream?.forEach((element) => {
        ids.push({ id: element.id })
      })
    } else {
      this.valuesStreamOfSelectedArts[i].valueStreams = []
      if (valueStreams.valueStream === null) return
      valueStreams.valueStream?.forEach((element) =>
        ids.push({ id: element.id })
      )
    }
    this.valuesStreamOfSelectedArts[i].valueStreams = ids
  }
  // SET DISABLE OR ENABLE THE CHECKBOX
  disabled(i) {
    const valueStreamsCheck = this.form.get('valueStreams') as FormArray
    let disable = false
    // eslint-disable-next-line guard-for-in
    for (const key in this.valuesStreamOfSelectedArts) {
      if (!this.valuesStreamOfSelectedArts[key].valueStreams) {
        return
      }
      if (
        this.valuesStreamOfSelectedArts[key].valueStreams
          .map((s: any) => s.id)

          .indexOf(valueStreamsCheck.controls[i].get('id').value) === -1 &&
        !disable
      ) {
        valueStreamsCheck.controls[i].enable()
      } else {
        valueStreamsCheck.controls[i].disable()

        disable = true
      }
    }
  }

  // Open budget form

  showBudgetForm(originEl, itemId) {
    const domElement = originEl.nativeElement as HTMLElement
    const form = this.previewDialog.open(
      {},
      {
        origin: domElement,
        content: BudgetInformationFormComponent,
        data: { itemId }
      }
    )
  }
  onSelectART(event: Artevent) {
    if (event.artId === '' || event.artId === null) {
      // UPDATE DE ITEM TO SET KANBAN NULL, RELEASED FALSE AND TOKANBAN FALSE (PATCH WONT DO THIS BECAUSE IT'S MANDATORY TO SEND IDS)
      /* const feature = {
        id: event.featureId,
        kanbanId: null,
        columunId: null,
        released: false,
        toKanban: false
      }
      */
      const feature: Update<RequirementI> = {
        id: event.featureId,
        changes: { kanbanId: null, columnId: null, toKanban: false }
      }

      const kanbanitem = feature as unknown as Update<RequirementI>

      this.store.dispatch(
        fromRequirementsActions.patchKanbanitem({
          kanbanitem: kanbanitem
        })
      )
    } else {
      const kanban$: Observable<Kanban> = this.store.pipe(
        // eslint-disable-next-line ngrx/select-style
        select(fromARTSelectors.selectART(event.artId)),
        switchMap((art: ART) =>
          this.store.select(fromKanbanSelectors.getKanbanByIdM(art.kanbanId))
        ),

        filter((val) => val !== undefined),
        tap((kanban) => {
          // TODO BETTER WAY LOAD KANBAN
          // eslint-disable-next-line ngrx/avoid-dispatching-multiple-actions-sequentially

          const kanbanitem: Update<RequirementI> = {
            id: event.featureId,
            changes: { kanbanId: kanban.id, columnId: kanban.columns[0].id }
          }

          this.store.dispatch(
            fromRequirementsActions.patchKanbanitem({
              kanbanitem: kanbanitem
            })
          )
        })
      )

      kanban$.subscribe()
    }
    // TODO
  }

  onChangeKeyResult(event) {
    if (!this.isUpdate) return

    const keyResultId = event.value

    // Clone the current keyResultsId array or initialize it if undefined
    const currentKeyResultsId = this.epic.keyResultsId || []

    // Determine if the keyResultId already exists in the array
    const isExisting = currentKeyResultsId.includes(keyResultId)

    let updatedKeyResultsId: string[] | null

    if (keyResultId) {
      if (isExisting) {
        // Remove the keyResultId if it already exists
        updatedKeyResultsId = currentKeyResultsId.filter(
          (id) => id !== keyResultId
        )
      } else {
        // Add the keyResultId if it doesn't exist
        updatedKeyResultsId = [...currentKeyResultsId, keyResultId]
      }
    } else {
      // If keyResultId is null, set the array to null
      updatedKeyResultsId = null
    }

    const epic: Update<RequirementI> = {
      id: this.epic.id,
      changes: { keyResultsId: updatedKeyResultsId }
    }

    this.store.dispatch(
      fromRequirementsActions.patchKanbanitem({
        kanbanitem: epic
      })
    )
  }
  // PATCH VALUE ON THE MAT GROUP FORM
  /* setValueKeyResultGruopField(object1: KeyResult, object2: KeyResult) {
    return object1 && object2 && object1.description === object2.description
  }
  */

  updateNameEpic(event) {
    if (event !== null && event !== '' && event !== this.formTitle) {
      const update: Update<RequirementI> = {
        id: this.epic.id,
        changes: { itemName: event }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
    }
  }

  updateEpicOwner(value) {
    if (!this.isUpdate) return
    const update: Update<RequirementI> = {
      id: this.epic.id,
      changes: { epicOwnerId: value }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  changeState(event) {
    const id = event.value

    if (!this.isUpdate) return
    const update: Update<RequirementI> = {
      id: this.epic.id,
      changes: { columnId: id }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  onHandleFile(event: any) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0]
      this.form.patchValue({
        files: this.file
      })

      const formData = new FormData()
      formData.append('file', this.file)
      this.attachmentService
        .uploadFile(formData)
        .subscribe((data: AttachMentResponse) => {
          this.newFile = { ...data }
          this.filesOfItem = [...this.filesOfItem, this.newFile]

          this.form.patchValue({
            files: this.filesOfItem
          })
          const update: Update<RequirementI> = {
            id: this.epic.id,
            changes: { files: this.filesOfItem }
          }

          this.store.dispatch(
            fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
          )
        })
    }
  }

  onSubmitFile() {
    const update: Update<RequirementI> = {
      id: this.epic.id,
      changes: { files: this.filesOfItem }
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }

  downLoadFile(url: string) {
    window.location.href = url
  }
  deleteFile(id) {
    const index = this.filesOfItem.findIndex((file) => file.id === id)
    const filesArray = [...this.filesOfItem]
    if (index !== -1) {
      filesArray.splice(index, 1)
      const update: Update<RequirementI> = {
        id: this.epic.id,
        changes: { files: filesArray }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
      this.filesOfItem = filesArray
      this.form.patchValue({
        files: this.filesOfItem
      })
    }
  }

  // custom validator to check that two fields match
  // eslint-disable-next-line @typescript-eslint/naming-convention
  MustMatch(startDateControlName: string, endDateControlName: string) {
    return (group: AbstractControl) => {
      const stardDate = group.get(startDateControlName)
      const endDate = group.get(endDateControlName)

      if (!stardDate || !endDate) {
        return null
      }

      // return if another validator has already found an error on the matchingControl
      if (stardDate.errors && !endDate.errors?.mustMatch) {
        return null
      }

      // set error on matchingControl if validation fails
      if (new Date(stardDate.value) > new Date(endDate.value)) {
        endDate.setErrors({ mustMatch: true })
      } else {
        endDate.setErrors(null)
      }
      return null
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls
  }

  openImproveEpicForm(
    origin: HTMLElement,
    description: string,
    nonFunctionalRequirements?: string
  ) {
    this.dialogRef = this.previewDialog.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.95,
        maxWidth: '50%',
        /** Position overrides. */
        position: { right: '0' },
        hasBackdrop: false
        // panelClass: 'dialog-slide-in'
      },
      {
        origin,
        data: {
          epicDescription: this.stripHtml(description),
          nonFunctionalRequirements,
          parentComponent: this
        },
        content: ImproveEpicFormComponent
      }
    )
  }

  findLeadingIndicators(
    origin: HTMLElement,
    epicDescription: string,
    nonFunctionalRequirements?: string
  ) {
    this.dialogRef = this.previewDialog.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.95,
        maxWidth: '50%',
        /** Position overrides. */
        position: { right: '0' },
        hasBackdrop: false
        // panelClass: 'dialog-slide-in'
      },
      {
        origin,
        data: {
          epicDescription: this.stripHtml(epicDescription),
          nonFunctionalRequirements,
          parentComponent: this
        },
        content: FindLeadingIndicatorsComponent
      }
    )
  }
  openBreakDownEpicForm(origin: HTMLElement, description: string) {
    this.dialogRef = this.previewDialog.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.45,
        maxWidth: '50%',
        /** Position overrides. */
        position: { right: '0' },
        hasBackdrop: false
        // panelClass: 'dialog-slide-in'
      },
      {
        origin,
        data: {
          epicDescription: this.stripHtml(description),
          parentId: this.epic.id
        },
        content: BreakDownEpicFormComponent
      }
    )
  }
  setDescription(description) {
    this.form.get('description').setValue(description)
  }
  setLeadingIndicators(description) {
    this.form.get('leadingIndicators').setValue(description)
  }

  stripHtml(html) {
    if (html == null) {
      return '' // Return an empty string if the input is null or undefined
    }
    const regex = /<[^>]*>?/gm
    return html.replace(regex, '')
  }

  openCopyEpicForm(origin) {
    {
      this.dialogRef = this.previewDialog.open(
        { height: 350, width: 650 },
        {
          content: CopyEpicFormComponent,
          origin,
          data: { epic: this.epic }
        }
      )
    }
  }
  get solutionsIdArray(): FormArray {
    return this.form.get('solutionsId') as FormArray
  }
  onSolutionChange(event: { checked: boolean; solutionId: string }) {
    // If the checkbox is checked
    if (event.checked) {
      // Clear the FormArray to ensure only one checkbox is selected at a time
      this.solutionsIdArray.clear()

      // Add the currently selected solutionId to the FormArray
      this.solutionsIdArray.push(this.formBuilder.control(event.solutionId))

      // Update the solutionId field in the form
      this.form.patchValue({
        solutionId: event.solutionId
      })

      const update: Update<RequirementI> = {
        id: this.epic.id,
        changes: { solutionId: event.solutionId }
      }

      // Dispatch the appropriate action depending on whether solutionId exists
      const operation = this.epic.solutionId === undefined ? 'add' : 'replace'

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({
          kanbanitem: update,
          op: operation
        })
      )
    } else {
      // If unchecked, remove the solutionId from the FormArray
      const index = this.solutionsIdArray.controls.findIndex(
        (control) => control.value === event.solutionId
      )

      if (index !== -1) {
        this.solutionsIdArray.removeAt(index)
      }

      // Clear the solutionId field in the form (if no checkbox is selected)
      this.form.patchValue({
        solutionId: ''
      })

      const update: Update<RequirementI> = {
        id: this.epic.id,
        changes: { solutionId: null }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
    }
  }
  onKeyResultRemoved(keyResultId: string): void {
    console.log('Key Result Removed:', keyResultId)

    // First, dispatch an action to update the FormArray locally
    const index = this.keyResultIdsArray.controls.findIndex(
      (control) => control.value === keyResultId
    )

    if (index !== -1) {
      this.keyResultIdsArray.removeAt(index) // Remove from FormArray
    }

    // Prepare the updated list of key result IDs
    const updatedKeyResultsId = this.keyResultIdsArray.controls.map(
      (control) => control.value
    )
    const update: Update<RequirementI> = {
      id: this.epic.id,
      changes: { keyResultsId: updatedKeyResultsId }
    }

    // Dispatch the 'remove' operation to the store
    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({
        kanbanitem: update,
        op: 'remove'
      })
    )
  }
  onDescriptionSaved(description: string): void {
    console.log('Saved description:', description)
    // Optionally sanitize and update view mode, etc.
    //  this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(description);
  }
  private initializeValueStreamsSubscription(): void {
    this.subscription = this.scope$.subscribe((valueStreamList) => {
      const valueStreamsFormArray = this.form.get('valueStreams') as FormArray

      // Clear the existing valueStreams in the FormArray
      valueStreamsFormArray.clear()

      // Add valueStreams dynamically based on the latest valueStreamList
      valueStreamList.forEach((valueStream) => {
        const isSelected = this.epic?.valueStreams?.some(
          (vs) => vs.id === valueStream.id
        )

        valueStreamsFormArray.push(
          this.formBuilder.group({
            id: this.formBuilder.control(isSelected ? valueStream.id : '')
          })
        )
      })
      // Check if the Epic is new (adjust this condition based on your logic)
      if (this.isNewEpic()) {
        // Call the patchValue only for a new Epic
        this.form.patchValue(this.initializeEpic())
      }
    })
  }

  // Helper method to determine if the Epic is new
  private isNewEpic(): boolean {
    // Adjust the condition based on how you determine if it's a new Epic
    return !this.epic || !this.epic.id // For example, check if epic is undefined or doesn't have an id
  }
}
