<div class="main-wrapper">
    <form [formGroup]="form"  (ngSubmit)="onSubmit($event)" class="default-form">
      <div class="form-fields">
        <div class="form-fields-inner d-flex flex-column flex-lg-row">
          
          <div class="contextual-menu">
            <button *ngIf="isUpdate" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container *ngIf="features$ | async as features ">
                <button mat-menu-item (click)="changeParentDialogRef(origin1, features)" #origin1>
                  <mat-icon>change_circle</mat-icon>
                  <span>Change Parent</span>
                </button>
              </ng-container>
          
            </mat-menu>
          </div>

          <div class="left-section form-main px-0 mr-lg-3">
            <div class="d-flex px-0">
              <ng-container *ngIf="isUpdate">
              <anms-breadcrum-requirement [requirement]="userStory" (updateDescription)="updateNameStory($event)" [isUpdate]="isUpdate"></anms-breadcrum-requirement>
       
            </ng-container>
              
            </div>
            <ng-container *ngIf="!isUpdate">
            <mat-form-field appearance="fill" class="default-full-width">
              <mat-label>{{ 'anms.userstory.form.name' | translate }}</mat-label>

              <textarea matInput placeholder="{{'anms.userstory.form.name.placeholder' | translate }}"
                formControlName="itemName"
                     cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="10" ></textarea>

              
              <mat-hint>{{'anms.userstory.form.name.placeholder' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('itemName').invalid">
                {{ 'anms.userstory.form.name' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="isUpdate">
          <div  class="upload-file-field mb-2">
            <input class="upload-file-input" id="fileUpload" placeholder="upload a file" type="file" formControlName="fileSource"
            (change)="onHandleFile($event)">
            <span class="material-icons selected-file">
              attach_file
            </span>
          </div>
        </ng-container>
          
            <mat-form-field appearance="fill" class="default-full-width description-field">
              <mat-label>{{ 'anms.userstory.form.description' | translate }}</mat-label>
              <textarea class="textarea-form" matInput
                placeholder="{{'anms.userstory.form.description.placeholder' | translate }}"
                formControlName="description"  cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"></textarea>
              <mat-hint>{{'anms.userstory.form.description.placeholder' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('description').invalid">
                {{ 'anms.feature.form.description' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          
              <anms-dialog-parent>
                <!-- Edit Mode Template -->
                <ng-template #editMode>
                  <anms-acceptance-criteria-edit [form]="form" [mentionsConfig]="mentionsConfig"
                    (acceptanceCriteriaSaved)="onAcceptanceCriteriaSaved($event)">
                  </anms-acceptance-criteria-edit>

                </ng-template>

                <!-- View Mode Template -->
                <ng-template #viewMode>
                  <div class="py-4 px-2" style="background-color: #f0f1f1;cursor: pointer;">
                    <div [innerHTML]="sanitizedDescription"></div>
                  </div>
                  <div class="py-4" style="color: rgba(0, 0, 0, 0.6)">{{ 'anms.enablerstory.form.acceptanceCriteria.hint' | translate }}
                  </div>
                  
                  </ng-template>
                  </anms-dialog-parent>
      

            <div class="attachment-wrapper" *ngIf="filesOfItem!==undefined && filesOfItem.length>0">
              <anms-attachment-card  *ngFor="let file of filesOfItem" class="mt-1 pr-1 mb-2 " [file]="file" (downloadFileEmmiter)="downLoadFile($event)"
                (deleteFileEmmiter)="deleteFile($event)">
              </anms-attachment-card>
          </div>
          <mat-form-field appearance="fill" class="default-full-width acceptance-criteria-field">
            <mat-label>{{ 'anms.feature.form.notes' | translate }}</mat-label>
            <textarea class="textarea-form" matInput formControlName="notes"></textarea>
            <mat-hint> Notes </mat-hint>
            <mat-error *ngIf="form.get('notes').invalid">
              {{ 'anms.feature.form.notes' | translate }}
              {{ 'anms.examples.form.error1' | translate }}
            </mat-error>
          </mat-form-field>

          <anms-comments
*ngIf="isUpdate && (teamMates$ | async) as teamMates"
[itemId]="userStory.id"
[items]="teamMates">
</anms-comments>
          </div>

          <div class="right-section d-flex flex-column px-0">
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Feature</mat-label>
              <mat-select formControlName="parentId">
                <mat-option value=''>
                  None
                </mat-option>
                <ng-container *ngIf="features$ | async as features">
                  <mat-option *ngFor="let feature of features" [value]="feature.id">
                    {{feature.description}}
                  </mat-option>
                </ng-container>
                </mat-select>
                <mat-hint>{{'anms.userstory.form.feature.hint' | translate }}</mat-hint>
              </mat-form-field>


              <ng-container *ngIf="isUpdate">
                <ng-container *ngIf="states$ | async as states">

                  <mat-form-field appearance="fill" class="px-0">
                    <mat-label>State</mat-label>

                    <mat-select formControlName="columnId" (selectionChange)="changeState($event)">
                      <mat-option *ngFor="let state of states" [value]="state.id">
                        {{state.name}}
                      </mat-option>
                    </mat-select>

                    <mat-hint>State</mat-hint>
                    </mat-form-field>
                    </ng-container>
              </ng-container>


            <mat-form-field appearance="fill" class="px-0">
              <mat-label>Team</mat-label>
              <mat-select formControlName="teamId">
                <ng-container *ngIf="teams$ | async as teams">
                  <mat-option *ngFor="let team of teams" [value]="team.id">
                    {{team.name}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="team.invalid">
                {{ 'anms.form.placeholder.team' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="isUpdate">

            
            </ng-container>
            <mat-form-field appearance="fill" class="px-0">
              <mat-label>{{ 'anms.userstory.form.storypoints' | translate }}</mat-label>
              <input matInput placeholder="{{'anms.solution.userstory.form.points.placeholder' | translate }}"
                formControlName="storyPoints">
              <mat-hint>{{'anms.userstory.form.storypoints.placeholder' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('storyPoints').invalid">
                {{ 'anms.solution.userstory.form.points' | translate }}
                {{ 'anms.integer.form.error1' | translate }}
              </mat-error>
            </mat-form-field>

            <ng-container *ngIf="isUpdate">
              <div  class="item-meta">
            <p>Created: <span>{{created | date}}</span></p>
            <p>Last update:<span>{{ updated | date}}</span></p>
          </div>
          </ng-container>


            <div *ngIf="isUpdate" class="item-meta item-meta-bottom">
              <mat-expansion-panel hideToggle *ngIf="auditHistoryChanges$ | async as auditHistoryChanges">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Show History
                  </mat-panel-title>
                  <!-- <mat-panel-description>
                    This is a summary of the content
                  </mat-panel-description> -->
                </mat-expansion-panel-header>
                <ng-container *ngFor="let history of auditHistoryChanges">
                  <anms-audit [historyAudit]="history"></anms-audit>
                </ng-container>


            </mat-expansion-panel>

          </div>

          </div>
        </div>
      </div>
      <div class="form-actions">
        <div class="action-col secondary">
          <button *ngIf="isUpdate" mat-flat-button (click)="delete()" type="button" class="btn-delete px-0"><span
              class="material-icons">delete</span>Delete</button>
        </div>
        <div class="action-col primary">
          <button *ngIf="isUpdate" mat-raised-button color="primary" type="submit" id="save"
            class="btn-save"><span class="material-icons">check</span>Save</button>
          <button *ngIf="!isUpdate" mat-raised-button color="primary" type="submit" id="saveNew" class="btn-save"><span
              class="material-icons">check</span>Save New</button>
        </div>
      </div>
    </form>
</div>
