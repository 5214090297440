import { createFeatureSelector, createSelector } from '@ngrx/store'

import { FeaturesState, selectFeatures } from '../../features.state'
import { Valuestream } from './valuestream.model'
import { adapter, ValueStreamState } from './valuestream.reducer'
import { Id } from '../../../shared/models/entity-base.model'

export const selectvalueStreamState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.valuestreams
)
/*

export const selectValueStreamsState = createFeatureSelector<
  State6,
  ValueStreamState
>(stateFeatureKey)
*/
/*
export const selectValueStreams = createSelector(
  selectValueStreamsState,
  (state: State) => state.valuestreams
)*/

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectvalueStreamState)

export const getValueStreamsById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)
export const getValueStreamById = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getValueStreamsByIds = () =>
  createSelector(
    selectAll,
    (entities: Valuestream[], { vaIds }: { vaIds: string[] }) =>
      entities.filter((item) => vaIds.some((v) => v === item.id))
  )
export const getValueStreamsListByIds = () =>
  createSelector(
    selectAll,
    (entities: Valuestream[], { vaIds }: { vaIds: Id[] }) =>
      entities.filter((item) => vaIds.some((v) => v.id === item.id))
  )

export const getValueStreamsByPortfolioId = createSelector(
  selectAll,
  (entities: Valuestream[], { portfolioId }: { portfolioId: string }) =>
    entities.filter((item) => item.portfolioId === portfolioId)
)
export const getDevelopingValueStreamsByPortfolioId = createSelector(
  selectAll,
  (entities: Valuestream[], { portfolioId }: { portfolioId: string }) =>
    entities.filter(
      (item) => item.portfolioId === portfolioId && item.type === 'development'
    )
)
export const getDevelopingValueStreamsByPortfolioIdFunction = (
  portfolioId: string
) =>
  createSelector(selectAll, (entities: Valuestream[]) =>
    entities.filter(
      (item) => item.portfolioId === portfolioId && item.type === 'development'
    )
  )
export const getDevelopingValueStreamsByOrganizationId = (
  organizationId: string
) =>
  createSelector(selectAll, (entities: Valuestream[]) =>
    entities.filter(
      (item) =>
        item.organizationId === organizationId && item.type === 'development'
    )
  )
export const getDevelopingValueStreamsByOrganizationIdFactory = (
  organizationId: string
) =>
  createSelector(selectAll, (entities: Valuestream[]) =>
    entities.filter(
      (item) =>
        item.organizationId === organizationId && item.type === 'development'
    )
  )
export const getDevelopingValueStreamsByPortfolioIdFactory = (
  portfolioId: string
) =>
  createSelector(selectAll, (entities: Valuestream[]) =>
    entities.filter(
      (item) => item.portfolioId === portfolioId && item.type === 'development'
    )
  )

export const getDevelopmentValueStreams = createSelector(
  selectAll,
  (entities: Valuestream[]) =>
    entities.filter((item) => item.type === 'development')
)
export const getOperationalValueStreams = createSelector(
  selectAll,
  (entities: Valuestream[]) =>
    entities.filter((item) => item.type === 'operational')
)

export const getOperationalValueStreamsByPortfolioId = createSelector(
  selectAll,
  (entities: Valuestream[], { portfolioId }: { portfolioId: string }) =>
    entities.filter(
      (item) => item.portfolioId === portfolioId && item.type === 'operational'
    )
)
export const getOperationalValueStreamsByOrganizationId = (
  organizationId: string
) =>
  createSelector(selectAll, (entities: Valuestream[]) =>
    entities.filter(
      (item) =>
        item.organizationId === organizationId && item.type === 'operational'
    )
  )
export const getOperationalValueStreamsByOrganizationIdFactory = () =>
  createSelector(
    selectAll,
    (entities: Valuestream[], { organizationId }: { organizationId: string }) =>
      entities.filter(
        (item) =>
          item.organizationId === organizationId && item.type === 'operational'
      )
  )

export const getValueStreamsByProps = createSelector(
  selectAll,
  (
    entities: Valuestream[],
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) =>
    // console.log('call', columnId, columnValue)
    // console.log(entities.filter((item) => item[columnId] === columnValue))

    entities.filter(
      (item) => item[columnId] === columnValue
      // && item['toKanban'] === false
    )

  // console.log('entity', entity);
)
