<div class="main-wrapper d-flex flex-column">
  <form [formGroup]="form" (ngSubmit)="onSubmit($event.submitter.id)" class="default-form">
    <div class="form-fields">
      <div class="d-flex justify-content-center">
        <h2>{{ description }}</h2>
      </div>
      <!-- Tab Container -->
      <mat-tab-group>
        <mat-tab label="Acceptance Criteria">
      
          <div class="acceptance-criteria px-0">
                <div [innerHTML]="sanitizedAcceptanceCriteria"></div>
                </div>
              </mat-tab>
              <mat-tab label="IA Generated">
                <div class="acceptance-criteria px-0">
                  <anms-acceptance-criteria-selector (criteriaSelected)="moveSelectedCriteriaToFeature($event)">
                  </anms-acceptance-criteria-selector>
                </div>
              </mat-tab>
              
              <mat-tab label="Context">
                <div class="acceptance-criteria p-3">
            <mat-form-field appearance="fill" class="default-full-width mb-0">
              <mat-label>Context</mat-label>
              <textarea matInput placeholder="{{ 'anms.feature.form.placeholder.acceptance_criteria.context' | translate }}"
                formControlName="context" minlength="3" maxlength="1000" cdkTextareaAutosize
                #autosize2="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
              <mat-hint>Add more context</mat-hint>
              <mat-error *ngIf="form.get('context').invalid">
                {{ 'anms.feature.form.placeholder.acceptance_criteria.context' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </mat-tab>
        </mat-tab-group>

    </div>
<!-- Form Actions -->
    <div class="form-actions">
      <div class="d-flex flex-row mb-2 mb-md-0">
        <div class="action-col accent mb-2 mb-md-0 mr-md-4">
          <button mat-raised-button color="accent" class="btn-save" (click)="copyContentToParent()" [disabled]="!form.valid">
            Save
          </button>
        </div>
        <div class="action-col primary mb-2 mb-md-0">
          <button mat-raised-button color="primary" type="submit" class="btn-load" [disabled]="!form.valid" id="load">
            Generate
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
