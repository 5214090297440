import { Injectable } from '@angular/core'
import { ComponentStore } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

interface AcceptanceCriteriaState {
  criteria: string[]
  selectedCriteria: string[]
}

@Injectable({
  providedIn: 'root'
})
export class AcceptanceCriteriaStore extends ComponentStore<AcceptanceCriteriaState> {
  constructor() {
    super({ criteria: [], selectedCriteria: [] })
  }

  // Public selectors
  readonly criteria$ = this.select((state) => state.criteria)
  readonly selectedCriteria$ = this.select((state) => state.selectedCriteria)
  readonly hasSelections$ = this.selectedCriteria$.pipe(
    map((selected) => selected.length > 0)
  )

  // Updaters
  readonly setCriteria = this.updater((state, criteria: string[]) => ({
    ...state,
    criteria: [...criteria] // Create new array reference
  }))

  readonly toggleCriteriaSelection = this.updater(
    (state, criterion: string) => ({
      ...state,
      selectedCriteria: this.updateSelections(state.selectedCriteria, criterion)
    })
  )

  readonly clearSelections = this.updater((state) => ({
    ...state,
    selectedCriteria: []
  }))

  // Private helper method
  private updateSelections(current: string[], criterion: string): string[] {
    return current.includes(criterion)
      ? current.filter((c) => c !== criterion) // Remove if exists
      : [...current, criterion] // Add if new
  }
}
