import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { AcceptanceCriteriaStore } from './acceptance-criteria.store'
import { filter, take } from 'rxjs/operators'

@Component({
  selector: 'anms-acceptance-criteria-selector',
  templateUrl: './acceptance-criteria-selector.component.html',
  styleUrls: ['./acceptance-criteria-selector.component.scss']
})
export class AcceptanceCriteriaSelectorComponent implements OnInit {
  criteriaList$ = this.store.criteria$
  selectedCriteria$ = this.store.selectedCriteria$
  hasSelections$ = this.store.hasSelections$

  @Output() criteriaSelected = new EventEmitter<string[]>()

  constructor(private store: AcceptanceCriteriaStore) {}

  ngOnInit() {
    this.store.criteria$
      .pipe(
        filter((criteria) => criteria.length > 0),
        take(1)
      )
      .subscribe((criteria) => {
        // Check if there are no selected criteria when the component is initialized
        this.selectedCriteria$.pipe(take(1)).subscribe((selectedCriteria) => {
          if (selectedCriteria.length === 0) {
            this.store.toggleCriteriaSelection(criteria[0]) // Select the first criterion by default
          }
        })
      })
  }

  toggleSelection(criterion: string) {
    this.store.toggleCriteriaSelection(criterion)
  }

  saveSelection() {
    this.selectedCriteria$.pipe(take(1)).subscribe((selectedCriteria) => {
      this.criteriaSelected.emit(selectedCriteria) // Emits the current selected criteria
    })
  }

  clearSelections() {
    this.store.clearSelections()
  }
}
