import { Injectable, NgZone, Injector } from '@angular/core'
import { Router } from '@angular/router'
import {
  ofType,
  createEffect,
  Actions,
  OnInitEffects,
  concatLatestFrom
} from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { Observable, interval, of, throwError, from, EMPTY } from 'rxjs'
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mapTo,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
  timeout,
  withLatestFrom
} from 'rxjs/operators'
import { selectAuthState } from '../core.state'
import { LocalStorageService } from '../local-storage/local-storage.service'
import * as fromAuthActions from './auth.actions'
import { AuthResponse } from './auth.model'
import { selectExpirationDate, selectIsAuthenticated } from './auth.selectors'
import { AuthServiceGeneric } from './auth.service'
import { NotificationService } from '../notifications/notification.service'
import { isIntegrationEnviroment } from '../../features/authentication/helpers/isIntegration'
import { TranslateService } from '@ngx-translate/core'
import { ProfilingService } from '../profiling/profiling.service'
import { jwtDecode, JwtPayload } from 'jwt-decode'

export const AUTH_KEY = 'AUTH'
export const MILISECONDS_TO_EXPIRE = 8015000

export interface CustomJwtPayload extends JwtPayload {
  email?: string
  name?: string
  username?: string
  preferred_username?: string
}

@Injectable()
export class AuthEffects {
  updateToken = createEffect(
    () =>
      interval(21_600).pipe(
        startWith(0),
        mapTo(new Date().getHours()),
        distinctUntilChanged(),
        concatLatestFrom(() => this.store.select(selectIsAuthenticated)),
        filter(([_, authenticated]) => authenticated === true),
        map(() => fromAuthActions.refreshToken())
      ),
    { dispatch: true }
  )

  persistLoginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromAuthActions.successLogin,
          fromAuthActions.successRefreshToken,
          fromAuthActions.authSuccessLogout
        ),
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        tap(([_, authState]) => {
          this.localStorageService.setItem(AUTH_KEY, authState)
        })
      ),
    { dispatch: false }
  )

  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.authLogin),
        exhaustMap((action) =>
          this.injector
            .get(AuthServiceGeneric)
            .login(action.username, action.password)
            .pipe(
              map((resp) => {
                const decodedToken = jwtDecode<CustomJwtPayload>(
                  resp.access_token
                )
                // console.log(resp)
                const response: AuthResponse = {
                  access_token: resp.access_token,
                  refresh_token: '',
                  exp: decodedToken.exp,
                  name: decodedToken.name,
                  username: decodedToken.username,
                  email: resp.email,
                  id: decodedToken.sub,
                  isAuthenticated: true,
                  iat: decodedToken.iat
                }
                return fromAuthActions.successLogin({ response })
              }),
              catchError((error) => of(fromAuthActions.loginFailure({ error })))
            )
        )
      ),
    { dispatch: true }
  )

  failedRenewToken = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.failedRenewToken),
        tap(() => console.log('Failed to renew token')),
        map(() => fromAuthActions.authLogout())
      ),
    { dispatch: true }
  )

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.authLogout),
        tap(() => this.injector.get(AuthServiceGeneric).logout()),
        map(() => fromAuthActions.authSuccessLogout())
      ),
    { dispatch: true }
  )
  /*
  successLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.successLogin),
        tap((action) => {
          
          console.log('redirect')
          this.router.navigate(['/organizations'])
        })
      ),
    { dispatch: false }
  )*/

  successLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.successLogin),
        exhaustMap(() =>
          this.store.pipe(
            select(selectAuthState),
            tap((auth) => console.log('Auth state:', auth)), // Debugging
            filter((auth) => auth?.isAuthenticated === true),
            take(1), // Ensures we only proceed once it’s true
            timeout(5000), // Optional: Fails if it takes too long
            catchError(() => {
              console.error('Timeout: Auth state did not become true.')
              return EMPTY
            }),
            tap((auth) => {
              if (auth?.name) {
                // If the user has a name, mark the profile as completed
                this.profileService.markProfileAsCompleted()
              }
              // Navigate to /organizations after the profile check
              this.router.navigate(['/organizations'])
            })
          )
        )
      ),
    { dispatch: false }
  )
  successLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.authSuccessLogout),
        tap(() => {
          localStorage.removeItem('showHelp')
          localStorage.removeItem('ANMS-AUTH')
          localStorage.removeItem('menuStoreData')
        }),
        tap(() => this.router.navigate(['login']))
      ),
    { dispatch: false }
  )

  errorLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.loginFailure),
        tap(() => {
          localStorage.removeItem('showHelp')
          localStorage.removeItem('ANMS-AUTH')
          localStorage.removeItem('menuStoreData')
        }),
        tap((action) => {
          this.notificationService.error(
            `Error logging in. Status: ${action.error.status}: ${action.error.message}`
          )
        })
      ),
    { dispatch: false }
  )

  refreshToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.refreshToken),
        switchMap(() =>
          this.injector
            .get(AuthServiceGeneric)
            .refreshAccessToken()
            .pipe(
              switchMap((token) => {
                if (!token) return of(undefined)

                let accessToken
                if (!isIntegrationEnviroment()) {
                  const { access_token } = token as unknown as AuthResponse
                  accessToken = access_token
                } else {
                  accessToken = token
                }

                return this.injector
                  .get(AuthServiceGeneric)
                  .getUser()
                  .pipe(
                    take(1),
                    map((user) => {
                      if (!user) return undefined
                      const response: AuthResponse = {
                        access_token: accessToken,
                        exp: null, // To be set below
                        name: user.name,
                        username: user.email,
                        id: user.sub.slice(6),
                        isAuthenticated: true,
                        iat: null // To be set below
                      }

                      return this.injector
                        .get(AuthServiceGeneric)
                        .getIdTokenClaims()
                        .pipe(
                          take(1),
                          map((claims) => {
                            if (claims) {
                              response.exp = claims.exp
                              response.iat = claims.iat
                            }
                            return response
                          })
                        )
                    }),
                    switchMap((responseOrObservable) =>
                      responseOrObservable instanceof Observable
                        ? responseOrObservable
                        : of(responseOrObservable)
                    )
                  )
              }),
              map((response) => {
                if (!response) return
                return fromAuthActions.successRefreshToken({ response })
              }),
              catchError(() => {
                const errorResponse: AuthResponse = {
                  access_token: '',
                  exp: null,
                  name: '',
                  username: '',
                  id: '',
                  isAuthenticated: false,
                  iat: null
                }
                return throwError(() => errorResponse)
              })
            )
        )
      ),
    { dispatch: true }
  )

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private store: Store,
    public notificationService: NotificationService,
    public translateService: TranslateService,
    private profileService: ProfilingService,
    private injector: Injector
  ) {}
}
