import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  Inject,
  ChangeDetectorRef
} from '@angular/core'
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms'
import {
  TranslateLoader,
  TranslatePipe,
  TranslateService,
  USE_STORE
} from '@ngx-translate/core'
import { Store, select } from '@ngrx/store'
import { Observable, merge, of } from 'rxjs'
import * as fromActionsKanbanItems from '../../../features/requirements-store/store/item/requirement.actions'

import {
  UserStoryI,
  TaskI,
  StoryI,
  RequirementI,
  Story,
  Epic,
  Feature
} from '../../../features/requirements-store/store/requirements.model'

import { ART } from '../../../features/arts-store/art.model'

import { Team } from '../../../features/teams-store/store/team.model'
import { KanbanItemService } from '../../../features/requirements/services/kanban-item.service'
import * as fromFeatureSelectors from '../../../features/feature-selectors'
import { OverlayFormRef } from '../../../overlay-forms/overlay/overlay-form-ref'
import { User } from '../../../features/user-store/user.model'
import * as fromUsersSelectors from '../../../features/user-store/user.selectors'
import { HistoryAudit } from '../../../features/history-audit-store/history-audit.model'
import * as fromAuditSelectors from '../../../features/history-audit-store/history-audit.selectors'
import { Update } from '@ngrx/entity'
import * as fromKanbanSelectors from '../../../kanban/store/kanban-selector'
import {
  INIT_DATA1,
  OverlayService
} from '../../../overlay-forms/overlay/overlay.service'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { filter, startWith, switchMap } from 'rxjs/operators'
import * as fromTeamSelectors from '../../../features/teams-store/store/team.selectors'
import { AttachmentService } from '../../attachment/services/attachment.service'
import { AttachMentResponse, File } from '../../attachment/services/model'
import { StoryAcceptanceCriteriaFormComponent } from '../../ai/story-acceptance-criteria-form/story-acceptance-criteria-form.component'
import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { environment } from '../../../../environments/environment'
import { OrganizationMemberWithUser } from '../../../features/organization-store/store/organization-member.model'
import * as fromkanbanitemsSelectors from '../../../features/requirements-store/store/item/requirement.selectors'
import * as fromPortofolioSelectors from '../../../features/portfolio-store/portfolio/portfolio-safe.selectors'
import { SelectParentFormComponent } from '../feature/select-parent-form/select-parent-form.component'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  )
}

export interface StoryState {
  id: string
  name: string
}
@Component({
  selector: 'anms-user-story-form',
  templateUrl: './user-story-form.component.html',
  styleUrls: ['./user-story-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: USE_STORE, useValue: true },
    {
      provide: TranslatePipe,
      useFactory(service: TranslateService, ref: ChangeDetectorRef) {
        return new TranslatePipe(service, ref)
      },
      deps: [TranslateService, ChangeDetectorRef]
    },
    {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  ]
})
export class UserStoryFormComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize
  @ViewChild('autosize1') autosize1: CdkTextareaAutosize
  isUpdate = false
  storyId: string

  features$: Observable<RequirementI[]>
  teams$: Observable<Team[]>
  art$: Observable<ART>
  auditHistoryChanges$: Observable<HistoryAudit[]>
  teamMates$: Observable<OrganizationMemberWithUser[]>

  form: FormGroup
  userStory$: Observable<UserStoryI<any>>
  mentionsConfig: any
  key: string
  updated: Date
  created: Date
  editor$: Observable<User[]>
  userStory: StoryI
  editor
  file = null
  filesOfItem: File[]
  newFile: File
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['blockquote', 'code-block']
    ]
  }

  states$: Observable<StoryState[]>
  get team(): FormControl {
    return this.form.get('teamId') as FormControl
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: OverlayFormRef,
    private store: Store,
    private service: OverlayService,
    private itemService: KanbanItemService,
    private sanitizer: DomSanitizer,
    private attachmentService: AttachmentService,
    @Inject(INIT_DATA1)
    public data: {
      kanbanId?
      teamId?
      story?: Story
      ownerId?
      description?
      parentId?
      columnId?
      solutionId?
    }
  ) {
    this.form = this.formBuilder.group({
      itemName: ['', [Validators.required]],
      name: [''],
      description: ['', [Validators.required]],
      storyPoints: ['', [Validators.pattern(/^-?\d*$/)]],
      acceptanceCriteria: ['', [Validators.required]],
      id: [''],
      parentId: [''],
      columnId: [''],
      kanbanId: [''],
      toKanban: [''],
      locked: [''],
      solutionId: [''],
      type: '',
      prioriyId: '',
      ownerId: '',

      fileSource: [''],
      files: [''],
      notes: [''],
      teamId: '',
      state: ''
    })
  }
  onSubmit($event): void {
    if ($event.submitter.id === 'save') {
      this.save()
    }
    if ($event.submitter.id === 'saveNew') {
      this.saveNew()
    }
  }

  ngOnInit(): void {
    this.mentionsConfig = {
      items: [''], // Initially set data to empty array
      triggerChar: '@',
      mentionSelect: (item) =>
        // Customize the format of the selected mention
        `@'${item.label}'`
    }

    // this.store.dispatch(fromUserActions.loadUsers())
    this.editor$ = this.store.select(fromUsersSelectors.selectAll)

    this.form.reset()

    if ('story' in this.data) {
      //  const story: StoryI = this.data.story

      this.userStory = this.data.story
      this.auditHistoryChanges$ = this.store.select(
        fromAuditSelectors.getHistoryAuditByRequirement(this.userStory.id)
      )
      /*
      this.states$ = this.store.select(
        fromFeatureSelectors.getStatesAndColumnIdsByItemId(this.userStory.id)
      )*/
      // Subscribe to changes in teamId form control value
      const teamIdChanges$ = this.form.get('teamId').valueChanges.pipe(
        startWith('') // Emit an initial value
      )

      if (this.userStory.files) {
        this.filesOfItem = this.userStory.files
      } else {
        this.filesOfItem = []
      }

      if (this.userStory.id) {
        this.isUpdate = true
        // Merge both observables
        this.states$ = merge(
          teamIdChanges$.pipe(
            switchMap((teamId) =>
              // Select team based on teamId
              this.store.select(fromTeamSelectors.getTeamByIdF(teamId)).pipe(
                switchMap((team) =>
                  // Select states based on team's kanbanId
                  team
                    ? this.store.select(
                        fromKanbanSelectors.getStatesAndColumnIdsByKanbanId(
                          team.kanbanId
                        )
                      )
                    : of([])
                )
              )
            )
          ),
          this.store.select(
            fromFeatureSelectors.getStatesAndColumnIdsByItemId(
              this.userStory.id
            )
          )
        )
      }

      // retrieve all team mates of this organization, based on portfolio

      this.teamMates$ = this.store.pipe(
        // eslint-disable-next-line ngrx/select-style
        select(
          fromkanbanitemsSelectors.getkanbanItemByIdFactory(
            this.userStory.parentId
          )
        ),
        filter((feature) => !!feature), // Primero obtenemos el feature
        switchMap((feature: Feature) =>
          this.store.select(
            fromkanbanitemsSelectors.getkanbanItemByIdFactory(feature.parentId)
          )
        ),
        filter((parent) => !!parent),
        switchMap((epic: Epic) =>
          this.store.select(
            fromPortofolioSelectors.getPortfolioSafeById(epic.portfolioId)
          )
        ),
        switchMap((portfolio) =>
          this.store.select(
            fromFeatureSelectors.getMembersList(portfolio.organizationId)
          )
        )
      )

      this.teamMates$.subscribe((teamMembers) => {
        this.mentionsConfig.items = teamMembers.map(
          (teamMember) => teamMember.user.name
        )
      })

      if (!this.userStory.parentId) {
        this.form.get('parentId').setValue('')
      }

      this.created = this.userStory.creationDate
      this.updated = this.userStory.lastUpdate

      this.key = this.userStory.numeration

      if (
        this.userStory.parentId !== undefined &&
        this.userStory.parentId !== null
      ) {
        this.store.dispatch(
          fromActionsKanbanItems.loadKanbanitem({ id: this.userStory.parentId })
        )
        if (
          this.userStory.parentId !== null &&
          this.userStory.parentId !== undefined
        ) {
          this.form.get('parentId').disable()
        }

        this.features$ = this.itemService.getAssignedFeaturesByParent(
          this.userStory.parentId
        )
        this.teams$ = this.store.select(
          fromFeatureSelectors.getAvailableTeamsForStoriesByParent(
            this.userStory.parentId
          )
        )
      } else {
        // NO PARENT

        const teamId = this.userStory.teamId
        this.features$ = this.store.select(
          fromFeatureSelectors.selectFeaturesAssignedToTeam(teamId)
        )

        this.teams$ = this.itemService.getTeamsFromSameART(teamId)
      }

      if (
        this.userStory.ownerId !== null &&
        this.userStory.ownerId !== undefined
      ) {
        this.form.get('ownerId').disable()
      }

      if (
        this.userStory.teamId !== null &&
        this.userStory.teamId !== undefined
      ) {
        this.form.get('teamId').disable()
      }

      this.form.patchValue(this.userStory)
      if (this.form.get('description').value === '') {
        this.form.get('description').reset()
      }
    } else {
      if (this.data.parentId) {
        this.store.dispatch(
          fromActionsKanbanItems.loadKanbanitem({ id: this.data.parentId })
        )

        this.features$ = this.itemService.getAssignedFeaturesByParent(
          this.data.parentId
        )
        this.teams$ = this.store.select(
          fromFeatureSelectors.getAvailableTeamsForStoriesByParent(
            this.data.parentId
          )
        )
      } else if (this.data.teamId !== undefined && this.data.teamId !== null) {
        this.form.get('teamId').disable()

        this.features$ = this.store.select(
          fromFeatureSelectors.selectFeaturesAssignedToTeam(this.data.teamId)
        )

        this.teams$ = this.itemService.getTeamsFromSameART(this.data.teamId)
      }

      this.form.patchValue(this.createUserStory())
    }
  }
  saveNew() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()

      const callbackFunction = () => {
        this.dialogRef.close()
      }

      this.store.dispatch(
        fromActionsKanbanItems.addKanbanitem({
          kanbanitem: entity,
          callback: callbackFunction
        })
      )
      /*
      this.form.reset()
      Object.keys(this.form.controls).forEach((key) => {
        this.form.controls[key].setErrors(null)
      })
      */
    }
  }
  save() {
    if (this.form.valid) {
      const entity = this.form.getRawValue()

      if (this.isUpdate) {
        this.store.dispatch(
          fromActionsKanbanItems.upsertKanbanitem({ kanbanitem: entity })
        )
      } else {
        this.store.dispatch(
          fromActionsKanbanItems.addKanbanitem({ kanbanitem: entity })
        )

        this.isUpdate = true
      }
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }
  delete() {
    const id = this.form.value.id

    this.store.dispatch(
      fromActionsKanbanItems.deleteKanbanitem({
        id
      })
    )

    this.dialogRef.close()
  }
  /*
  returnOwner(id: string, owners: User[]) {
    for (const key in owners) {
      if (owners[key].id === id) {
        this.editor = owners[key].name
        return owners[key].name
      }
    }
  }
  */

  updateNameStory(event) {
    if (event !== null && event !== '') {
      const update: Update<RequirementI> = {
        id: this.userStory.id,
        changes: { itemName: event }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
    }
  }
  /* leave() {
    this.mouseleave.mouseLeave()
  }
  */
  changeState(event) {
    if (!this.isUpdate) return

    const id = event.value

    const teamId = this.form.get('teamId').value

    const changes: { columnId: string; teamId?: string } = { columnId: id }

    if (this.userStory.teamId === null || this.userStory.teamId === undefined) {
      changes.teamId = teamId
    }

    const update: Update<RequirementI> = {
      id: this.userStory.id,
      changes
    }

    this.store.dispatch(
      fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
    )
  }
  onHandleFile(event: any) {
    const input = event.target as HTMLInputElement
    if (input.files && input.files.length > 0) {
      this.file = input.files[0]
      this.form.patchValue({
        files: this.file
      })

      const formData = new FormData()
      formData.append('file', this.file)
      this.attachmentService
        .uploadFile(formData)
        .subscribe((data: AttachMentResponse) => {
          console.log('Respuesta del backend:', data)
          this.newFile = { ...data }
          this.filesOfItem = [...this.filesOfItem, this.newFile]

          this.form.patchValue({
            files: this.filesOfItem
          })
          const update: Update<RequirementI> = {
            id: this.userStory.id,
            changes: { files: this.filesOfItem }
          }

          this.store.dispatch(
            fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
          )
        })
    }
  }
  downLoadFile(url: string) {
    window.location.href = url
  }
  generateAcceptanceCriteria(
    origin: HTMLElement,
    description: string,
    itemName: string,
    nonFunctionalRequirements?: string
  ) {
    this.dialogRef = this.service.open(
      {
        height: window.innerHeight * 0.95,
        width: window.innerWidth * 0.95,
        maxWidth: '50%',
        /** Position overrides. */
        position: { right: '0' },
        hasBackdrop: false
        // panelClass: 'dialog-slide-in'
      },
      {
        origin,
        data: {
          description: description,
          itemName: itemName,

          parentComponent: this
        },
        content: StoryAcceptanceCriteriaFormComponent
      }
    )
  }
  stripHtml(html) {
    if (html == null) {
      return '' // Return an empty string if the input is null or undefined
    }
    const regex = /<[^>]*>?/gm
    return html.replace(regex, '')
  }

  deleteFile(id) {
    const index = this.filesOfItem.findIndex((file) => file.id === id)
    const filesArray = [...this.filesOfItem]
    if (index !== -1) {
      filesArray.splice(index, 1)
      const update: Update<RequirementI> = {
        id: this.userStory.id,
        changes: { files: filesArray }
      }

      this.store.dispatch(
        fromActionsKanbanItems.patchKanbanitem({ kanbanitem: update })
      )
      this.filesOfItem = filesArray
      this.form.patchValue({
        files: this.filesOfItem
      })
    }
  }
  setAcceptanceCriteria(acceptanceCriteria: any) {
    if (typeof acceptanceCriteria === 'object' && 'ops' in acceptanceCriteria) {
      // If Quill delta format is used, extract only text
      this.form
        .get('acceptanceCriteria')
        .setValue(acceptanceCriteria.ops.map((op) => op.insert).join(''))
    } else {
      // Otherwise, assume it's already a string (HTML or plain text)
      this.form.get('acceptanceCriteria').setValue(acceptanceCriteria)
    }
  }
  private createUserStory(): UserStoryI<TaskI> {
    return {
      id: '',
      code: '',
      acceptanceCriteria: '',
      columnId: this.data.columnId ?? null,
      description: this.data.description != null ? this.data.description : '',
      kanbanId: this.data.kanbanId ?? null,
      itemName: '',
      locked: false,
      name: 'userstory',
      solutionId: this.data.solutionId ?? null,
      type: 'business',
      ownerId: '',
      priorityId: null,
      parentId: this.data.parentId != null ? this.data.parentId : '',
      toKanban: true,
      storyPoints: 0,
      notes: '',
      inRoadmap: false,
      teamId: this.data.teamId != null ? this.data.teamId : ''
    }
  }

  changeParentDialogRef(origin, features: Feature[]) {
    {
      const form: OverlayFormRef = this.service.open(
        { height: 350, width: 650 },
        {
          content: SelectParentFormComponent,
          origin,
          data: { story: this.userStory, features: features }
        }
      )
    }
  }
  onAcceptanceCriteriaSaved(description: string): void {
    console.log('Saved description:', description)
    // Optionally sanitize and update view mode, etc.
    //  this.sanitizedDescription = this.sanitizer.bypassSecurityTrustHtml(description);
  }
  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.form.get('acceptanceCriteria')?.value
    )
  }
}
