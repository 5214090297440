import { createAction, props } from '@ngrx/store'
import { Update } from '@ngrx/entity'

import { Page, PageRequest } from '../../../../shared/paginated/page'
import {
  ItemQuery,
  Operation,
  PatchMany
} from '../../../requirements/services/kanban-item.service'
import { Relation, RequirementI } from '../requirements.model'

export const loadAllKanbanitems = createAction(
  '[Kanbanitem/API] Load Kanbanitems'
)
export const loadChildrenKanbanitems = createAction(
  '[Kanbanitem/API] Load Children Kanbanitems',
  props<{ parentId: string }>()
)

export const loadKanbanitemsPaginated = createAction(
  '[Kanbanitem/API] Load Kanbanitems Paginated',
  props<{ query: ItemQuery; pageRequest: PageRequest<RequirementI> }>()
)
export const loadKanbanitem = createAction(
  '[Kanbanitem/API] Load Kanbanitem',
  props<{ id: string }>()
)

export const loadKanbanitems = createAction(
  '[Kanbanitem/API] Load Kanbanitems by Id',
  props<{ ids: string[] }>()
)
export const loadKanbanitemsSingleFetch = createAction(
  '[Kanbanitem/API] Load Kanbanitems by Id Single Fetch',
  props<{ ids: string[] }>()
)
export const loadKanbanitemsByColumnId = createAction(
  '[Kanbanitem/API] Load Kanbanitems by Column Id',
  props<{ columnId: string }>()
)
export const loadKanbanitemsByKanbanColumns = createAction(
  '[Kanbanitem/API] Load Kanbanitems by Columns Id',
  props<{ ids: string[] }>()
)

/* export const loadKanbanitemsByPIId = createAction(
  '[Kanbanitem/API] Load Kanbanitems by PI Id',
  props<{ piId: string }>()
)*/
export const loadKanbanitemsByPIId = createAction(
  '[Kanbanitem/API] Load Kanbanitems by PI Id',
  props<{ piId: string[] }>()
)
export const successLoadKanbanitemsSet = createAction(
  '[Kanbanitem/API] Success Load Kanbanitems Set',
  props<{ kanbanitems: RequirementI[] }>()
)

export const loadChildrenOfList = createAction(
  '[Kanbanitem/API] Load Children of List',
  props<{ kanbanitems: RequirementI[] }>()
)
export const loadChildrenOfItem = createAction(
  '[Kanbanitem/API] Load Children of Item',
  props<{ kanbanitem: RequirementI }>()
)

export const successLoadKanbanitem = createAction(
  '[Kanbanitem/API] Success Load Kanbanitem',
  props<{ kanbanitem: RequirementI }>()
)
export const successLoadPagedKanbanitems = createAction(
  '[Kanbanitem/API] Success Load Paged Kanbanitems',
  props<{ page: Page<RequirementI> }>()
)

export const successLoadKanbanitems = createAction(
  '[Kanbanitem/API] Success Load Kanbanitems',
  props<{ kanbanitems: RequirementI[] }>()
)

export const successPagingKanbanItems = createAction(
  '[Kanbanitem/API] Success Paging Kanbanitems',
  props<{ kanbanItemsPage: Page<RequirementI> }>()
)
export const failedLoadKanbanitems = createAction(
  '[Kanbanitem/API] Failed Load Kanbanitems',
  props<{ error: string }>()
)
export const actionError = createAction(
  '[Kanbanitem/API] Action Error Kanbanitem',
  props<{ error: string }>()
)

export const successAddKanbanitem = createAction(
  '[Kanbanitem/API] Success Add Kanbanitem',
  props<{ kanbanitem: RequirementI; callback?: () => void }>()
)
export const successUpdateKanbanitem = createAction(
  '[Kanbanitem/API] Success Update Kanbanitem',
  props<{ kanbanitem: RequirementI }>()
)

export const successDeleteKanbanitem = createAction(
  '[Kanbanitem/API] Success Delete Kanbanitem',
  props<{ id: string }>()
)
export const deleteKanbanitems = createAction(
  '[Kanbanitem/API] Delete Kanbanitems',
  props<{ ids: string[] }>()
)

export const addKanbanitem = createAction(
  '[Kanbanitem/API] Add Requirement',
  props<{ kanbanitem: RequirementI; callback?: () => void }>()
)

export const upsertKanbanitem = createAction(
  '[Kanbanitem/API] Upsert Requirement',
  props<{ kanbanitem: RequirementI }>()
)

export const addKanbanitems = createAction(
  '[Kanbanitem/API] Add Requirements',
  props<{ kanbanitems: RequirementI[] }>()
)

export const upsertKanbanitems = createAction(
  '[Kanbanitem/API] Upsert Requirements',
  props<{ kanbanitems: RequirementI[] }>()
)

export const updateKanbanitem = createAction(
  '[Kanbanitem/API] Update Requirement',
  props<{ kanbanitem: Update<RequirementI> }>()
)
export const patchKanbanitem = createAction(
  '[Kanbanitem/API] Patch Requirement',
  props<{
    kanbanitem: Update<RequirementI>
    op?: Operation
  }>()
)

export const patchManyKanbanitems = createAction(
  '[Kanbanitem/API] Patch Many Kanbanitems',
  props<{
    items: PatchMany
  }>()
)
export const successPatchManyKanbanitems = createAction(
  '[Kanbanitem/API] Success Patch Many Kanbanitems',
  props<{
    modifiedCount: number
    items: PatchMany
  }>()
)

export const successPatchKanbanitem = createAction(
  '[Kanbanitem/API] Success Patch Kanbanitem',
  props<{
    kanbanitem: RequirementI
  }>()
)

export const updateKanbanitems = createAction(
  '[Kanbanitem/API] Update Kanbanitems',
  props<{ kanbanitems: Update<RequirementI>[] }>()
)

export const deleteKanbanitem = createAction(
  '[Kanbanitem/API] Delete Kanbanitem',
  props<{ id: string }>()
)

export const askCode = createAction(
  '[Kanbanitem/API] Ask Code Kanbanitem',
  props<{ source: string }>()
)
export const askCodeSuccess = createAction(
  '[Kanbanitem/API] Ask Code Success Kanbanitem',
  props<{ code: string }>()
)

export const clearKanbanitems = createAction(
  '[Kanbanitem/API] Clear Kanbanitems'
)

export const loadAllEpicsByUser = createAction(
  '[Kanbanitem/API] Load all Epics of all organizations that User Belongs'
)

export const errorDeleteKabanItem = createAction(
  '[Kanbanitem/API] Error Delete a Item ',
  props<{ error }>()
)
export const addRelatedItem = createAction(
  '[Kanbanitem/API] Add a linked Item to an Item ',
  props<{ relation: Relation; id: string }>()
)
export const removeRelatedItem = createAction(
  '[Kanbanitem/API] Remove linked Item from an Item ',
  props<{ id: string; relationId: string }>()
)

export const loadKanbanItemsByKeyResults = createAction(
  '[Kanbanitem/API] Load Items by Key Result Ids',
  props<{
    ids: any[]
  }>()
)

export const addComment = createAction(
  '[Comments] Add Comment',
  props<{ content: string; itemId: string; userId: string }>()
)

export const addCommentSuccess = createAction(
  '[Comments] Add Comment Success',
  props<{ kanbanitem: RequirementI }>()
)
