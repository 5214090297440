import { createSelector } from '@ngrx/store'
import * as jsonPath from 'jsonpath/jsonpath'

import {
  FeatureI,
  KanbanItemI,
  StoryI,
  UserStoryC
} from '../../features/requirements-store/store/requirements.model'

import {
  Column,
  isPortfolioKanban,
  isTeamKanban,
  Kanban,
  KanbanColumn,
  KanbanType,
  PortfolioKanban,
  TeamKanban
} from './kanban.model'
import { adapter } from './kanban.reducer'

import { FeaturesState, selectFeatures } from '../../features/features.state'
import { Dictionary } from '@ngrx/entity'

export const selectKanbans = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.kanbans
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectKanbans)

export const getKanbanGByPropertyValue = createSelector(
  selectAll,
  (
    entities,
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) => {
    // console.log('column', columnId);
    // console.log('value', columnValue);
    // console.log('entities', entities);
    const entity = entities.filter((item) => item[columnId] === columnValue)

    return entity
  }
)

export const getKanbanGByPropertyValue1 = createSelector(
  selectAll,
  (
    kanbans,
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) => {
    // console.log('column', columnId);
    // console.log('value', columnValue);
    // console.log('entities', kanbans);
    const ent = kanbans.filter((item) => item[columnId] === columnValue)
    // console.log(ent);
    return Object.assign({}, ...ent)
  }
)

export const getStates = (id: string) =>
  createSelector(selectEntities, (entities: Dictionary<Kanban>) =>
    // entities[id].columns.map((item: { name: any }) => item.name)
    {
      const kanban = entities[id]
      const arrayOut = []

      kanban.columns.forEach((item) => {
        arrayOut.push(item.name)
      })

      return arrayOut
    }
  )
export const getStatesAndColumnIdsByKanbanId = (id: string) =>
  createSelector(selectEntities, (entities: Dictionary<Kanban>) =>
    // entities[id].columns.map((item: { name: any }) => item.name)
    {
      const kanban = entities[id]
      const arrayOut = []
      if (kanban) {
        kanban.columns.forEach((column) => {
          arrayOut.push({ name: column.name, id: column.id })
        })
      }
      return arrayOut
    }
  )

export const getStateNameByColumnId = (kanbanId: string, columnId: string) =>
  createSelector(selectEntities, (entities: Dictionary<Kanban>) =>
    // entities[id].columns.map((item: { name: any }) => item.name)
    {
      const kanban = entities[kanbanId]

      if (kanban) {
        for (const key in kanban.columns) {
          if (columnId === kanban.columns[key].id) {
            return kanban.columns[key].name
          }
        }
        // const col=kanban.columns.find(column=>column.id===columnId)
        // return col.name
      }
    }
  )

export const getKanbanGById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)
export const selectKanban = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getKanbanArrayById = createSelector(
  selectAll,
  (entities: Kanban[], props) => entities[props.id]
)
export const getKanbansByType = (type: KanbanType) =>
  createSelector(selectAll, (entities: Kanban[]) =>
    entities.filter((entity) => entity.type === type)
  )
export const getKanbanByIdNoProps = () =>
  createSelector(selectAll, (entities: Kanban[], { id }: { id: string }) =>
    entities.find((entity) => entity.id === id)
  )

export const getKanbanByColumnId = (columnId) =>
  createSelector(selectEntities, (entities: Dictionary<Kanban>) =>
    Object.values(entities).find((entity) =>
      Object.values(entity.columns).some((col) => col.id === columnId)
    )
  )

export const getKanbanByIdM = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

/*
export const getKanbanById = createSelector(
  selectE,
  (entities: Kanban[], props) => {
    const kanban = entities.find((entity) => entity.id === props.id)
    if (kanban !== undefined) {
      if (kanban.type === 'portfolio') {
        return kanban as PortfolioKanban
      } else if (kanban.type === 'program') {
        return kanban as ProgramKanban
      } else if (kanban.type === 'team') {
        return kanban as TeamKanban
      } else {
        return null
      }
    } else {
      return undefined
    }
  }
)
*/
export const getKanbanByIdNoType = createSelector(
  selectAll,
  (entities: Kanban[], props) =>
    entities.find((entity) => entity.id === props.id)
)
export const getKanbanGBySolutionId = createSelector(
  selectAll,
  (entities, { solutionId }: { solutionId: string }) =>
    entities.filter((kanban) => kanban.solutionId === solutionId)
)

/*
export const getKanbanGByportfolioId = createSelector(
  selectAll,
  (entities, { portfolioId }: { portfolioId: string }) => {
    return Object.assign(
      {},
      ...entities.filter((kanban) => kanban['portfolioId'] === portfolioId)
    )
  }
)
*/
export const selectAllPortfolioKanbans = () =>
  createSelector(
    selectAll,
    (entities: Kanban[]) =>
      entities.filter((item) =>
        isPortfolioKanban(item)
      ) as unknown as PortfolioKanban[]
  )

export const selectKanbanListFromIds = (ids: string[]) =>
  createSelector(selectAll, (entities: Kanban[]) =>
    entities.filter((item) => ids.some((id) => item.id === id))
  )

export const selectAllTeamKanbans = () =>
  createSelector(
    selectAll,
    (entities: Kanban[]) =>
      entities.filter((item) => isTeamKanban(item)) as unknown as TeamKanban[]
  )

// export const selectKanbanId = selectRouteParam('id')
/*
export const selectSelectedPortfolioKanban = createSelector(
  selectEntities,
  selectCurrentRoute,
  (entities, params) => {

    if (params.routeConfig.path.indexOf('portfolio-kanban/:id') > -1) {
      return entities[params.params.id]
    } else {
      return
    }
  }
)

export const selectSelectedProgramKanban = createSelector(
  selectEntities,
  selectCurrentRoute,
  (entities, params) => {
    if (params.routeConfig.path.indexOf('program-kanban/:id') > -1) {
      return entities[params.params.id]
    } else {
      return
    }
  }
)
*/
/*
export const selectSelectedKanban = createSelector(
  fromKanbanSelects.selectEntities,
  selectKanbanId,
  (entities, params) => entities[params]
);
*/
/*
export const selectorKanbans = createSelector(selectEntities, (entities) => {
  const kan: Kanbantype[] = []

  Object.values(entities).forEach((kanban) => {
    if (kanban.type === 'portfolio') {
      const kanbancolumns: Column<KanbanItemI>[] = []
      kanban.columns.forEach((col) => {
        const colout = new Column(col as KanbanColumn<KanbanItemI>)
        kanbancolumns.push(colout)
      })
      const p = new PortfolioKanban(
        kanban.id,
        kanban.name,
        kanban['portfolioId'],
        kanbancolumns
      )

      kan.push(p)
    } else if (kanban.type === 'program') {
      const kanbancolumns: Column<KanbanItemI>[] = []
      kanban.columns.forEach((col) => {
        const ad: KanbanColumn<KanbanItemI> = {
          id: col.id,
          description: col.description,
          name: col.name,
          tasks: [],
          dropData: col.dropData,
          items: []
        }
        const colout = new Column<FeatureI<StoryI>>(ad)

        // const colout = new Column(col as KanbanColumn<KanbanItemI>)
        kanbancolumns.push(colout)
      })
      const p1 = new ProgramKanban(
        kanban.id,
        kanban.name,
        kanban['portfolioId'],
        kanban['ARTId'],
        kanbancolumns
      )
      kan.push(p1)
    } else if (kanban.type === 'team') {
      const kanbancolumns: Column<StoryI>[] = []
      kanban.columns.forEach((col) => {
        const ad: KanbanColumn<KanbanItemI> = {
          id: col.id,
          description: col.description,
          name: col.name,
          tasks: [],
          dropData: col.dropData,
          items: []
        }

        const colout = new Column<StoryI>(ad)
        // const colout = new Column<StoryI>(col as KanbanColumn<StoryI>)
        kanbancolumns.push(colout)
      })
      const p1 :TeamKanban= {
        id:kanban.id,
        name:kanban.name,
       columns: kanbancolumns}


      kan.push(p1)
    }
  })
  return kan
})
*/
/*
export const selectorKanbanJoinItems = createSelector(
  selectorKanbans,
  fromKanbanItemSelectors.selectorKanbanItems,
  fromPortfolioSelectors.selectPortfoliosArray,
  (
    kanbans1: Kanbantype[],
    kanbanitems: Kanbanitem[],
    portfolioSafe: PortfolioSafe[]
  ) => {
    // console.log('aqui kanbans', kanbanitems);
    // console.log('aqui portfolios', portfolioSafe);
    const kanbanitemsKanban = []
    const portfolio: PortfolioSafe[] = []
    // console.log('items to push', kanbanitems);
    portfolioSafe.map((safe) => {
      const kanbansout: Kanbantype[] = []
      kanbans1
        .filter((kanban) => kanban['portfolioId'] === safe.id)
        .map((kanban) => {
          const cols = []
          kanban.columns?.forEach((col) => {
            // console.log('aqui hay uno', col);
            // const column = col as Column<Kanbanitem>;

            const itemsOut = kanbanitems.filter(
              (item) => item.columnId !== null && item.columnId === col.id
            )

            cols.push({
              id: col.id,
              description: col.description,
              name: col.name,
              dropData: col.dropData,
              items: itemsOut
            })
          })
          kanban.columns = cols
          kanbansout.push(kanban)
        })
      portfolio.push({
        id: safe.id,
        description: safe.description,
        name: safe.name,
        manager: safe.manager,
        ownerId: safe.ownerId,
        kanbans: kanbansout,
        kanbanId: safe.kanbanId,
        roadMapId: safe.roadMapId,
        sponsor: safe.sponsor
      })
    })
    // console.log('portoflio', portfolio);
    return portfolio
  }
)
export const selectorPortfolioKanbanJoinEpics = createSelector(
  selectorKanbanJoinItems,
  (safes: PortfolioSafe[], props) => {
    const b = safes
      .filter((entity) => entity.id === props.portfolioId)
      .map((portfolioSafe) =>
        portfolioSafe.kanbans.find((kanban) => kanban.type === 'portfolio')
      )
    // console.log('selector', b[0]);
    return b[0] as PortfolioKanban
  }
)
*/
/*
export const selectorProgramKanbanJoinEpicsId = createSelector(
  selectorKanbanJoinEpics,
  (safe: PortfolioSafe[], props) => {
    // console.log(safe);
    const b = safe
      .find((entity) => entity.id === props.portfolioId)
      .kanbans?.find((kanban) => kanban.type === 'program')

    return b as ProgramKanban
  }
)
*/
/*
export const selectorProgramKanbanJoinItemsByProgramId = createSelector(
  selectAll,
  fromKanbanItemSelectors.selectorKanbanItems,
  (kanbans: Kanbantype[], items: Kanbanitem[], props) => {
    const kanbansout = { ...kanbans.find((entity) => entity.id === props.id) }

    const cols = []
    kanbansout.columns?.forEach((col) => {
      const itemsOut = items.filter(
        (item) => item.columnId !== null && item.columnId === col.id
      )

      cols.push({
        id: col.id,
        description: col.description,
        name: col.name,
        dropData: col.dropData,
        items: itemsOut
      })
    })
    kanbansout.columns = cols

    return kanbansout as ProgramKanban
  }
)*/

/*
export const selectorProgramKanbanJoinEpicsId = createSelector(
  selectorKanbanJoinEpics,
  (entities, props) => {
    //console.log("entoty",entities);
    let d;
    entities
      .filter(entity => entity['id'] === props.portfolioId)
      .map(value => {
        const a = value as PortfolioSafe;
        //console.log("entoty",a);
        d = Object.assign(
          {},
          ...a.kanbans.filter(kanban => kanban.type === 'program')
        ) as Kanban;
        //console.log("entoty",c);
        //d = c as Kanban;
        //console.log("kanban",d);
      });
    return d;
  }
);
}
*/
/*
export const selectorPortfolioKanbanJoinKanbanItems = createSelector(
  selectorKanbanJoinItems,
  (portfoliosList, props) => {
    // console.log("storeEpics",portfoliosList);
    // console.log("store",props);

    const portfolioSAFE: PortfolioSafe = portfoliosList.find(
      (portfolio) => portfolio.id === props.porfolioSAFEId
    )

    // ONLY ONE KANBAN PORFOLIO PER SAFE

    return portfolioSAFE.kanbans.find(
      (kanban) => kanban.type === 'portfolio'
    ) as PortfolioKanban
  }
)
export const selectorAllPortfolioKanbanJoinKanbanItems = createSelector(
  selectorKanbanJoinItems,
  (portfoliosList) => {
    const outP: PortfolioSafe[] = []

    portfoliosList.forEach((portfolioSAFE) => {
      const out2: Kanbantype[] = []

      portfolioSAFE.kanbans.forEach((kanban) => {
        if (kanban.type === 'portfolio') {
          out2.push(kanban)
          const pd: PortfolioSafe = {
            description: portfolioSAFE.description,
            id: portfolioSAFE.id,
            kanbans: out2,
            name: portfolioSAFE.name,
            ownerId: portfolioSAFE.ownerId,
            manager: portfolioSAFE.manager,
            kanbanId: portfolioSAFE.kanbanId,
            roadMapId: portfolioSAFE.roadMapId,
            sponsor: portfolioSAFE.sponsor
          }
          // pd.kanbans.push(...out2);
          outP.push(pd)
        }
      })
    })

    // console.log("1",outP);
    return outP
  }
)

export const selectorAllProgramKanbanJoinKanbanItems = createSelector(
  selectorKanbanJoinItems,
  (portfoliosList) => {
    const outP: PortfolioSafe[] = []

    portfoliosList.forEach((portfolioSAFE) => {
      const out2: Kanbantype[] = []
      // kanbans?:
      portfolioSAFE.kanbans.forEach((kanban) => {
        if (kanban.type === 'program') {
          out2.push(kanban)
          const pd: PortfolioSafe = {
            description: portfolioSAFE.description,
            id: portfolioSAFE.id,
            kanbans: out2,
            name: portfolioSAFE.name,
            ownerId: portfolioSAFE.ownerId,
            manager: portfolioSAFE.manager,
            kanbanId: portfolioSAFE.kanbanId,
            roadMapId: portfolioSAFE.roadMapId,
            sponsor: portfolioSAFE.sponsor
          }

          outP.push(pd)
        }
      })
    })

    // console.log("program",outP);
    return outP
  }
)

export const selectorProgramKanbanWithItemsFilterByKanbanId = createSelector(
  selectorKanbanJoinItems,
  (portfoliosList, props) => {
    const outP: PortfolioSafe[] = []

    portfoliosList.forEach((portfolioSAFE) => {
      const out2: Kanbantype[] = []
      // kanbans?:
      portfolioSAFE.kanbans.forEach((kanban) => {
        if (kanban.type === 'program') {
          out2.push(kanban)
          const pd: PortfolioSafe = {
            description: portfolioSAFE.description,
            id: portfolioSAFE.id,
            kanbans: out2,
            name: portfolioSAFE.name,
            ownerId: portfolioSAFE.ownerId,
            kanbanId: portfolioSAFE.kanbanId,
            roadMapId: portfolioSAFE.roadMapId,
            manager: portfolioSAFE.manager,
            sponsor: portfolioSAFE.sponsor
          }

          outP.push(pd)
        }
      })
    })

    // console.log("program",outP);
    return outP
  }
)
export const selectorFilteredKanbanJoinKanbanItems = createSelector(
  selectorKanbanJoinItems,
  (
    portfoliosList: PortfolioSafe[],
    props: { name: string; solutionId: string }
  ) => {
    const outP: PortfolioSafe[] = []

    portfoliosList.forEach((portfolioSAFE) => {
      const out2: Kanbantype[] = []

      portfolioSAFE.kanbans.forEach((kanban) => {
        if (kanban.type === props.name) {
          out2.push(kanban)
          const pd: PortfolioSafe = {
            description: portfolioSAFE.description,
            id: portfolioSAFE.id,
            kanbans: out2,
            name: portfolioSAFE.name,
            ownerId: portfolioSAFE.ownerId,
            kanbanId: portfolioSAFE.kanbanId,
            roadMapId: portfolioSAFE.roadMapId,
            manager: portfolioSAFE.manager,
            sponsor: portfolioSAFE.sponsor
          }
          // pd.kanbans.push(...out2);
          outP.push(pd)
        }
      })
    })

    // console.log("1",outP);
    return outP
  }
)
export const selectorPortfolioKanbanJoinEKanbanItems = createSelector(
  selectorKanbanJoinItems,
  (portfoliosList, porfolioSAFE: PortfolioSafe) => {
    // console.log("store",portfoliosList);
    // console.log("store",porfolioSAFE);

    const portfolioSAFE: PortfolioSafe = portfoliosList.find(
      (portfolio) => portfolio.id === porfolioSAFE.id
    )

    // ONLY ONE KANBAN PORFOLIO PER SAFE

    return portfolioSAFE.kanbans.find(
      (kanban) => kanban.type === 'portfolio'
    ) as PortfolioKanban
  }
)
export const selectorProgramKanbanJoinKanbanItems = createSelector(
  selectorKanbanJoinItems,
  fromPortfolioSelectors.getSelectedPortfolioSAFEFromStore,
  (portfoliosList, porfolioSAFE: PortfolioSafe) => {
    const portfolioSAFE: PortfolioSafe = portfoliosList.find(
      (portfolio) => portfolio.id === porfolioSAFE.id
    )

    // ONLY ONE KANBAN PORFOLIO PER SAFE
    return portfolioSAFE.kanbans.find(
      (kanban) => kanban.type === 'program'
    ) as ProgramKanban
  }
)

export const selectorPortfolioKanbanFilterBySolution = createSelector(
  selectorAllPortfolioKanbanJoinKanbanItems,
  (portfolioSAFEList: PortfolioSafe[], props: any) => {
    let indexPortfolio = 0
    const kanbansout: PortfolioKanban[] = []

    // const a = JSON.stringify(portfolioSAFEList);
    // console.log(a);
    const names = jsonPath.query(
      portfolioSAFEList,
      "$..kanbans..columns..items[?(@.solutionId=='" + props.solutionId + "')]"
    )
    // console.log("names",names);

    // const JsonPath: any;
    // use it like
    // sonPath().JSONPath(
    //  '$.kanbans.columns.[?(@items==' + props.solutionId + ')]',
    //  a
    // );

    // console.log(portfolioSAFEList);

    portfolioSAFEList.forEach((portfolioSAFE, index) => {
      portfolioSAFE.kanbans.forEach((kanban) => {
        const cols = []
        // console.log("kanban",kanban);

        kanban.columns.forEach((col) => {
          const itemsOut = col.items.filter((item) => {
            const b: Kanbanitem = {
              ...item
            }
            if (b !== undefined) {
              if (b.solutionId === props.solutionId) {
                return item
              }
            }
          })
          if (itemsOut !== undefined) {
            indexPortfolio = index
          }
          const ad: KanbanColumn<KanbanItemI> = {
            id: col.id,
            description: col.description,
            name: col.name,
            tasks: [],
            dropData: col.dropData,
            items: itemsOut
          }
          const a = new Column<FeatureI<StoryI>>(ad)
          cols.push(a)
        })

        kanbansout.push(
          new PortfolioKanban(
            kanban.id,
            kanban.name,
            kanban['portfolioId'],
            cols,
            kanban['initialcolumnId']
          )
        )
      })
    })

    return kanbansout[indexPortfolio]
  }
)

export const selectorProgramKanbanFilterBySolution = createSelector(
  selectorAllProgramKanbanJoinKanbanItems,
  (portfolioSAFEList: PortfolioSafe[], props: any) => {
    const names = jsonPath.query(
      portfolioSAFEList,
      "$..kanbans..columns..items[?(@.solutionId=='" + props.solutionId + "')]"
    )

    let indexPortfolio = 0
    const kanbansout: ProgramKanban[] = []
    portfolioSAFEList.forEach((portfolioSAFE, index) => {
      portfolioSAFE.kanbans.forEach((kanban) => {
        const cols: KanbanColumn<FeatureI<StoryI>>[] = []
        kanban.columns.forEach((col) => {
          const itemsOut = col.items.filter(
            (item) => item['solutionId'] === props.solutionId
          ) as FeatureI<StoryI>[]
          if (itemsOut !== undefined) {
            indexPortfolio = index
          }

          const ad: KanbanColumn<KanbanItemI> = {
            id: col.id,
            description: col.description,
            name: col.name,
            tasks: [],
            dropData: col.dropData,
            items: itemsOut
          }
          const a = new Column<FeatureI<StoryI>>(ad)
          cols.push(a)
        })

        kanbansout.push(
          new ProgramKanban(
            kanban.id,
            kanban.name,
            kanban['portfolioId'],
            kanban['ARTId'],
            cols,
            kanban['initialcolumnId']
          )
        )
      })
    })
    // console.log("dsf",kanbansout[indexPortfolio]);
    return kanbansout[indexPortfolio]
  }
)

export const selectorKanbanFilterBySolution = createSelector(
  selectorFilteredKanbanJoinKanbanItems,
  (
    portfolioSAFEList: PortfolioSafe[],
    props: { name: string; solutionId: string }
  ) => {
    const kanbansout: PortfolioKanban[] = []
    portfolioSAFEList.forEach((portfolioSAFE) => {
      portfolioSAFE.kanbans.forEach((kanban) => {
        const cols: KanbanColumn<KanbanItemI>[] = []
        kanban.columns.forEach((col) => {
          const itemsOut = col.items.filter(
            (item) => item['solutionId'] === props.solutionId
          )

          const ad: KanbanColumn<KanbanItemI> = {
            id: col.id,
            description: col.description,
            name: col.name,
            tasks: [],
            dropData: col.dropData,
            items: itemsOut
          }

          cols.push(new Column(ad))
        })

        kanbansout.push(
          new PortfolioKanban(
            kanban.id,
            kanban.name,
            kanban['portfolioId'],
            cols,
            kanban['initialcolumnId']
          )
        )
      })
    })

    return kanbansout[0]
  }
)

export const selectorTeamKanbanByIdJoinItems = createSelector(
  selectorKanbans,
  fromKanbanItemSelectors.selectorKanbanItems,
  (kanbans1: Kanbantype[], kanbanitems: KanbanItemI[], props) => {
    // console.log('aqui kanbans', kanbanitems);
    // console.log('aqui portfolios', portfolioSafe);

    // console.log('items to push', kanbanitems)

    const kanbansout = kanbans1.find(
      (kanban) => kanban.type === 'team' && kanban['teamId'] === props.teamId
    ) as TeamKanban

    // console.log(kanbansout)
    kanbansout.columns?.forEach((col) => {
      const itemsOut = kanbanitems.filter((item) => item.columnId === col.id)
      // console.log(itemsOut)
      // const col1 = { col, items: { ...itemsOut } }
      col.items.push(...itemsOut)
    })
    // kanban.columns = cols
    // kanbansout.push(kanban)
    return kanbansout as TeamKanban

    // console.log('portoflio', portfolio);
  }
)
*/
