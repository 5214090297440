import { AuthState } from './auth.models'
import * as fromAuthActions from './auth.actions'
import { createReducer, on, Action } from '@ngrx/store'

export const initialState: AuthState = {
  isAuthenticated: false,
  expirationDate: null,
  username: null,
  id: null,
  accessToken: null,
  issuedDate: null,
  email: null,
  name: null,
  sub: null,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  refresh_token: null
}

const reducer = createReducer<AuthState>(
  initialState,
  // eslint-disable-next-line ngrx/on-function-explicit-return-type
  on(fromAuthActions.successLogin, (state, action) => ({
    ...state,
    isAuthenticated: true,
    username: action.response.username,
    name: action.response.name,
    id: action.response.id,
    accessToken: action.response.access_token,
    email: action.response.email,
    sub: action.response.sub,
    audience: action.response.audience,
    issuedDate: new Date(action.response.iat * 1000),
    expirationDate: new Date(action.response.exp * 1000)
  })),
  // on(authLogin, state => ({ ...state, isAuthenticated: true })),
  on(
    fromAuthActions.authSuccessLogout,
    (state): AuthState => ({
      isAuthenticated: false,
      username: '',
      id: '',
      accessToken: '',
      audience: '',
      expirationDate: null,
      issuedDate: null,
      email: '',
      name: '',
      sub: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      refresh_token: ''
    })
  ),
  on(
    fromAuthActions.successRefreshToken,
    (state, action): AuthState => ({
      isAuthenticated: true,
      username: action.response.username,
      id: action.response.id,
      accessToken: action.response.access_token,
      audience: action.response.audience,
      email: action.response.email,
      sub: action.response.sub,
      name: action.response.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      issuedDate: new Date(Date.now()),
      expirationDate: new Date(action.response.exp * 1000),
      refresh_token: ''
    })
  )
)

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action)
}
