<div>
    <!-- Conditionally display the callback message -->
    <p>callback works!</p>
</div>

<div *ngIf="errorMessage$ | async as errorMessage" class="info-message">
    <div class="error-message">
        {{errorMessage.message}}
        <br>
        If you think this is an error send us a message to <a
            href="mailto:info@kvasar.tech?subject=KVASAR Login Error">info&#64;kvasar.tech</a>
    </div>
    <div>

    </div>
    <p>
        To ensure the security and authenticity of your account, we kindly
        request you to verify your email address.
    </p>
    <p>
        This verification step is essential in completing your
        registration process and unlocking the full range of features and
        benefits that KVASAR has to offer.
    </p>
    <p>To verify your email, please follow these simple steps:</p>
    <ul>
        <li>
            Check your email inbox or spam folder for a message from KVASAR.
        </li>
        <li>Open the verification email.</li>
        <li>
            Click on the provided verification link or copy and paste it
            into your web browser.
        </li>
        <li>
            You will be redirected to a confirmation page, confirming that
            your email has been successfully verified.
        </li>
    </ul>
    <p>Having Trouble Confirming Your Account?</p>
    <p>
        Check your spam folder or click <a (click)="resendVerificationTicket(errorMessage.email,origin3)" #origin3
            style="cursor: pointer;padding-left: 10px;padding-right: 10px;">here</a>
        to resend the confirmation email.
    </p>
    <p> Complete the process within 5 days</p>
    <p>

        Need help? Contact our support team at <a href="mailto:info@kvasar.tech">info&#64;kvasar.tech</a>
    </p>


</div>

