import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromOpenAIActions from '../../../features/openai/open-ai.actions'
import { OverlayFormRef } from '../../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../../overlay-forms/overlay/overlay.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  GenerateAcceptanceCriteriaRequest,
  GenerateFeatureAcceptanceCriteriaRequest,
  OpenAIStore
} from '../../../features/openai/open-ai.model'
import { Observable, Subscription } from 'rxjs'
import { FeatureFormComponent } from '../../requirement-forms/feature/feature-form/feature-form.component'
import { ContentChange, QuillEditorComponent } from 'ngx-quill'
import { MatQuill } from '../../../mat-quill/mat-quill'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'anms-acceptance-criteria-form',
  templateUrl: './acceptance-criteria-form.component.html',
  styleUrls: ['./acceptance-criteria-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcceptanceCriteriaFormComponent implements OnInit, OnDestroy {
  @ViewChild('autosize') autosize: CdkTextareaAutosize
  @ViewChild('autosize2') autosize2: CdkTextareaAutosize

  form: FormGroup
  parentComponent: FeatureFormComponent

  subscription: Subscription
  description: string
  context: string
  benefitHypothesis: string

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private sanitizer: DomSanitizer,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: {
      description: string
      parentComponent: FeatureFormComponent
      acceptanceCriteria: string
      benefitHypothesis: string
      context: string
    }
  ) {
    this.form = this.formBuilder.group({
      acceptanceCriteria: this.data?.acceptanceCriteria ?? '',
      // improvedAcceptanceCriteria: '',
      context: this.data?.context ?? ''
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.description = this.data.description
    this.benefitHypothesis = this.data.benefitHypothesis
    this.parentComponent = this.data.parentComponent
    this.context = this.data.context
  }

  onSubmit(buttonType: string): void {
    if (buttonType === 'load') {
      this.generateAcceptanceCriteria()
    }
  }

  generateAcceptanceCriteria(): void {
    if (this.form.valid) {
      const req: GenerateFeatureAcceptanceCriteriaRequest = {
        description: this.description,
        benefitHypothesis: this.benefitHypothesis,
        context: this.context,
        n: 1
      }
      this.store.dispatch(
        fromOpenAIActions.generateFeatureAcceptanceCriteria({ data: req })
      )
    }
  }

  copyContentToParent(): void {
    this.form.get('acceptanceCriteria').updateValueAndValidity()
    const acceptanceCriteria = this.form.get('acceptanceCriteria').value
    this.parentComponent.setAcceptanceCriteria(acceptanceCriteria)
    this.dialogRef.close()
  }

  closeDialog(): void {
    this.dialogRef.close()
  }
  moveSelectedCriteriaToFeature(selectedCriteria: string[]): void {
    const currentCriteria = this.form.get('acceptanceCriteria').value || '' // Get current value or an empty string
    const updatedCriteria = currentCriteria
      ? `${currentCriteria}\n${selectedCriteria.join('\n')}`
      : selectedCriteria.join('\n') // Append new criteria
    this.form.get('acceptanceCriteria').setValue(updatedCriteria) // Set the updated value
  }
  get sanitizedAcceptanceCriteria(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.form.get('acceptanceCriteria')?.value
    )
  }
}
