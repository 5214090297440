<div class="main-wrapper d-flex flex-column">
  <form
  [formGroup]="form"
  (ngSubmit)="onSubmit($event.submitter.id)"
  class="default-form"
>
  <div class="form-fields">
    <div class="overflow-y-auto p-3">
   
    <div class="d-flex justify-content-center"> <h2>{{description}}</h2></div>
     
        <div
          class="improve_epic_form_container d-flex flex-column flex-lg-row align-items-center justify-content-center mb-0 pb-0"
        >
       
          <div class="box-left px-0">
  
            <mat-form-field appearance="fill" class="default-full-width mb-0">
              <mat-label>Acceptance Criteria</mat-label>
              <textarea matInput placeholder="{{ 'anms.feature.form.placeholder.acceptance_criteria' | translate }}"
                formControlName="acceptanceCriteria" minlength="3" maxlength="1000" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
              <mat-hint>Save this acceptance criteria to the Story</mat-hint>
              <mat-error *ngIf="form.get('acceptanceCriteria').invalid">
                {{ 'anms.organization.form.placeholder.name' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <button
              class="arrow-btn"
              (click)="moveTextToLeft(form.get('improvedAcceptanceCriteria').value)"
            >
              <fa-icon [icon]="['fas', 'arrow-up']" size="2x"></fa-icon>
            </button>
          </div>
  
          <div class="box-right px-0">
            <ng-container  *ngIf="descriptions$ | async as descriptions">
              <ng-container *ngFor="let description of descriptions.slice(1)">
                <div>
                  <p>{{description}}
                  </p>
                </div>
                <mat-divider></mat-divider>
  
              </ng-container>
              </ng-container>
            <mat-form-field
              appearance="fill"
              class="default-full-width mb-0 mt-3 mt-lg-0"
            >
              <mat-label>AI Generated Acceptance Criteria</mat-label>
              <textarea matInput formControlName="improvedAcceptanceCriteria" minlength="3" maxlength="1000" cdkTextareaAutosize
                #autosize1="cdkTextareaAutosize" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
              <mat-hint>AI Generated Acceptance Criteriat</mat-hint>
              <mat-error *ngIf="form.get('improvedAcceptanceCriteria').invalid">
                {{ 'anms.organization.form.placeholder.name' | translate }}
                {{ 'anms.examples.form.error1' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
    
  
   
    </div>
    </div>
    <div class="form-actions">
      <div class="d-flex flex-row mb-2 mb-md-0">
      <div class="action-col secondary mb-2 mb-md-0 mr-md-4">
        <button
          mat-raised-button
          color="secondary"
          class="btn-save"
          (click)="copyContentToParent()"
          [disabled]="!form.valid"
        >
          Save
        </button>
      </div>
      <div class="action-col primary mb-2 mb-md-0">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          class="btn-load"
          [disabled]="!form.valid"
          id="load"
        >
        Generate
        </button>
      </div>
    </div>
    </div>
     
  </form>
  </div>
  
