import { HistoryAudit } from '../../history-audit-store/history-audit.model'
import { KeyResult } from '../../strategic-themes-store/store/strategic-themes.model'
import { KanbanitemBase, RequirementType } from './item/requirement.model'
import { Item, IssueI, PriorityId, KanbanItemI } from './requirements.model'
import { Task, TaskStatus } from '../../portfolio-store/portfolio/Task'
import { Comment } from './requirements.model'
import { File } from '../../../shared/attachment/services/model'

export class IssueC extends Item<KanbanItemI> implements IssueI {
  name: 'issue' = 'issue' // Fixed string assignment
  priorityId?: PriorityId | null
  storyPoints?: number
  summary: string
  numeration?: string

  // Properties inherited from KanbanItemI
  description?: string
  teamId?: any
  ownerId: string
  code: string
  color?: string
  state?: string
  keyResults?: KeyResult[]
  keyResultsId?: string[]
  history?: HistoryAudit[]
  comments?: Comment[]
  id: any
  itemName: string
  kanbanId: string
  columnId: string
  toKanban: boolean
  type: RequirementType
  locked: boolean
  children?: KanbanItemI[] // Recursive type for children
  solutionId?: string
  creationDate?: Date
  lastUpdate?: Date
  parentId?: any
  tasks?: Task[]
  inRoadmap?: boolean
  index?: number
  numChildren?: number
  files?: File[]

  constructor({
    id,
    code,
    itemName,
    description,
    summary,
    priorityId,
    storyPoints,
    locked,
    solutionId,
    columnId,
    toKanban,
    type,
    ownerId,
    parentId,
    kanbanId,
    inRoadmap,
    creationDate,
    numeration,
    lastUpdate,
    color,
    children,
    state,
    numChildren
  }: IssueI) {
    super(
      id,
      code,
      'issue', // Direct assignment for the 'name' field
      itemName,
      description ?? '',
      locked,
      solutionId ?? '',
      columnId,
      toKanban,
      type,
      ownerId,
      parentId ?? '',
      kanbanId,
      inRoadmap ?? false,
      numeration ?? '',
      creationDate,
      lastUpdate,
      color,
      children as KanbanItemI[],
      state ?? '',
      numChildren ?? 0
    )

    this.summary = summary
    this.priorityId = priorityId ?? null
    this.storyPoints = storyPoints
    this.files = []
  }

  getStoryPoints() {
    return this.storyPoints
  }

  getPrioritizationId() {
    return this.priorityId
  }
}
