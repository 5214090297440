import { HttpClient } from '@angular/common/http'
import { AuthClientConfig, AuthService } from '@auth0/auth0-angular'
import { Store } from '@ngrx/store'
import { environment } from '../../../environments/environment'

import {
  AuthServiceLocaL,
  AuthServiceGeneric,
  AuthServiceAuth0
} from './auth.service'
import { Injector } from '@angular/core'

export function authFactory(
  http: HttpClient,
  auth0Service: AuthService,
  injector: Injector
): AuthServiceGeneric {
  switch (environment.envName) {
    case 'INT': {
      return new AuthServiceAuth0(auth0Service, injector)
    }

    case 'PROD': {
      return new AuthServiceAuth0(auth0Service, injector)
    }
    case 'DEV': {
      return new AuthServiceLocaL(http, injector)
    }
  }
}
