import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { CallbackComponent } from './callback/callback.component'
import { EffectsModule } from '@ngrx/effects'
import { AuthEffects } from '../../core/auth/auth.effects'

const routes: Routes = [
  {
    path: '',
    component: CallbackComponent
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    EffectsModule.forFeature([AuthEffects])
  ],
  exports: [RouterModule]
})
export class CallbackRoutingModule {}
