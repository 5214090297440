import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  NgModule
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CallbackRoutingModule } from './callback-routing.module'
import { CallbackComponent } from './callback/callback.component'
import { SharedModule } from '../../shared/shared.module'
import { FeaturesStoreModule } from '../features-store.module'

@NgModule({
  declarations: [CallbackComponent],
  imports: [
    //FeaturesStoreModule,
    CommonModule,
    //FeaturesStoreModule,
    //SharedModule,
    // SharedModule,
    // OverlayModule,
    CallbackRoutingModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CallbackModule {}
