import {
  Action,
  createReducer,
  on,
  createFeatureSelector,
  createSelector
} from '@ngrx/store'
import {
  EntityState,
  EntityAdapter,
  createEntityAdapter,
  Update
} from '@ngrx/entity'

import * as KanbanitemActions from './requirement.actions'
import { selectCurrentPage } from './requirement.selectors'
import { Page } from '../../../../shared/paginated/page'
import { Patch } from '../../../requirements/services/kanban-item.service'
import { RequirementI } from '../requirements.model'

// export const kanbanitemsFeatureKey = 'kanbanitems'

export const itemsFeatureKey = 'items'

export interface KanbanItemState extends EntityState<RequirementI> {
  code: string
  totalPages: number
  totalElements: number
  currentPage: number
  pageSize: number
  page: Page<RequirementI>
  // additional entities state properties
}

export const adapter: EntityAdapter<RequirementI> =
  createEntityAdapter<RequirementI>()

export const initialState: KanbanItemState = adapter.getInitialState({
  code: '',
  totalElements: 0,
  totalPages: 0,
  currentPage: 0,
  pageSize: 0,
  page: null
  // additional entity state properties
})

export const reducer = createReducer(
  initialState,
  // on(KanbanitemActions.addKanbanitem, (state, action) =>
  //   adapter.addOne(action.Kanbanitem, state)
  // ),
  /* on(KanbanitemActions.upsertKanbanitem, (state, action) =>
    adapter.upsertOne(action.Kanbanitem, state)
  ),
  */
  on(KanbanitemActions.successAddKanbanitem, (state, action) => {
    if (action.callback && typeof action.callback === 'function') {
      action.callback()
    }
    return adapter.addOne(action.kanbanitem, state)
  }),
  on(KanbanitemActions.addCommentSuccess, (state, action) =>
    adapter.setOne(action.kanbanitem, state)
  ),
  on(KanbanitemActions.successUpdateKanbanitem, (state, action) =>
    adapter.setOne(action.kanbanitem, state)
  ),
  on(KanbanitemActions.successDeleteKanbanitem, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(KanbanitemActions.successPatchKanbanitem, (state, action) =>
    adapter.setOne(action.kanbanitem, state)
  ),
  on(KanbanitemActions.successLoadKanbanitem, (state, action) =>
    adapter.upsertOne(action.kanbanitem, state)
  ),
  on(KanbanitemActions.addKanbanitems, (state, action) =>
    adapter.addMany(action.kanbanitems, state)
  ),
  on(KanbanitemActions.upsertKanbanitems, (state, action) =>
    adapter.upsertMany(action.kanbanitems, state)
  ),
  /* on(KanbanitemActions.updateKanbanitem, (state, action) =>
    adapter.updateOne(action.Kanbanitem, state)
  ),
*/
  /*
  on(KanbanitemActions.patchKanbanitem, (state, action) =>
    adapter.updateOne(action.Kanbanitem, state)
  ),
  */

  on(KanbanitemActions.updateKanbanitems, (state, action) =>
    adapter.updateMany(action.kanbanitems, state)
  ),
  /*
  on(KanbanitemActions.deleteKanbanitem, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  */

  on(KanbanitemActions.deleteKanbanitems, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),

  // on(KanbanitemActions.loadKanbanitems, (state, action) =>
  //   adapter.setAll(action.Kanbanitems, state)
  // ),
  on(KanbanitemActions.successLoadKanbanitems, (state, action) =>
    adapter.setAll(action.kanbanitems, state)
  ),
  on(KanbanitemActions.successLoadKanbanitemsSet, (state, action) =>
    adapter.upsertMany(action.kanbanitems, state)
  ),

  on(KanbanitemActions.successPatchManyKanbanitems, (state, action) => {
    const a: Update<RequirementI>[] = []
    action.items.jsonPatches.forEach((patcher, index) => {
      patcher.forEach((p) => {
        const pp: Patch = p
        if (p.op === 'add') {
          const updateART: Update<RequirementI> = {
            id: action.items.entityIds[index],
            changes: {
              index: p.value
            }
          }

          a.push(updateART)
        }
        if (p.op === 'replace') {
          const updateART: Update<RequirementI> = {
            id: action.items.entityIds[index],
            changes: {
              columnId: p.value
            }
          }

          a.push(updateART)
        }
      })
    })

    return adapter.updateMany(a, state)
  }),

  on(KanbanitemActions.clearKanbanitems, (state) => adapter.removeAll(state)),
  on(KanbanitemActions.askCodeSuccess, (state, action) => ({
    ...state,
    code: action.code
  })),
  on(KanbanitemActions.successLoadPagedKanbanitems, (state, action) =>
    adapter.upsertMany(action.page.content, {
      ...state,
      totalPages: action.page.totalPages,
      totalElements: action.page.totalElements,
      currentPage: action.page.number,
      pageSize: action.page.size
    })
  )
)

export function kanbanItemReducer(
  state: KanbanItemState | undefined,
  action: Action
) {
  return reducer(state, action)
}
