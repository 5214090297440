import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  OnDestroy,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core'
import { Store, select } from '@ngrx/store'
import { authLogout, selectSettingsLanguage } from '../../../core/core.module'
import { actionSettingsChangeLanguage } from '../../../core/settings/settings.actions'
import { Observable, Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'anms-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountMenuComponent implements OnInit, OnDestroy {
  @Output() closeDialogEvent = new EventEmitter()
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he']
  language$: Observable<string>
  private languageSubscription: Subscription
  constructor(private store: Store, private translate: TranslateService) {}

  ngOnInit(): void {
    this.languageSubscription = this.store
      .pipe(select(selectSettingsLanguage))
      .subscribe((language: string) => {
        // When language changes, update the language in TranslateService
        if (language) {
          this.translate.reloadLang(language)
          //this.translate.use(language)
        }
      })
    this.language$ = this.store.pipe(select(selectSettingsLanguage))
  }
  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe()
    }
  }

  onLogoutClick() {
    // this.router.navigate(['/logout'])
    this.store.dispatch(authLogout())

    this.closeDialogEvent.emit()
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }))
  }

  onNavigationClick() {
    this.closeDialogEvent.emit()
  }
}
