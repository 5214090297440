import { createFeatureSelector, createSelector } from '@ngrx/store'
import { EMPTY } from 'rxjs'

import { FeaturesState, selectFeatures } from '../../features.state'

import { Team } from './team.model'
import { TeamState, teamsFeatureKey, adapter } from './team.reducer'
import { Id } from '../../../shared/models/entity-base.model'

export type teamKeys = 'name' | 'backlogId' | 'ARTId' | 'solutionId' | 'owner'
export type filterKeys = {
  [key in teamKeys]: string
}

export const selectTeamsState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.teams
)
/*
export const selectTeams = createFeatureSelector<State2, TeamState>(
  teamsFeatureKey
)
*/
export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectTeamsState)

export const selectTeamsArray = createSelector(selectAll, (entities) =>
  Object.values(entities)
)
export const getTeamByPropertyValue = createSelector(
  selectAll,
  (
    entities: Team[],
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) => entities.filter((item) => item[columnId] === columnValue)
)
export const getTeamsWithNoArt = createSelector(selectAll, (entities: Team[]) =>
  entities.filter((item) => item.ARTId === undefined || item.ARTId === null)
)

export const getTeamById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)
export const getTeamByIdF = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getTeamsByNoART = createSelector(selectAll, (entities: Team[]) =>
  entities.filter(
    (item) =>
      !hasProp(item, 'ARTId') || item['ARTId'] === '' || item['ARTId'] === null
  )
)

export const getTeamsByART = (artId: string) =>
  createSelector(selectAll, (entities: Team[]) =>
    entities.filter((item) => item.ARTId === artId)
  )
export const getTeamByKanbanId = (kanbanId: string) =>
  createSelector(selectAll, (entities: Team[]) =>
    entities.find((item) => item.kanbanId === kanbanId)
  )

export const getTeamByIds = (ids: Id[]) =>
  createSelector(selectAll, (entities: Team[]) => {
    const teamOuts = entities.filter((item) => {
      for (const key in ids) {
        if (item.id === ids[key].id) {
          return item
        }
      }
    })

    return teamOuts
  })

function hasProp(o, name) {
  return o.hasOwnProperty(name)
}
