import { createFeatureSelector, createSelector } from '@ngrx/store'
import { FeaturesState, selectFeatures } from '../../features.state'
import { StrategicTheme, StrategicThemeClass } from './strategic-themes.model'
import * as strategicThemeReducer from './strategic-theme.reducer'
import { Dictionary } from '@ngrx/entity'

const adapter = strategicThemeReducer.adapter

export type StrategicThemeKey = 'title' | 'objetive' | 'keyResult' | 'portfolio'

export type FilterKeys = {
  [key in StrategicThemeKey]: string
}

export const selectStrategicThemeState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.strategicthemes
)

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors(selectStrategicThemeState)

export const selectStrategicThemesArray = createSelector(
  selectAll,
  (entities) => Object.values(entities)
)

export const getStrategicThemeByeTitle = createSelector(
  selectAll,
  (entities, props) => entities.filter((e) => e.title === props.title)
)

export const getStrategicThemeByIdF = (id: string) =>
  createSelector(selectEntities, (entities) => entities[id])

export const getStrategicTheme = () =>
  createSelector(selectEntities, (entities, props) => entities[props.id])

export const selectStrategicThemeByOrganization = () =>
  createSelector(
    selectAll,
    (
      entities: StrategicTheme[],
      { organizationId }: { organizationId: string }
    ) =>
      entities.filter(
        (strategicTheme) => strategicTheme.organizationId === organizationId
      )
  )

export const selectStrategicThemesByEpicId = (epicId: string) =>
  createSelector(selectAll, (entities: StrategicTheme[]) =>
    // eslint-disable-next-line no-shadow
    {
      // console.log(entities)
      const sts = entities.filter((st) =>
        st.epics?.filter((e) => e.id === epicId)
      )
      // console.log(sts);

      return sts
    }
  )

export const selectKeyResults = (strategicThemeId: string) =>
  createSelector(
    selectEntities,
    (entities: Dictionary<StrategicTheme>) =>
      entities[strategicThemeId]?.keyResults
  )

export const getStrategicThemeByKeyResult = (keyResultId: string) =>
  createSelector(selectAll, (entities: StrategicTheme[]) =>
    entities.find(
      (theme) =>
        theme.keyResults && theme.keyResults.some((kr) => kr.id === keyResultId)
    )
  )
