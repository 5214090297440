import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { OverlayFormRef } from '../../../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../../../overlay-forms/overlay/overlay.service'
import { Organization } from '../../../../features/organization-store/store/organization.model'
import * as fromOrganizationSelectors from '../../../../features/organization-store/store/organization.selectors'
import * as fromActionsKanbanItems from '../../../../features/requirements-store/store/item/requirement.actions'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { RequirementI } from '../../../../features/requirements-store/store/requirements.model'
import { PortfolioSafe } from '../../../../features/portfolio-store/portfolio/portfolio-safe.model'
import { filter, switchMap } from 'rxjs/operators'
import * as fromPortfolioSelectors from '../../../../features/portfolio-store/portfolio/portfolio-safe.selectors'
import { User } from '../../../../features/user-store/user.model'
import * as fromFeatureSelectors from '../../../../features/feature-selectors'
import { OrganizationMemberWithName } from '../../../../features/organization-store/store/organization-member.model'

@Component({
  selector: 'anms-copy-epic-form',
  templateUrl: './copy-epic-form.component.html',
  styleUrls: ['./copy-epic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyEpicFormComponent implements OnInit, OnDestroy {
  form: FormGroup

  epic: RequirementI

  organizations$: Observable<Organization[]>

  portfolios$: Observable<PortfolioSafe[]>

  users$: Observable<OrganizationMemberWithName[]>

  selectedOrganization$: BehaviorSubject<string> = new BehaviorSubject(
    undefined
  )
  selectedPortfolioId$: BehaviorSubject<string> = new BehaviorSubject(undefined)

  selectedPortfolio$: Observable<PortfolioSafe>

  private subscriptions: Subscription[] = []

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: {
      epic?
    }
  ) {
    this.form = this.formBuilder.group({
      organizationId: ['', [Validators.required]],
      portfolioId: ['', [Validators.required]],
      kanbanId: ['', [Validators.required]],
      ownerId: ['', [Validators.required]]
    })
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this.epic = this.data.epic
    this.organizations$ = this.store.select(fromOrganizationSelectors.selectAll)

    this.subscriptions.push(this.organizations$.subscribe())

    this.portfolios$ = this.selectedOrganization$.pipe(
      filter((organizationId) => !!organizationId),
      switchMap((organizationId) =>
        this.store.select(
          fromPortfolioSelectors.selectPortfoliosByOrganizationId(
            organizationId as unknown as string
          )
        )
      )
    )
    this.subscriptions.push(this.portfolios$.subscribe())

    this.selectedPortfolio$ = this.selectedPortfolioId$.pipe(
      filter((selectedPortfolioI) => !!selectedPortfolioI),
      switchMap((portfolioId) =>
        this.store.select(
          fromPortfolioSelectors.getPortfolioSafeById(
            portfolioId as unknown as string
          )
        )
      )
    )
    this.subscriptions.push(
      this.selectedPortfolio$.subscribe((val) => {
        this.form.get('kanbanId').setValue(val.kanbanId)
      })
    )

    this.users$ = this.selectedOrganization$.pipe(
      filter((organizationId) => !!organizationId),
      switchMap((organizationId) =>
        this.store.select(
          fromFeatureSelectors.getOrganizationMembersWithRoles(
            organizationId as unknown as string
          )
        )
      )
    )
    this.subscriptions.push(this.users$.subscribe())
  }
  updatePortfolio(portfolioId) {
    this.form.get('portfolioId').setValue(portfolioId)
    this.selectedPortfolioId$.next(portfolioId)
  }

  updateOwner(value) {
    this.form.get('ownerId').setValue(value)
  }

  save() {
    if (this.form.valid) {
      console.log('here')
      const organizationId = this.form.get('organizationId').value
      const portfolioId = this.form.get('portfolioId').value
      const ownerId = this.form.get('ownerId').value
      const kanbanId = this.form.get('kanbanId').value
      if (organizationId !== undefined && ownerId !== undefined) {
        const copiedEpic = {
          ...this.epic,
          ownerId: ownerId,
          portfolioId: portfolioId,
          kanbanId: kanbanId
        }

        this.store.dispatch(
          fromActionsKanbanItems.addKanbanitem({ kanbanitem: copiedEpic })
        )

        this.dialogRef.close()
      }
    }
  }
  closeDialog() {
    this.dialogRef.close()
  }
  changeOrganization(organizationId) {
    this.selectedOrganization$.next(organizationId)
  }
}
