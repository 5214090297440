<div class="main-wrapper p-1">


    <form [formGroup]="form" class=" default-form d-flex flex-column">
      <div class="form-fields">

            <div class="d-flex px-0">
              <div>
                <h2 class="main-heading">Change Parent - {{this.description}}</h2>

              </div>

            </div>


        <div class="form-fields-inner d-flex flex-column">
          <div>
            <p class="justify-content-end" *ngIf="currentItem">Select a parent for this feature. Features can only belong to one
              parent Epic at a time.

              <br/>
              <b> {{description}} </b> is currently assigned to <b> {{name}} </b>

            </p>
            <br/>
            <p *ngIf="!currentItem">
              Select a parent for this feature. Features can only belong to one parent Epic at a time.
              <br/>
              <b> {{description}} </b> is currently without an assigned parent.
            </p>
          </div>
          <div class="px-0">
            <mat-form-field appearance="fill" class="px-0">
              <mat-select formControlName="parentId" (selectionChange)="updateParent($event.value)">
                <mat-option value="" >None</mat-option>
                <mat-option *ngFor="let item of itemsList" [value]="item.id">
                  {{ item.numeration }} - {{ item.itemName || item.description }}
                </mat-option>

              </mat-select>


            </mat-form-field>

          </div>
        </div>
      </div>

    </form>


</div>
